const AmenitiesData = [
  {
    id: 0,
    value: 'fireplace',
    label: 'Fire Place',
    font: 'fa-solid fa-fire-flame-curved',
    img: require('assets/img/Fireplace.png'),
    text: 'Fireplace',
    status: false,
  },
  {
    id: 1,
    value: 'exercise',
    label: 'Exercise',
    font: 'fa-solid fa-dumbbell',
    img: require('assets/img/Exercise.png'),
    text: 'Exercise',
    status: false,
  },
  {
    id: 2,
    value: 'pool',
    label: 'Pool',
    font: 'fa-solid fa-person-swimming',
    img: require('assets/img/Pool.png'),
    text: 'Pool',
    status: false,
  },
  {
    id: 3,
    value: 'outdoording',
    label: 'Outdoording',
    font: 'fa-solid fa-door-open',
    img: require('assets/img/outdoording.png'),
    text: 'Outdoor Dining',
    status: false,
  },
  {
    id: 4,
    value: 'bbq',
    label: 'BBQ',
    font: 'fa-solid fa-drumstick-bite',
    img: require('assets/img/BBQ.png'),
    text: 'BBQ',
    status: false,
  },
  {
    id: 5,
    value: 'kid',
    label: 'Kid',
    font: 'fa-solid fa-children',
    img: require('assets/img/Kid.png'),
    text: 'Kid Friendly',
    status: false,
  },
  {
    id: 6,
    
    value: 'firepit',
    label: 'Firepit',
    font: 'fa-solid fa-house-fire',
    img: require('assets/img/FirePit2.png'),
    text: 'Fire Pit',
    status: false,
  },
  {
    id: 7,
    value: 'Hottub',
    label: 'Hottub',
    font: 'fa-solid fa-house-fire',
    img: require('assets/img/HotTub2.png'),
    text: 'Hot Tub',
    status: false,
  },

  {
    id: 8,
    value: 'patio',
    label: 'Patio',
    font: 'fa-solid fa-chart-area',
    img: require('assets/img/Patio.png'),
    text: 'Patio',
    status: false,
  },
];

export { AmenitiesData };
