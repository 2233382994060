import React, { useState } from 'react'
import { Payments } from './payments';
import { Payouts } from './payouts';
export const PaymentsAndPayouts = ({ setViewPayout, viewPayout }) => {
    const [selectedTab, setSelectedTab] = useState('payments'); // Initial selected tab

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-color-green h-auto w-full p-5">

                {selectedTab === 'payments' && <Payments />}
                {selectedTab === 'payouts' && <Payouts setViewPayout={setViewPayout} viewPayout={viewPayout} />}
            </div>
        </div>
    )
}
