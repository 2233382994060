import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import icon3 from "assets/img/filter.png";
import icon4 from "assets/img/searchbar.png";
import icon5 from "assets/img/export.png";
import user_pics from "../../../assets/img/user_pics.jpg";
import Pagination from 'components/Pagination/Pagination';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Modal from 'components/Modal/Modal';
import { RxCrossCircled } from "react-icons/rx";
import userServices from 'services/httpService/userAuth/userServices';
import Rating from 'react-rating';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import StatusMenu from 'components/StatusMenu/StatusMenu';
import { useMutation } from "react-query";



const UserList = ({ headers, data, heading, setStepsAndDetail }) => {
  console.log("datadata", data)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);


  const [search, setSearch] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filtered, setFiltered] = useState(data);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [userRole, setUserRole] = useState('');
  const [status, setStatus] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [allProperty, setAllProperty] = useState();

  //API call to get properties
  const getproperty = async () => {
    let res = await userServices.commonGetService(
      `/property/getAllPropertyAvailable`
    );
    setAllProperty(res.data.data);
  };

  useEffect(() => {
    getproperty();
  }, []);

  console.log("filtered", filtered)

  // Function to calculate average rating for a user
  const calculateAverageRating = (userId) => {
    if (allProperty && allProperty.length > 0) {
      const userProperties = allProperty.filter(property => property.userId === userId);

      const propertiesWithReviews = userProperties.filter(property => property.reviews.length > 0);

      if (propertiesWithReviews.length > 0) {

        const averageRatings = propertiesWithReviews.map(property => {

          const totalRating = property.reviews.reduce((acc, review) => acc + review.rating, 0);
          const averageRating = (totalRating / property.reviews.length).toFixed(1);
          return { propertyId: property.propertyId, averageRating: parseFloat(averageRating) };
        });

        const totalAverageRating = averageRatings.reduce((acc, rating) => acc + rating.averageRating, 0);
        const overallAverageRating = (totalAverageRating / averageRatings.length).toFixed(1);

        return parseFloat(overallAverageRating);
      }
    }
    return 0;
  };



  const handleFilterButtonClick = () => {
    setIsFilterModalOpen(true);
  }

  // Toggles the selection of all users and updates the filtered data and selected users list
  const handleSelectAll = () => {
    const newIsAllSelected = !isAllSelected;
    setIsAllSelected(newIsAllSelected);

    const updatedData = filtered.map(user => ({
      ...user,
      isSelected: newIsAllSelected,
    }));

    setFiltered(updatedData);
    setSelectedUsers(newIsAllSelected ? updatedData.map(user => user._id) : []);
  };

  // Filters the user data based on role, status, active status, and search input, then updates the state
  const filterUser = () => {
    let filteredData = data;

    if (userRole) {
      filteredData = filteredData.filter(user => user.loginAs === userRole);
    }
    if (status) {
      filteredData = filteredData.filter(user => user.verify === status);
    }
    if (activeStatus) {
      filteredData = filteredData.filter(user => user.isEnable === activeStatus);
    }

    if (search) {
      filteredData = filteredData.filter(user =>
        (user.fname + ' ' + user.lname).toLowerCase().includes(search.toLowerCase())
      );
    }

    setFiltered(filteredData);
    setIsFilterModalOpen(false);
    setFiltersApplied(true);
    setCurrentPage(1);
  };

  // Updates the filtered data whenever the search input or data changes
  useEffect(() => {
    let filteredData = data;

    if (search) {
      filteredData = filteredData.filter(user =>
        (user.fname + ' ' + user.lname).toLowerCase().includes(search.toLowerCase())
      );
    }

    setFiltered(filteredData);
  }, [search, data]);

  // Resets all filters and search input, then updates the filtered data to show all users
  const resetFilter = () => {
    setUserRole('');
    setStatus('');
    setActiveStatus('');
    setSearch('');
    setFiltered(data);
    setIsFilterModalOpen(false);
    setFiltersApplied(false);
    setCurrentPage(1);
  };

  // Toggles the selection of a single user and updates the filtered data and selected users list
  const handleCheckboxChange = (userId) => {
    const updatedData = filtered.map(user => {
      if (user._id === userId) {
        return { ...user, isSelected: !user.isSelected };
      }
      return user;
    });

    setFiltered(updatedData);
    setSelectedUsers(updatedData.filter(user => user.isSelected).map(user => user._id));

    setIsAllSelected(updatedData.every(user => user.isSelected));
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  // sort data for showing newly added user at the top of the list
  const sortedData = (data) => {
    return [...data].reverse();
  };

  //page calculation for pagination
  const totalPages = Math.ceil(filtered.length / itemsPerPage);
  const firstIndex = (currentPage - 1) * itemsPerPage;
  const lastIndex = currentPage * itemsPerPage;
  const currentPageData = sortedData(filtered).length > 0 && sortedData(filtered).slice(firstIndex, lastIndex);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (currentPageData.length > 0) {
      setLoading(false);
    }
  }, [currentPageData]);

  let formattedRegistrationDate;
  let formattedRegistrationTime;

  //fuction for exporting selected user list
  const handleExportClick = () => {
    if (selectedUsers.length === 0) {
      toast.warning("Please select users to export");
      return;
    }

    document.getElementById("test-table-xls-button").click();
  };

  // API request to update a user's status 
  const { mutate: updateUser, isLoading } = useMutation(
    (data) =>
      userServices.userUpdateById(`userAuth/userUpdateById/${data.itemId}`, { isInfluencer: data.status }),
    {
      onError: (error) => {
        toast.error(`Error updating user: ${error.message}`);
      },
      onSuccess: (data) => {
        toast.success('User status updated successfully');
        console.log('Updated user data:', data);
      },
    }
  );

  // API request to update the user status with the provided itemId and newStatus
  const handleStatusChange = async (itemId, newStatus) => {
    try {
      // Call the mutation function
      await updateUser({ itemId, status: newStatus });
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  return (
    <>
      <div className="flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white">
        <div className='pt-3'>
          <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white rounded-t-xl items-center '>
            <div className=''>
              <p className='font-medium sm:text-lg text-sm'>{heading}</p>
            </div>
            <div className='flex  space-x-2'>
              <div onClick={handleFilterButtonClick} className='cursor-pointer flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                <div>
                  <img src={icon3} />
                </div>
                <div>
                  <p className='font-normal text-xs text-gray-600'>Fillter</p>
                </div>
              </div>

              <div className='flex items-center justify-center'>
                {filtersApplied && (
                  <button onClick={resetFilter}>
                    <RxCrossCircled />
                  </button>
                )}
              </div>

              <button
                onClick={handleExportClick}
                className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
              >
                <div className='w-4 h-4'>
                  <img src={icon5} alt="Export Icon" className='w-full h-full' />
                </div>
                <div className='ml-2 sm:ml-0'>
                  <p className='font-normal text-xs text-gray-600'>Export</p>
                </div>
              </button>

              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="hidden"
                table="selected-users-table"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export"
              />

              <div className="relative md:w-64  ">
                <input
                  type="text"
                  value={search}
                  onChange={handleInputChange}
                  placeholder='Search By Name'
                  className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                />
                <img
                  src={icon4}
                  alt="search icon"
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>

        <div className='overflow-auto'>
          <table id="table-to-xls" className="table-auto border-collapse w-full bg-white">
            <thead>
              <tr className='bg-[#fafbff]'>
                <th className="px-4 py-2 border-t border-b text-left">
                  <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} className="mr-2" />
                  User
                </th>
                {headers.map((header, index) => (
                  <th key={index} className="px-4 py-2 border-t border-b text-left">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={headers.length + 1} className="text-center py-10">
                    <CircularProgress />
                  </td>
                </tr>
              )
                :
                (
                  currentPageData.map((user, index) => {
                    const registrationDate = new Date(user.date);
                    const options = { month: 'short', day: '2-digit', year: 'numeric' };
                    formattedRegistrationDate = registrationDate.toLocaleDateString('en-US', options);
                    formattedRegistrationTime = registrationDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });


                    return (

                      <tr key={index} className='border-t'>
                        <td className="px-4 py-2 flex flex-col items-start">
                          <div className="flex items-center">
                            <input type="checkbox" checked={isAllSelected || user.isSelected} onChange={() => handleCheckboxChange(user._id)} className="mr-2" />
                            {user.pic ? (
                              <img src={user.pic} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                            ) : (
                              <img src={user_pics} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                            )}
                            <div className='flex flex-col'>
                              <h2 onClick={() => setStepsAndDetail(user._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{user.fname + " " + user.lname}</h2>
                              <span className="font-medium text-xs leading-4 text-gray-400">{user.email}</span>
                            </div>
                          </div>
                        </td>
                        {/* <td className="px-4 py-2 font-medium text-sm leading-5 text-black">{user.loginAs}</td> */}
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          <div className='flex flex-col'>
                            <span>{formattedRegistrationDate}</span>
                            <span className="font-medium text-xs leading-4 text-gray-400">{formattedRegistrationTime}</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          <div className="flex items-center w-28">
                            {calculateAverageRating(user._id) === 0 ? (
                              <span>Not rated yet</span>
                            ) : (
                              <>
                                <Rating
                                  initialRating={calculateAverageRating(user._id)}
                                  readonly
                                  emptySymbol={<FaRegStar color="#d3d3d3" />}
                                  halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                  fullSymbol={<FaStar color="#ffd700" />}
                                  fractions={2}
                                />
                                <span>({calculateAverageRating(user._id)})</span>
                              </>
                            )}
                          </div>

                        </td>
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          <div className={`w-auto px-2 h-[30px] ${user.verify === 'yes' ? 'bg-green-500' : 'bg-red-500'} bg-opacity-10 rounded-full flex justify-center items-center`}>
                            <button className={`font-extrabold text-xs leading-4 text-center ${user.verify === 'yes' ? 'text-green-500' : 'text-red-500'}`}>
                              {user.verify === 'yes' ? 'Verified' : 'Unverified'}
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          <div className="w-[56px] h-[30px] bg-green-500 bg-opacity-10 rounded-full flex justify-center items-center">
                            <button className="font-extrabold text-xs leading-4 text-center text-green-500">
                              {user.isEnable === 'yes' ? 'Active' : 'Delete'}
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          <div className='flex flex-col'>
                            <span>24 Feb, 2024</span>
                            <span className="font-medium text-xs leading-4 text-gray-400">03:00 AM</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                          {user.roles.includes("Influencer") ? (
                            <StatusMenu
                              initialStatus={user?.isInfluencer} // Assuming data.status contains the current status
                              itemId={user._id}
                              onStatusChange={handleStatusChange}
                              customProp="Custom Value"
                              statusTexts={['Pending', 'Active', 'Inactive']}
                            />
                          ) : (
                            <BsThreeDotsVertical />
                          )}
                        </td>
                      </tr>
                    );
                  }))
              }
            </tbody>
          </table>
          {/* Separate table for exporting selected users */}
          <table id="selected-users-table" style={{ display: 'none' }}>
            <thead>
              <tr>
                <th>
                  User
                </th>
                <th>
                  Email
                </th>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData(data).filter(user => selectedUsers.includes(user._id)).map(user => (
                <tr key={user._id}>
                  <td>{user.fname + " "}{user.lname}</td>
                  <td>{user.email}</td>
                  <td>{user.loginAs}</td>
                  <td>{formattedRegistrationDate + '  ' + formattedRegistrationTime}</td>
                  <td>{calculateAverageRating(user._id)}</td>
                  <td>{user.verify === 'yes' ? 'Verified' : 'Unverified'}</td>
                  <td>{user.isEnable === 'yes' ? 'Active' : 'Delete'}</td>
                  <td>{user.lastLoginDate}</td>
                  <td>{user.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filtered.length > itemsPerPage && (
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
        )}
      </div>

      <Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <div className="py-4 text-center font-bold">Filters</div>
        <hr className="text-[#C1E1C2]" />
        <div className="flex flex-col h-[90%] justify-between pt-5 md:pt-14 items-center">
          {/* User Role */}
          <div className="mb-4 px-5 text-center flex flex-col gap-5">
            <p className="text-xl font-semibold mb-2">User Role</p>
            <div className="flex items-center space-x-4">
              <input type="radio" id="guest" name="userRole" value="Guest" onChange={() => setUserRole('Guest')} />
              <label htmlFor="guest">Guest</label>
              <input type="radio" id="host" name="userRole" value="Host" onChange={() => setUserRole('Host')} />
              <label htmlFor="host">Host</label>
            </div>
          </div>

          {/* Status */}
          <div className="mb-4 px-5 text-center flex flex-col gap-5">
            <p className="text-xl font-semibold mb-2">Status</p>
            <div className="flex items-center space-x-4">
              <input type="radio" id="verified" name="status" value="yes" onChange={() => setStatus('yes')} />
              <label htmlFor="verified">Verified</label>
              <input type="radio" id="unverified" name="status" value="no" onChange={() => setStatus('no')} />
              <label htmlFor="unverified">Unverified</label>
            </div>
          </div>

          {/* Active Status */}
          <div className='px-5 text-center flex flex-col gap-5'>
            <p className="text-xl font-semibold mb-2">Active Status</p>
            <div className="flex items-center space-x-4">
              <input type="radio" id="active" name="activeStatus" value="yes" onChange={() => setActiveStatus('yes')} />
              <label htmlFor="active">Active</label>
              <input type="radio" id="inactive" name="activeStatus" value="no" onChange={() => setActiveStatus('no')} />
              <label htmlFor="inactive">Inactive</label>
            </div>
          </div>

          <button
            className="w-full bg-blue-500 text-white py-2 rounded-md rounded-tr-none rounded-tl-none hover:bg-blue-600 transition duration-300"
            onClick={filterUser}
          >
            Filter
          </button>
        </div>
      </Modal>


    </>
  );
};

export default UserList;
