import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoAddress from "components/AutoAddress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import SearchCard from "components/Cards/SearchCard";

import { useLocation, useParams } from "react-router-dom";
import Modal from "components/Modal/Modal";
import dayjs from "dayjs";

const LandingSearchBar = ({
  notPedding,
  onSearchInputChange,
  checkindate,
  handleFilter,
  filterType,
  setSelectedPrice,
  filterPrice,
  checkoutdate,
  handleFilterData
}) => {
  const [checkIn, setCheckIn] = React.useState(null);
  const [checkOut, setCheckOut] = React.useState(null);
  const [guest, setGuest] = useState("");
  const [rooms, setRooms] = useState("");
  const [filter, setFilter] = useState(false);
  const [price, setPrice] = useState(false);
  const [filterModal, setfilterModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fullAddress, setFullAddress] = useState({});
  const [filteredData, setFilteredData] = useState([]); // State to hold filtered data
  const [priceRange, setPriceRange] = useState({ min: 36, max: 3600 });

  const currentDate = dayjs();

  // Updating the price range state
  const handleChange = (value) => {
    setPriceRange(value);
  };

  //simulate filtering data based on price range
  const filterDataByPriceRange = () => {
    const filteredData = []; // Replace this with your actual data filtering logic
    setFilteredData(filteredData);
  };

  // Handling search click
  const handleSearchClick = () => {
    filterDataByPriceRange();
  };

  // Updating guest state and search input state
  const handleInputChange = (e) => {
    setGuest(e.target.value);
    setSearchInput(e.target.value);
    onSearchInputChange(e.target.value);
  };

  // Updating room state and search input state
  const handleRoomInput = (e) => {
    setRooms(e.target.value);
    setSearchInput(e.target.value);
    onSearchInputChange(e.target.value);
  };

  let navigate = useNavigate();
  const { state: locationData } = useLocation();
  let [lat, setLat] = useState(0);
  let [long, setLong] = useState(0);

  // Updating address, latitude, and longitude state
  const changeAddress = (address, lat, long) => {
    setLat(lat);
    setLong(long);
    setFullAddress({ address, lat, long });
  };

  const onSearch = () => {
    if (lat == 0) {
      toast.error("Location required for search");
      return;
    }

    const spaceTypeDetailInput = document.getElementById("guestsInput");
    const bedroomsInput = document.getElementById("bedroomsInput");
    const spaceTypeDetail = spaceTypeDetailInput
      ? spaceTypeDetailInput.value
      : "";
    const bedroomData = bedroomsInput
      ? bedroomsInput.value
      : "";
    navigate(`/search/${lat}/${long}`, {
      state: { spaceTypeDetail, checkIn, checkOut, fullAddress, bedroomData },
    });
  };

  // Sets initial values based on location data
  useEffect(() => {
    locationData?.spaceTypeDetail && setGuest(locationData?.spaceTypeDetail);
    locationData?.bedroomData && setRooms(locationData?.bedroomData);
    locationData?.fullAddress?.lat && setLat(locationData.fullAddress.lat);
    locationData?.fullAddress?.long && setLong(locationData.fullAddress.long);
  }, [locationData]);


  const [state, setState] = useState({
    value5: { min: 36, max: 3600 },
    bedrooms: 'Any',
    beds: 'Any',
    bathrooms: 'Any'
  });

  const [bedrooms, setBedrooms] = useState(0);
  const [beds, setBeds] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);

  const incrementCounter = (setState) => {
    setState((prevCount) => prevCount + 1);
  };

  const decrementCounter = (setState) => {
    setState((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };
  const handleSearchAndCloseModal = () => {
    handleFilterData(priceRange, bedrooms); // Call handleFilterData
    setfilterModal(false); // Close the modal
  };
  return (
    <>
      <Container maxWidth="lg">
        <div className="flex justify-center items-center">
          <span className="text-white font-bold w-[90%] pt-40 lg:pt-24 md:w-[80%] lg:w-[60%] xl:w-[54%] leading-tight  text-center  text-xl md:text-4xl">
            The Exchange Community for Vacation Rental Property Owners
          </span>
        </div>

        <div
          className={`z-10 w-full ${notPedding == "notapply" ? " pt-0" : "md:pt-20 lg:pt-28"
            }  center-styl`}
        >
          <div className="text-start pl-0 md:pl-0 md:text-start pt-20 md:pt-0">
            {notPedding !== "notapply" ? (
              <span className="text-[#FFFFFF] text-[24px] md:text-[30px]  font-extrabold ">
                Find Your Destination
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="block lg:flex pt-5">
            <div className="w-full py-2 flex  md:justify-center lg:h-[100px] h-full bg-white shadow rounded-r-xl rounded-l-xl md:rounded-r-none md:rounded-l-xl">
              <div className="flex flex-col gap-2 lg:flex-row px-4 text-[#AFB5C1]  w-full lg:w-auto items-center justify-between">
                <div className="lg:my-0 md:my-0 w-full lg:w-auto h-[50px]">
                  <div className="flex items-center justify-between px-4 py-3 border border-gray-300 rounded-xl ">
                    <div>
                      <AutoAddress
                        className="outline-none"
                        placeholder="Destination"
                        changeaddress={changeAddress}
                      />
                    </div>
                    <div className="pr-3">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                  </div>
                </div>
                <div className="flex  w-full lg:w-auto items-center justify-center px-4 py-2 border border-gray-300 rounded-xl h-[50px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Basic example"
                      value={notPedding == "notapply" ? checkindate : checkIn}
                      onChange={(newValue) => {
                        setCheckIn(newValue);
                      }}
                      minDate={currentDate.toDate()}
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center">
                          <input
                            className="w-full text-black outline-0"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="Check-in"
                            {...params}
                          />
                          {InputProps?.endAdornment}
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                  <div className="w-px h-full mx-2 bg-gray-300"></div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Basic example"
                      value={notPedding == "notapply" ? checkoutdate : checkOut}
                      onChange={(newValue) => {
                        setCheckOut(newValue);
                      }}
                      minDate={checkIn || currentDate.toDate()}
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center">
                          <input
                            className="w-full text-black outline-0"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="Check-out"
                            {...params}
                          />
                          {InputProps?.endAdornment}
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                  <input
                    placeholder="Guests"
                    className="w-full outline-none"
                    id="guestsInput"
                    value={guest}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                  <input
                    placeholder="Rooms"
                    className="w-full outline-none"
                    id="bedroomsInput"
                    value={rooms}
                    onChange={handleRoomInput}
                  />
                </div>
                <div className="flex  w-full lg:w-auto h-[50px] justify-center">
                  <button
                    onClick={onSearch}
                    className="text-sm w-full lg:w-[100px] font-bold text-white transition-all duration-150 ease-linear rounded-full shadow bg-[#2459BF] hover:shadow-lg"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            {notPedding !== "notapply" ? (
              <div className="flex items-center rounded-r-xl rounded-l-xl lg:rounded-r-xl lg:rounded-l-none justify-center mt-2 lg:mt-0 w-full lg:w-[120px] lg:h-[100px] h-[50px] bg-[#E8AD21]">
                <span className="w-[260px] h-[30px] lg:h-[75px] text-center text-[#000000] font-[500] text-[18px]">
                  Where can you stay right now?
                </span>
              </div>
            ) : (
              <div className="flex border-l-2 border-l-[#AFB5C1] items-center rounded-r-xl rounded-l-xl md:rounded-r-xl lg:rounded-l-none justify-center w-full lg:w-[150px]  bg-[#E8AD21]">
                <div className="w-[114px] h-[75px]  flex flex-col gap-2 text-center justify-center text-[#000000] font-[500] text-[18px]">

                  {/* just for now adding this text */}
                  Where can you stay right now!

                  {/*  in future the below commented filter will be using*/}

                  {/* <div
                    onClick={() => setFilter(!filter)}
                    className="w-[95px] relative gap-2 flex items-center  h-[30px] border border-[#AFB5C1] rounded-full"
                  >
                    <span className="ml-2 cursor-pointer" onClick={() => setfilterModal(true)} >
                      <img
                        src={require("assets/img/filter_tag.png")}
                        className="text-[#AFB5C1] text-base"
                      />
                    </span>
                    <button
                      onClick={() => setfilterModal(true)}
                      className="text-[#AFB5C1] text-xs font-normal "
                    >
                      {filterType == "" && "FILTERS"}
                      {filterType && filterType === "guest" && "Guest"}
                      {filterType && filterType === "bedroom" && "Bedrooms"}
                    </button>

                    <Modal
                      isOpen={filterModal}
                      onClose={() => setfilterModal(false)}
                    >
                      <div className="py-4  font-bold">Filters</div>
                      <hr className="text-[#C1E1C2]" />
                      <div className="px-8 py-4">
                        <h2 className="font-bold text-start pb-2">Type Of Place</h2>
                        <p className="text-start text-sm">
                          Search rooms, entire homes, or any type of place.
                        </p>
                        <div className="flex gap-3 my-2">
                          <button className="px-2 py-2 text-sm bg-blue-500 text-white border border-color-darkgrey/50 rounded-md">
                            Entire Home
                          </button>
                          <button className="px-2 py-2 text-sm border border-color-darkgrey/50 rounded-md">
                            Common Room
                          </button>
                          <button className="px-2 py-2 text-sm border border-color-darkgrey/50 rounded-md">
                            shared Room
                          </button>
                        </div>
                        <h2 className="font-bold text-start">Price range</h2>
                        <p className="text-start text-sm">
                          Nightly prices before fees and taxes
                        </p>
                        <div className="my-6">
                          <InputRange
                            draggableTrack
                            maxValue={3600}
                            minValue={36}
                            onChange={handleChange}
                            value={priceRange}
                          />
                        </div>
                        <div className="flex gap-4 my-2">
                          <div className="p-2 w-1/2 text-start border border-slate-400 rounded-md">
                            <p className="text-xs">minimum</p>
                            <p className="font-semibold">$ {priceRange.min}</p>
                          </div>
                          <div className="p-2 w-1/2 text-start border border-slate-400 rounded-md">
                            <p className="text-xs">maximum</p>
                            <p className="font-semibold">$ {priceRange.max}</p>
                          </div>
                        </div>



                        <button
                          className="bg-blue-600 py-2 px-3 text-white rounded-xl text-sm"
                          onClick={handleSearchAndCloseModal}
                        >
                          Search
                        </button>

                        {filteredData.map((data) => (
                          <SearchCard key={data._id} data={data} />
                        ))}
                        Pass filtered data to SearchCard component

                        <h2 className="font-bold text-start">Rooms and beds</h2>

                        <div className="md:flex w-full gap-2 mt-2">
                          <div className=" md:w-1/3 w-[170px]  ">
                            <p className="text-start text-md mb-1">Bedrooms</p>
                            <div className="flex  py-2 gap-1">
                              <p
                                onClick={() => {
                                  if (bedrooms > 0) {
                                    setBedrooms(0); // Reset counter to 0 if counter is greater than 0
                                  }
                                }}
                                className={`cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${bedrooms === 0 ? 'bg-blue-500' : ''
                                  }`}
                                style={{ color: bedrooms === 0 ? '#ffffff' : '' }} // Add inline style for text color
                              >
                                Any
                              </p>

                              <p onClick={() => decrementCounter(setBedrooms)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                -
                              </p>
                              <p className="p-1 text-xs text-color-darkgrey/70 rounded-full w-[50px]">
                                {bedrooms}
                              </p>
                              <p onClick={() => incrementCounter(setBedrooms)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                +
                              </p>
                            </div>
                          </div>
                          <div className=" md:w-1/3 w-[170px]  ">
                            <p className="text-start text-md mb-1">Beds</p>
                            <div className="flex  py-2 gap-1">
                              <p
                                onClick={() => {
                                  if (beds > 0) {
                                    setBeds(0); // Reset counter to 0 if counter is greater than 0
                                  }
                                }}
                                className={`cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${beds === 0 ? 'bg-blue-500' : ''
                                  }`}
                                style={{ color: beds === 0 ? '#ffffff' : '' }} // Add inline style for text color
                              >
                                Any
                              </p>

                              <p onClick={() => decrementCounter(setBeds)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                -
                              </p>
                              <p className="p-1 text-xs text-color-darkgrey/70 rounded-full w-[50px]">
                                {beds}
                              </p>
                              <p onClick={() => incrementCounter(setBeds)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                +
                              </p>
                            </div>
                          </div>
                          <div className=" md:w-1/3 w-[170px]  ">
                            <p className="text-start text-md mb-1">Bathrooms</p>
                            <div className="flex  py-2 gap-1">
                              <p
                                onClick={() => {
                                  if (bathrooms > 0) {
                                    setBathrooms(0); // Reset counter to 0 if counter is greater than 0
                                  }
                                }}
                                className={`cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${bathrooms === 0 ? 'bg-blue-500' : ''
                                  }`}
                                style={{ color: bathrooms === 0 ? '#ffffff' : '' }} // Add inline style for text color
                              >
                                Any
                              </p>

                              <p onClick={() => decrementCounter(setBathrooms)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                -
                              </p>
                              <p className="p-1 text-xs text-color-darkgrey/70 rounded-full w-[50px]">
                                {bathrooms}
                              </p>
                              <p onClick={() => incrementCounter(setBathrooms)} className="cursor-pointer p-1 text-xs text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                                +
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default LandingSearchBar;