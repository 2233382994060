import React, { useState } from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  console.log("props:", currentPage, totalPages)
  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  // Handle clicking the "Next" button
  const handleNext = () => {
    onPageChange(currentPage + 1);
    // Update the page number limits if the current page exceeds the max limit
    if (currentPage + 1 > maxPageNumberLimit && totalPages > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  // Handle clicking the "Previous" button
  const handlePrev = () => {
    onPageChange(currentPage - 1);
    // Update the page number limits if the current page is at the last limit
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  // Generate the page numbers
  let pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="flex justify-center my-8 ">
        <button
          onClick={handlePrev}
          disabled={currentPage === 1}
          className="mr-2 text-xl font-medium disabled:opacity-50 focus:outline-none px-3 py-1   bg-gray-300 rounded-full"
        >
          {'<'}
        </button>
        {pageNumbers.map((number) => {
          if (number <= maxPageNumberLimit && number > minPageNumberLimit) {
            return (
              <button
                key={number}
                onClick={() => onPageChange(number)}
                className={`mx-2 px-3 py-1 rounded-full focus:outline-none text-xl font-medium ${currentPage === number
                  ? 'bg-yellow-500 text-white'
                  : ' text-gray-700'

                  }`}
              >
                {number}
              </button>
            );
          } else {
            return null;
          }
        })}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="ml-2 disabled:opacity-50 focus:outline-none px-3 py-1   bg-blue-600 text-white rounded-full text-xl font-medium"
        >
          {'>'}
        </button>
      </div>

      <div className='flex items-start justify-center border-t p-4'>
        <span className='text-sm font-normal text-[#AFB5C1] '>Terms of use. Privacy Policy</span>
      </div>
    </>

  );
};

export default Pagination;
