import React, { useEffect, useState } from 'react'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { localStorageData } from 'services/auth/localStorageData';
import userServices from 'services/httpService/userAuth/userServices';

export const TrackPayments = () => {

    const [allReservation, setAllReservation] = useState([])

    // fetching reservation data
    const getReservation = async () => {
        try {
            const userId = localStorageData("_id");
            const apiUrl = `/reservation/reservation/${localStorageData("_id")}`;
            const res = await userServices.getReservationByuserId(apiUrl);
            setAllReservation(res.data);
        } catch (error) {
            console.error("Error fetching reservation data", error);
        }
    };
    const data = [
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Refund"
        },
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Refund"
        },
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Refund"
        },
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Paid"
        },
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Paid"
        },
        {
            bookingId: "ABCDEFGHI",
            checkInOut: "June 14, 2023 - Sep 14, 2023",
            location: "55 Glen Eagles Drive, Clarkburg, WV 26301",
            transactions: "$3253",
            status: "Refund"
        },
    ]
    useEffect(() => {
        getReservation();
    }, []);
    console.log("allReservation", allReservation)
    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-[#C1E1C2] h-auto w-full p-5 space-y-6">
                <div className='p-5 flex justify-between'>
                    <p className="text-2xl capitalize font-medium">
                        Transaction History
                    </p>
                    <div className="h-[40px] w-[100px] rounded-[50px]  border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2" >
                        <span className=''>
                            All
                        </span>
                        <div className="my-auto">
                            <IoIosArrowDown />
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-fixed">
                        <thead className='bg-color-secondry bg-opacity-10'>
                            <tr className="space-x-3">
                                <th className="py-4 px-4 text-left font-medium">Booking ID#</th>
                                <th className="py-4 px-4 text-left font-medium">Check-In's & Out's</th>
                                <th className="py-5 px-4 text-left font-medium">Location</th>
                                <th className="py-4 px-4 text-left font-medium">Transactions</th>
                                <th className="py-4 px-4 text-left font-medium">Status</th>
                                <th className="py-4 px-4 text-left font-medium"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((data, index) => (
                                <tr key={index} className="border-b-2 border-color-[#AFB5C1] text-[#4B4B4B] text-[16px]">
                                    <td className="py-5 px-4 font-normal">{data.bookingId}</td>
                                    <td className='py-5 px-4 font-normal'>{data.checkInOut}</td>
                                    <td className='py-5 px-4 font-normal'>{data.location}</td>
                                    <td className='py-5 px-4 font-normal'>
                                        <span className={`p-1 px-5 rounded-md 
                            ${data.status === 'Paid' ? 'text-[#2459BF] bg-[#58C0D0] bg-opacity-10 font-medium' : 'text-[#EC3434] bg-[#EC3434] bg-opacity-10'} font-medium`}>
                                            {data.transactions}
                                        </span>
                                    </td>
                                    <td className='py-5 px-4 font-normal'>{data.status}</td>
                                    <td className=" py-5 px-4">
                                        <IoIosArrowForward className="w-6 h-6 cursor-pointer" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}
