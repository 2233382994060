import React, { useEffect, useState } from 'react';
import ManageProperty from './PropertyManagement';
import AdminPropertyDetail from './AdminPropertyDetail';
import userServices from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { toast } from 'react-toastify';
import { useMutation } from "react-query";
import RefundReservation from '../RefundReservation/RefundReservation';
import AdminDashboard from '../MainDashboard';

const Properties = () => {
  const [allProperty, setAllProperty] = React.useState([]);
  const [propertyDetail, setPropertyDetail] = React.useState(null);
  const [steps, setSteps] = useState('ManageProperty');

  // fetch all available properties from the API.
  const getproperty = async () => {
    let res = await userServices.commonGetService(
      `/property/getAllPropertyAvailable`
    );
    setAllProperty(res.data.data);
  };
console.log("allPropertyyyy", allProperty)
  //update a property's status by its ID.
  const { mutate: updateProperty, isLoading } = useMutation(
    (data) =>

      // API call to update the property status using its ID and the new status.
      userServices.updatePropertyById(`/property/updatePropertyById/${data.itemId}`, { status: data.status }),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log("data", data)
      },
    }
  );

  useEffect(() => {
    getproperty();
  }, []);

  // handle status change for a specific property.
  const handleStatusChange = async (itemId, newStatus) => {

    const updatedProperties = allProperty.map(property =>
      property._id === itemId ? { ...property, status: newStatus } : property
    );
    // Call the mutation to update the property status on the server.
    await updateProperty({ itemId, status: newStatus });
  };

  // Function to set the current step and display the details of a selected property.
  const handleSetStepsAndDetail = (_id) => {
    const detail = allProperty.find(property => property._id === _id);
    setSteps('AdminPropertyDetail');
    setPropertyDetail(detail);
  };
  console.log("propertyDetail", propertyDetail)
  console.log("steps", steps)

  return (
    <>
      {steps === "ManageProperty" &&
        <ManageProperty setStepsAndDetail={handleSetStepsAndDetail} allProperty={allProperty} handleStatusChange={handleStatusChange} />
      }
      {steps === "AdminPropertyDetail" &&
        <AdminPropertyDetail setSteps={setSteps} propertyDetail={propertyDetail} allProperty={allProperty} />
      }
       {/* {steps === 'AdminDashboard' && (
        <AdminDashboard
          setStepsAndDetail={handleSetStepsAndDetail}
          allProperty={allProperty}
        />
      )} */}

    </>
  );
};

export default Properties;
