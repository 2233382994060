import React from "react";
import { SlDislike, SlLike } from "react-icons/sl";
import bedd from "../../assets/img/back.png";
import { Container, Rating } from "@mui/material";
const RateandReview = () => {
  return (
    <>
      <Container>
        <div className="md:w-full py-5">
          <div className="py-3 ">
            <h1 className="font-bold text-2xl">Rate & Review</h1>
          </div>
          <div className="sm:flex gap-2 md:gap-0 w-full">
            <div className="md:w-[300px]">
              <div className="  md:w-[265px] md:h-[360px] border  rounded-md overflow-hidden">
                <img
                  src={bedd}
                  alt=""
                  className=" w-full h-[180px] object-cover "
                />
                <div className="p-2">
                  <p className=" text-md pt-2">
                    <strong>Stayed at:</strong> B2b Suites
                  </p>
                  <p className="text-md ">
                    {" "}
                    <strong>Location:</strong> Harbourview, Dartmouth, Nova
                    Scotia, Cana...
                  </p>
                </div>
              </div>
              <div />
            </div>
            <div className="md:w-[70%]">
              <div>
                <p className="font-semibold text-xl">
                  Describe Your Experience (required)
                </p>
                <p className="text-md">
                  Your review will be public on your gues’s profile.
                </p>
                <textarea className="w-full h-[140px] border outline-none mt-2"></textarea>
                <p className="text-[16px]">0/4000</p>
              </div>
              <div className="my-10">
                <p className="font-semibold text-xl">
                  Private Guest Feedback
                </p>
                <p className="text-md">
                  This feedback is just for your guest. We won’t make it public.
                </p>
                <textarea className="w-full h-[140px] border outline-none mt-2"></textarea>
                <p className="text-[16px]">0/4000</p>
              </div>
              <div className="mt-3">
                <p className="font-semibold text-xl">Cleanliness</p>
                <p className="text-md">Did the guest leave your space clean?</p>
                <Rating
                  name="size-small"
                  readOnly
                  defaultValue={2}
                  size="small"
                />
              </div>
              <div className="mt-3">
                <p className="font-semibold text-xl">Communication</p>
                <p className="text-md">
                  How clearly did the guest communicate their plans, questions,
                  and concerns?
                </p>
                <Rating
                  name="size-small"
                  readOnly
                  defaultValue={2}
                  size="small"
                />
              </div>
              <div className="mt-3">
                <p className="font-semibold text-xl">
                  Observance of House Rules
                </p>
                <p className="text-md">
                  Did the guest observe the house rules you provided?
                </p>
                <Rating
                  name="size-small"
                  readOnly
                  defaultValue={2}
                  size="small"
                />
              </div>
              <div className="mt-3">
                <p className="font-semibold text-xl">
                  Would you recommend this guest?
                </p>
                <p className="text-md">
                  Your answer will not be posted on your profile or your guest’s
                  profile.
                </p>
                <Rating
                  name="size-small"
                  readOnly
                  defaultValue={2}
                  size="small"
                />
              </div>
              <div></div>
              <div className="flex items-center gap-4 mt-2">
                <p className="text-2xl">
                  <SlDislike />
                </p>
                <p className="font-bold">No</p>
                <p className="text-2xl">
                  <SlLike />
                </p>
                <p className="font-bold">Yes</p>
              </div>
              <div className="mt-3">
                <button className="bg-blue-500 text-white p-2 rounded-lg">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RateandReview;
