import React from 'react'
import TopCard from '../UserManagement/TopCard'
import icon2 from "assets/img/Frame.png"
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import icon6 from "assets/img/Facebookref.png"
import icon7 from "assets/img/Twitter.png"
import icon8 from "assets/img/refless.png"
import icon9 from "assets/img/Reddit.png"
import icon10 from "assets/img/WhatsApp.png"
import icon11 from "assets/img/Signal.png"
import icon12 from "assets/img/TikTok.png"
import icon13 from "assets/img/Telegram.png"
import img1 from "assets/img/dropdwn.png"
import img2 from "assets/img/referral1.png"
import img3 from "assets/img/referral2.png"
import img4 from "assets/img/referral3.png"
import img5 from "assets/img/referral4.png"
import img6 from "assets/img/ref5.png"
import img7 from "assets/img/ref6.png"
import img8 from "assets/img/ref7.png"
import img9 from "assets/img/3group.png"
import img10 from "assets/img/ref8.png"
import img11 from "assets/img/ref9.png"
import img12 from "assets/img/ref10.png"
import img13 from "assets/img/ref11.png"
import img14 from "assets/img/ref12.png"
import img15 from "assets/img/ref13.png"
import img16 from "assets/img/ref14.png"
import img17 from "assets/img/ref15.png"
import img18 from "assets/img/ref16.png"
import img19 from "assets/img/ref17.png"
import img20 from "assets/img/ref18.png"

const dataArray = [
  {
    id: 1234,
    imageUrl: img6,
    name: 'Alfons Workm',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',

  },
  {
    id: 1234,
    imageUrl: img10,
    name: 'Cooper Lipshu',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 100,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img11,
    name: 'Zain Sarisa',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 22,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img12,
    name: 'Terry Ekstorm',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 40,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img13,
    name: 'Lincoln Ekstorm',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 30,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img14,
    name: 'Talan Kenter',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 504,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img15,
    name: 'Alfonso Vetrovs',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img16,
    name: 'Phillip  vaccaro',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img17,
    name: 'Emerson Donin',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img18,
    name: 'Wilson Saris',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img19,
    name: 'Zain Mango',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },
  {
    id: 1234,
    imageUrl: img20,
    name: 'Chance Culhane',
    website: 'https://bnbyond.com/antoniotony',
    clicks: 50,
    newUser: 25,
    referralLink: 'https://bnbyond.com/antoniotony',
    conversionRate: 50,
  },

];

export default function ReferralPrograms() {

  return (
    <>
      <div className='md:p-5 p-2 bg-gray-100'>
        <div className='flex justify-between'>
          <div>
            <h1 className='md:text-2xl md:font-extrabold text-xl font-bold '>Referral Programs</h1>
          </div>
          <div class=" ">
            <button type="button" className="sm:w-[140px] w-[120px] bg-white  flex items-center justify-between sm:py-3 py-2 sm:px-4 px-3 text-xs font-normal text-[#BEBEBE]  rounded-full border border-gray-400 ">
              LAST 30 DAYS
              <img src={img1} />
            </button>
          </div>
        </div>

        <div className='w-full flex md:flex-row flex-col md:pt-3 pt-2 md:space-x-2 space-x-1 md:space-y-0 space-y-2 '>
          <div className=' xl:w-[40vw] xl:h-[291px] h-auto  flex flex-col md:p-8 p-4  rounded-xl bg-white border border-[#C1E1C2] '>
            <div>
              <img src={img2} alt="" />
              <img src={img3} alt="" className='relative sm:bottom-8 bottom-5 sm:right-6 right-2 sm:w-16 w-10 ' />
            </div>

            <div className='md:w-full  space-y-3'>
              <p className='sm:text-2xl text-xl sm:font-extrabold font-bold text-[#E8AD21]'>Refer a Friend</p>
              <p className='text-sm font-extrabold text-[#4B4B4B]'>Increase your user signups with an all-time classic referral
                program.</p>

              <div>
                <button className='py-2 sm:px-10 px-3 rounded-full bg-[#2459BF] text-white sm:text-base text-sm'>INVITE</button>
              </div>
            </div>



          </div>

          <div className=' w-auto  flex flex-wrap sm:gap-[12px] gap-2 xl:items-center items-start xl:justify-center justify-start '>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border-[#C1E1C2] border  sm:p-4 p-2 sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex  space-x-1'>
                <img src={img4} />
                <img src={img5} alt="" />
              </div>
              <div className='sm:w-[111px] w-[225px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>86%</span>
              </div>
              <div className='sm:w-[97px] w-[175px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border border-[#C1E1C2]  sm:p-4 p-2 sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex  space-x-1 '>
                <img src={icon6} />
                <img src={img5} alt="" />
              </div>
              <div className='sm:w-[123px] w-[205px] sm:h-[17px] h-6 bg-[#E8AD21] rounded-full p-1 flex items-center '>
                <span className='text-xs font-normal'>82%</span>
              </div>
              <div className='sm:w-[74px] w-[135px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>54%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border border-[#C1E1C2] sm:p-4 p-2  sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex  space-x-1'>
                <img src={icon7} />
                <img src={icon8} alt="" />
              </div>
              <div className='sm:w-[71px] w-[110px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>42%</span>
              </div>
              <div className='sm:w-[109px] w-[175px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border-[#C1E1C2] border  sm:p-4 p-2 sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex space-x-1'>
                <img src={icon9} />
                <img src={img5} alt="" />
              </div>
              <div className='sm:w-[111px] w-[225px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>86%</span>
              </div>
              <div className='sm:w-[97px] w-[172px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border border-[#C1E1C2] sm:p-4 p-2  sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex  space-x-1'>
                <img src={icon10} />
                <img src={icon8} alt="" />
              </div>
              <div className='sm:w-[71px] w-[110px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>42%</span>
              </div>
              <div className='sm:w-[109px] w-[175px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border-[#C1E1C2] border  sm:p-4 p-2 sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex space-x-1'>
                <img src={icon11} />
                <img src={img5} alt="" />
              </div>
              <div className='sm:w-[111px] w-[225px]  sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>86%</span>
              </div>
              <div className='sm:w-[97px] w-[172px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border-[#C1E1C2] border sm:p-4 p-2 sm:space-y-3 space-y-1 rounded-xl'>
              <div className='flex  space-x-1'>
                <img src={icon12} />
                <img src={img5} alt="" />
              </div>
              <div className='sm:w-[111px] w-[225px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>86%</span>
              </div>
              <div className='sm:w-[97px] w-[172px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>
            <div className='sm:w-[169px] w-full h-[139px] flex flex-col bg-white border border-[#C1E1C2]   sm:p-4 p-2  sm:space-y-3  space-y-1 rounded-xl'>
              <div className='flex space-x-1'>
                <img src={icon13} />
                <img src={icon8} alt="" />
              </div>
              <div className='sm:w-[71px] w-[110px] sm:h-[17px] h-6  bg-[#E8AD21] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>42%</span>
              </div>
              <div className='sm:w-[109px] w-[175px] sm:h-[17px] h-6 bg-[#E9E9E9] rounded-full p-1 flex items-center'>
                <span className='text-xs font-normal'>72%</span>
              </div>
            </div>

          </div>

        </div>


        <div>
          <TopCard className='' />
        </div>


        <div className='pt-3'>
          <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center  '>
            <div className=''>
              <p className='font-medium sm:text-lg text-sm'>Properties</p>
            </div>
            <div className='flex  space-x-2'>
              <div className='flex justify-between sm:p-4 p-2 items-center space-x-1 w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                <div>
                  <img src={icon3} />
                </div>
                <div>
                  <p className='font-normal text-xs text-gray-600'>Fillter</p>
                </div>
              </div>
              <div className='flex justify-between sm:p-4 p-2 items-center   w-[100px] h-[45px] border border-gray-300 rounded-md'>
                <div>
                  <img src={icon5} />
                </div>
                <div>
                  <p className='font-normal text-xs text-gray-600'>Export</p>
                </div>
              </div>

              <div className="relative md:w-64  ">
                <input
                  type="text"
                  placeholder='Search'
                  className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                />
                <img
                  src={icon4}
                  alt="search icon"
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>


        <div className="overflow-x-auto ">
          <table className="table-auto border-collapse w-full bg-white rounded-xl">
            <thead>
              <tr className='bg-[#fafbff]'>
                <th className="px-4 py-2 border-t border-b text-left">
                  Users
                </th>
                <th className="px-4 py-2 border-t text-left border-b">Referral Link:</th>
                <th className="px-4 py-2 border-t text-left border-b">Clicks</th>
                <th className="px-4 py-2 border-t text-left border-b">New User</th>
                <th className="px-4 py-2 border-t text-left border-b">Conversion Rate</th>
              </tr>
            </thead>
            <tbody>
              {dataArray.map((data, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-t border-b flex flex-col items-start">
                    <div className="flex items-center justify-center  ">
                      <img src={data.imageUrl} className=" rounded-md mr-2" />
                      <div className='flex flex-col '>
                        <span className="font-medium text-xs leading-4 text-[#2459BF]">{data.id}</span>
                        <span className="font-medium text-sm leading-5 text-black">{data.name}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                    <span>{data.referralLink}</span>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                    <div className="flex items-center">
                      <img src={img7} alt="" className=" mr-2" />
                      <span>{data.clicks}</span>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    {data.newUser} Points
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    <img src={img8} alt="" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}