import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDropzone } from 'react-dropzone';
import ListingColor from 'components/Cards/ListingColor';
import { Dropzone, FileItem, FullScreenPreview } from '@dropzone-ui/react';
import { FiPlus } from "react-icons/fi";

export default function AddImages({ state, setState }) {
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);

  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
    setState((prevState) => ({ ...prevState, photos: incomingFiles }));
  };

  const onDelete = (id) => {
    setState((prevState) => ({ ...prevState, photos: state.photos.filter((x) => x.id !== id) }));
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const handleClean = (files) => {
  };

  return (
    <div>
      <Grid container spacing={2} >
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "84vh", marginTop: '10vh' }}>
          <ListingColor
            bg='bg-color-darknavyblue'
            text='Add Some Great Photos'
            color='text-[#FFFFFF]'
            btn='step 5'
            images={[
              require('assets/img/photoStep.png'),
              require('assets/img/photoStep2.png'),
            ]}
          />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}
          className='addImages_responsiveness'
        >
          <div className='sm:4 h-[456px] w-[700px] xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0'>
            <Dropzone
              style={{ minWidth: '100%', minHeight: "auto", border: '2px dashed #C1E1C2', paddingBottom: "30px", paddingTop: "20px", borderRadius: '15px', overflowY: 'auto' }}
              onChange={updateFiles}
              minHeight='195px'
              onClean={handleClean}
              value={state.photos}
              minFiles={5}
              maxFileSize={2998000}
              label={
                <div className='flex flex-col items-center justify-center lg:gap-6 xs:gap-7'>
                  <div className=' w-[70px] h-[70px] rounded-full flex justify-center items-center bg-[#58C0D0] bg-opacity-[10%]'>
                    <FiPlus className='w-[50px] h-[50px] text-[#58C0D0]' />
                  </div>
                  <div>
                    <h2 className='xs:text-[18px] xs:mx-5 lg:mx-auto   font-medium lg:text-2xl mb-2 text-black leading-7 font-avenir'>Drag & Drop, Upload or Paste image</h2>
                    <div className=' w-full flex justify-center items-center'>
                      <div className='w-[336px] h-[52px] '>
                        <p className='xs:text-[16px] xs:mx-5 lg:mx-auto  lg:text-[16px] font-normal leading-7 items-center font-lato'>Upload up to 10 images at once. Browse max image size 5 MB.</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button className='w-[215px] h-[50px] rounded-[50px] bg-[#E8AD21] text-[16px] font-bold leading-5 text-center text-[#FFFFFF] '>
                      Add Photos</button>
                  </div>
                </div>
              }

              accept='image/*'
              uploadingMessage={'Uploading...'}
              url='https://my-awesome-server/upload-my-file'
              fakeUploading
              disableScroll
            >
              {state.photos.length > 0 &&
                state.photos.map((file) => (
                  <FileItem
                    {...file}
                    key={file.id}
                    onDelete={onDelete}
                    onSee={handleSee}
                    resultOnTooltip
                    preview
                    info
                    hd
                  />
                ))}
            </Dropzone>
            {(e) => handleSee}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
