import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import ListingColor from "components/Cards/ListingColor";
import ReviewCard from "components/Cards/ReviewCard";

export default function Preview({ state, setState }) {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} sm={12} xs={12} style={{ height: "84vh", marginTop: '10vh' }}>
        <ListingColor
          bg="bg-color-darknavyblue"
          text="All set? Review your property listing."
          color="text-[#FFFFFF]"
          btn='step 10'
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}
        className="preview_responsiveness"
      >
        <div className=" mx-10 xs:mb-36 md:mb-36 lg:mb-20 ">
          <ReviewCard state={state} />
        </div>
      </Grid>
    </Grid>
  );
}
