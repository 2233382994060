import React from 'react'
import TopCard from './TopCard'
import UserList from './UserList'
import Ellipse from '../../../assets/img/Ellipse.png';


const GuestCard = ({ setStepsAndDetail, Guest, Host, allUser }) => {
  const headers = ["Registration Date", "Ratings", "Verified", "Status", "Last Login", ""];
  const userData = [
    {
      name: 'Gustavo Donin1',
      email: "user@gmail.com",
      role: 'Host',
      registrationDate: 'Feb 29, 2024',
      registrationTime: '03:00 AM',
      ratings: 5,
      ratingsCount: 23,
      active: true,
      lastLoginData: '03:00 AM',
      lastLoginTime: '03:00 AM',
      profileImage: Ellipse // Add the profile image URL here
    },
    // Add more user data objects as needed
  ];
  // Repeat userData array 5 times
  const repeatedUserData = Array.from({ length: 10 }, () => userData).flat();

  const heading = "Guests/Hosts";
  return (
    <>
      <TopCard allUsers={allUser} />
      <UserList headers={headers} data={Guest} heading={heading} setStepsAndDetail={setStepsAndDetail} />
    </>
  )
}

export default GuestCard