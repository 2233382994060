import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import SettingsSteps from './SettingsSteps';

const AdminSettings = () => {
  const [active, setActive] = useState(1);
  const [General, setGeneral] = useState([]);
  const [Property, setProperty] = useState([]);
  const [Booking, setBooking] = useState([]);
  const [Payment, setPayment] = useState([]);
  const [Email, setEmail] = useState([]);
  const [Security, setSecurity] = useState([]);
  const [Privacy, setPrivacy] = useState([]);
  const [Other, setOther] = useState([]);
  const handleActive = (num) => {
    setActive(num);
  };
  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 flex justify-between pt-7">
            <h1 className="text-2xl font-extrabold ml-4">Website Settings</h1>
          </div>
          <div className=" p-4">
            <div className="grid grid-flow-row md:grid-cols-8 grid-cols-4 items-center border-b border-b-color-grey relative ">
              <p
                onClick={() => handleActive(1)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mr-2 relative -bottom-[0.5px] ${active == 1
                    ? "border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  } `}
              >
                General
              </p>
              <p
                onClick={() => handleActive(2)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 2
                    ? "border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  } `}
              >
                Property
              </p>
              <p
                onClick={() => handleActive(3)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 3
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Booking
              </p>
              <p
                onClick={() => handleActive(4)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 4
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Payment
              </p>
              <p
                onClick={() => handleActive(5)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 5
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Email
              </p>
              <p
                onClick={() => handleActive(6)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 6
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Security
              </p>
              <p
                onClick={() => handleActive(7)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 7
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Privacy
              </p>
              <p
                onClick={() => handleActive(8)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 8
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                  }`}
              >
                Other
              </p>
            </div>
            <SettingsSteps
              active={active}
              General={General}
              Property={Property}
              Booking={Booking}
              Payment={Payment}
              Email={Email}
              Security={Security}
              Privacy={Privacy}
              Other={Other}
            />
          </div>
        </Container>

      </section>
    </>
  )
}

export default AdminSettings