import React, { useEffect, useState } from 'react'
import visaIcon from "../../../../assets/img/visa.svg"
import visaCard from "../../../../assets/img/cardTypes.jpg"
import AntonioTonyPoints from "../../../../assets/img/paycardss.png"
import paypal from "../../../../assets/img/paypal.svg"
import { PaymentCard } from 'components/Cards/PaymentCard'
import { ButtonOutlined } from 'common/buttons/buttonOutlined'
import userServices from "services/httpService/userAuth/userServices";
import { useMutation } from "react-query";
import { toast } from 'react-toastify'
import ErrorService from 'services/formatError/ErrorService'
import { localStorageData, Logout } from "services/auth/localStorageData";

export const Payments = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [lastFourDigits, setLastFourDigits] = useState('');
  const [showAddCard, setShowAddCard] = useState(false)
  const [Loading, setLoading] = useState(false);

  //fetching user data by ID
  const { mutate: passMutate, isLoading3 } = useMutation(
    (userData) =>

      userServices.userById(`/userAuth/user/${(localStorageData("_id"))}`, userData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data && data.data && data.data.cardInfo) {
          const { cardNumber } = data?.data?.cardInfo;
          //   setCardName(cardName)
          setCardNumber(cardNumber);
          //  // Extract last four digits
          //  if (cardNumber.length >= 4) {
          //   setLastFourDigits(cardNumber.slice(-4));
          // }


        }


      },
    }
  );

  //Adding card information
  const { mutate: setMutate, isLoading: LoadingCardAdd } = useMutation(
    (reservationData) =>

      userServices.Reservation('/stripe/AddCardInfo', reservationData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setCardNumber('')
        setExpiryDate('')
        setCardName('')
        setCvv('')
        passMutate()
        setShowAddCard(false)
        toast.success('Your card added successfully');
      },
    }
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value; 

    switch (name) {
      case 'cardNumber':
        // Remove non-numeric characters and limit to 16 digits
        formattedValue = value.replace(/\D/g, '').slice(0, 16);
        setCardNumber(formattedValue);
        break;
      case 'cardName':
        if (/^[a-zA-Z\s]*$/.test(value)) { // Check if value contains only letters and spaces
          setCardName(value);
        }
        break;
      case 'expiryDate':
        // Remove non-numeric characters and format as MM/YY
        formattedValue = value.replace(/\D/g, '');
        if (formattedValue.length > 2) {
          formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
        }
        setExpiryDate(formattedValue);
        break;
        break;
      case 'cvv':
        // Remove non-numeric characters and limit to 3 digits
        formattedValue = value.replace(/\D/g, '').slice(0, 3);
        setCvv(formattedValue);
        break;
      default:
        break;
    }
  };

  //handle adding card information
  const handleAddCard = async () => {
    setLoading(true); 
    try {
      await setMutate({
        cardNumber: cardNumber,
        expiryDate: expiryDate,
        cardName: cardName,
        cvv: cvv,
        userId: localStorageData("_id"),
      });

    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    passMutate()
  }, [])
  return (
    <div className='p-5 md:p-10 flex flex-col gap-5'>
      <div className="flex flex-col gap-2">
        <div className="pb-4">
          <p className='text-2xl font-medium'>Manage Payment</p>
          <p className='text-base font-normal text-[#4B4B4B]'>You can manage your credit card from here. Having methods connected will make your checkout experience faster.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="w-[100%]">
            {cardNumber && <PaymentCard name="VISA Master Card" icon={visaCard} number={cardNumber} verification="verified" />}
          </div>
          {/* <PaymentCard name="VISA Master Card" icon={visaIcon} number={`**** ${lastFourDigits}`} verification="verified" />
            <PaymentCard name="paypal" icon={paypal} number="****5535" verification="verified" />
            <PaymentCard name="Antonio tony Points" icon={AntonioTonyPoints}  number="Booking with your earning points" verification="55 Points" /> */}
        </div>
      </div>
      <div className="w-[100%] mr-auto">
        {!showAddCard &&
          <ButtonOutlined text="Add Payments" style={{ width: "300px" }} onClick={() => setShowAddCard(true)} />}
        {showAddCard && <div className="p-2 border rounded-md border-[#C1E1C2]">
          <div className="flex">
            <div className="w-[100%] ">
              <h4 className="mt-2 mb-2 text-lg font-semibold ">
                Credit/Debit Card
              </h4>
            </div>
            <div className="w-[100%] flex justify-end">

              <i class="fa-solid fa-x" onClick={() => setShowAddCard(false)} ></i>
            </div>
          </div>
          <hr className="my-2 border-[#C1E1C2] " />
          <div className="flex flex-wrap gap-y-2 items-center">
            <img
              src={require("assets/img/visa.png")}
              alt=""
              className="w-16 mx-2"
            />
            <div className="border border-[#C1E1C2] rounded-md">
              <img
                src={require("assets/img/paypal.png")}
                alt=""
                className="py-1 mx-3"
              />
            </div>
            <div className="border border-[#C1E1C2] rounded-md mx-2">
              <img
                src={require("assets/img/pay.png")}
                alt=""
                className="py-1 mx-2"
              />
            </div>

            <img
              src={require("assets/img/point.png")}
              alt=""
              className="h-10 rounded "
            />
            <div className="py-2">
              <button className="px-2 mx-2 text-sm border rounded-md  h-9 border-[#C1E1C2]">
                {" "}
                Other
              </button>
            </div>
          </div>
          <div>
            <img
              src={require("assets/img/all.png")}
              alt=""
              className="p-2 "
            />
          </div>
          <div className="flex items-center gap-2 mt-2">
            <div className="w-1/2">
              <label htmlFor="">Card Number*</label>
              <input
                type="text"
                name="cardNumber"
                className="input-styl placeholder:text-color-grey"
                placeholder="Card Number"
                value={cardNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="">Name on Card*</label>
              <input
                type="text"
                name="cardName"
                value={cardName}
                onChange={handleInputChange}
                className="input-styl placeholder:text-color-grey"
                placeholder="Name on card"
              />
            </div>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <div className="w-1/2">
              <label htmlFor="" className="text-[15px] md:text-[17px]">Expiration Date*</label>
              <input
                type="text"
                name="expiryDate"
                className="input-styl placeholder:text-color-grey"
                placeholder="MM/YY"
                value={expiryDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="w-1/2">
              <label htmlFor="">CVC*</label>
              <input
                type="text"
                className="input-styl placeholder:text-color-grey"
                placeholder="CVC"
                name="cvv"
                value={cvv}
                onChange={handleInputChange}

              />
            </div>
          </div>
          <button className="btn-styl-blue" onClick={handleAddCard} disabled={LoadingCardAdd}>
            {LoadingCardAdd ? 'Adding Card...' : 'Add Card'}</button>
          <div className="flex p-2 rounded-md bg-color-blue/25 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
            >
              <path
                d="M17.7793 6.25855C17.7692 6.03223 17.7627 5.80576 17.7596 5.57923C17.747 4.72827 17.0775 4.03382 16.2278 3.98636C13.629 3.84118 11.5966 2.97675 9.87985 1.30346C9.59258 1.04013 9.16171 1.04013 8.87444 1.30346C7.15771 2.97675 5.12529 3.84118 2.5265 3.98636C1.67679 4.03382 1.00732 4.72827 0.994682 5.57919C0.99162 5.80573 0.985065 6.03221 0.975023 6.25855C0.879515 11.2735 0.736337 18.1465 9.07763 21.0551C9.11757 21.069 9.15843 21.0801 9.19991 21.0884L9.20032 21.0885C9.31705 21.1118 9.43724 21.1118 9.55397 21.0885C9.59561 21.0802 9.63692 21.0689 9.67703 21.0549C17.9941 18.1464 17.8748 11.2972 17.7793 6.25855Z"
                stroke="black"
                stroke-miterlimit="10"
              />
              <path
                d="M14.0487 11.106C14.0487 13.6862 11.9571 15.7778 9.37689 15.7778C6.79673 15.7778 4.70508 13.6862 4.70508 11.106C4.70508 8.52577 6.79673 6.43412 9.37689 6.43412C11.9571 6.43412 14.0487 8.52577 14.0487 11.106Z"
                stroke="black"
                stroke-miterlimit="10"
              />
              <path
                d="M11.3398 9.90287L8.43945 12.8032"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M7.33105 11.6949L8.43941 12.8032"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
            <p className="ml-2 text-sm">
              This transaction is protected with a secure and
              encrypted payment system by BnByond.
            </p>
          </div>
        </div>}
      </div>
    </div>
  )
}
