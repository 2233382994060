import React, { useState } from 'react';
import logo from '../../../assets/img/logo2.png';
import icon from '../../../assets/img/icon.png';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const GeneralSetting = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <>
      <div className='mt-10 px-8 w-1100 h-624 flex-shrink-0 rounded-lg border border-solid border-[#C1E1C2] bg-white'>
        <h2 className="text-black font-Avenir font-bold text-2xl leading-5 py-5">
          Website Logo & Favicon
        </h2>
        <div className=' gap-12 flex flex-wrap'>
          <div className='w-[246px] h-[104px] border border-[#E9E9E9] rounded-md flex justify-center items-center'>
            <img src={logo} alt='' className='w-[163px] h-[85px]' />
          </div>
          <div className='w-[104px] h-[104px] border border-[#E9E9E9] rounded-md flex justify-center items-center'>
            <img src={icon} alt='' className='w-[38] h-[58px] ' />
          </div>
        </div>
        <div className="border-b border-[#E9E9E9] mt-8"></div>
        <div className='mb-4 mt-8'>
          <h2 className="text-black font-Avenir font-bold text-2xl leading-5">
            Website Details
          </h2>

          <div className='flex gap-5 mt-6 flex-wrap'>
            <div className='md:w-[404.432px] w-full h-[50px] flex-shrink-0 mb-5'>
              <label htmlFor='name' className='block font-semibold text-base text-black font-Avenir text-transform-capitalize'>
                Name
              </label>
              <input type='text' id='name' name='name' placeholder='BnByond' className='w-full h-full border border-[#C1E1C2] rounded-lg p-2 bg-white' />
            </div>
            <div className='md:w-[404.432px] w-full h-[50px] flex-shrink-0'>
              <label htmlFor='Email' className='block font-semibold text-base text-black font-Avenir text-transform-capitalize'>
                Email or user name
              </label>
              <input type='text' id='Email' name='Email' placeholder='BnByond.com' className='w-full h-full border border-[#C1E1C2] rounded-lg p-2 bg-white' />
            </div>
          </div>
          <div className='md:w-[404.432px] w-full h-[80px] flex-shrink-0 mt-14 relative'>
            <label htmlFor='Password' className='block font-semibold text-base text-black font-Avenir text-transform-capitalize'>
              Password
            </label>
            <div className='relative'>
              <input
                type={passwordVisible ? 'text' : 'password'}
                id='Password'
                name='Password'
                placeholder='Password'
                className='w-full h-12 border border-[#C1E1C2] rounded-lg p-2 bg-white pr-10'
              />
              {passwordVisible ? (
                <HiEyeOff
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#929293]'
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <HiEye
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#929293]'
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>
          <button className='mt-10 w-[140px] h-[40px] border border-blue-500 bg-[#2459BF] rounded-full text-white font-bold'>Update</button>
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
