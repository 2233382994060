import React, { useEffect, useState } from 'react'
import profile from '../../../assets/img/userProfileImg.png'
import { ProfileLeftSection } from 'components/ProfileLeftSection'
import animal from "../../../assets/img/animal.png"
import cooking from "../../../assets/img/cooking.png"
import book from "../../../assets/img/book.png";
import yoga from "../../../assets/img/yoga.png";
import { ButtonFilled } from 'common/buttons/buttonFilled'
import { PropertyCard } from 'components/Cards/PropertyCard'
import { IoIosArrowBack } from 'react-icons/io'
import { localStorageData } from 'services/auth/localStorageData'
import HistoryCard from 'pages/customer/MyTrip/HistoryCard'
import PastCard from 'pages/guest/PastCard/Pastcard'
import userServices from "services/httpService/userAuth/userServices";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import { toast } from "react-toastify";
import ReservationCrad from 'components/Cards/ReservationCrad'
import like from '../../../assets/img/like.png'
import { FaUtensils, FaRunning, FaBook, FaMusic, FaDog, FaBasketballBall, FaPlane, FaCamera, FaBeer, FaLandmark, FaArchway, FaPaintBrush, FaBalanceScale, FaSeedling, FaPenFancy, FaTshirt } from 'react-icons/fa';


const InterestsContainer = ({ icon, name }) => {
  const renderIcon = icon ? (
    <span className=''>{icon}</span>
  ) : (
    <img src={like} alt="Default Icon" className="w-6 h-6 text-gray-500" />
  );

  return (
    <div className="flex w-[150px] items-center capitalize justify-center p-4 h-[50px] bg-[#F9F9F9] border-2 text-[#646464] border-[#C1E1C2] gap-4 font-medium rounded-2xl">
      {renderIcon}
      <p className="text-[16px]">{name}</p>
    </div>
  );
};

export default InterestsContainer;

export const ProfileDetails = ({ setSteps, pastTrips }) => {

  // Defined interests with associated icons
  const predefinedInterests = [
    { name: "Cooking", icon: <FaUtensils /> },
    { name: "Exercise", icon: <FaRunning /> },
    { name: "Reading", icon: <FaBook /> },
    { name: "Music", icon: <FaMusic /> },
    { name: "Pets", icon: <FaDog /> },
    { name: "Sports", icon: <FaBasketballBall /> },
    { name: "Travel", icon: <FaPlane /> },
    { name: "Photography", icon: <FaCamera /> },
    { name: "Restaurants", icon: <FaUtensils /> },
    { name: "Wine & Beer", icon: <FaBeer /> },
    { name: "History", icon: <FaLandmark /> },
    { name: "Architecture", icon: <FaArchway /> },
    { name: "Art", icon: <FaPaintBrush /> },
    { name: "Politics", icon: <FaBalanceScale /> },
    { name: "Gardening", icon: <FaSeedling /> },
    { name: "Writing", icon: <FaPenFancy /> },
    { name: "Fashion", icon: <FaTshirt /> },
  ];

  const [interestsArray, setInterestsArray] = useState(
    localStorageData("interest")
  );
  const [about, setAbout] = useState(localStorageData("about"));
  const [profilePic, setProfilePic] = useState(localStorageData("profilePic"));
  const [card, setCard] = useState([]);
  const [propertyId, setPropertyId] = useState("");
  const [favoriteRender, setFavoriteRender] = useState(false);

  const aboutText = localStorageData("about");
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to find the icon for a given interest name
  const findIconByName = (interestName) => {
    const predefinedInterest = predefinedInterests.find(
      (interest) => interest.name === interestName
    );
    return predefinedInterest ? predefinedInterest.icon : null;
  };

  // Fetch user's property list
  const getPropertyList = async () => {
    let res = await userServices.getBlogPost(
      `property/getPropertyByUserId/${localStorageData("_id")}`
    );
    setCard(res.data.data);
  };

  // Fetch property list when the favoriteRender state changes
  useEffect(() => {
    getPropertyList();
  }, [favoriteRender]);

  // API call to update the user's wishlist
  const { mutate } = useMutation(
    (propertyId) =>
      userServices.Wishlist("/property/updatePropertyforFavourite", propertyId),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setFavoriteRender(!favoriteRender);
        // setFavourite(data)
        // toast.success('Wishlist Added');
      },
    }
  );

  // Function to handle adding/removing properties from the wishlist
  const handleWishlist = (propertyId) => {
    setPropertyId(propertyId);
    mutate({ propertyId: propertyId, userId: localStorageData("_id") });
  };

  // Initialize state values from localStorage on component mount
  useEffect(() => {
    setInterestsArray(localStorageData("interest"));
    setAbout(localStorageData("about"));
    setProfilePic(localStorageData("profilePic"));
  }, []);

  return (
    <>
      <div className=" px-5 lg:px-32 py-7 md:py-5 ">

        <div
          className="w-full my-4 mr-auto  flex items-center justify-end"
          onClick={() => {
            setSteps("updateProfile");
          }}
        >
          <ButtonFilled text="Edit profile" />
        </div>

        <div className="flex flex-col md:flex-row w-full gap-10 ">
          <ProfileLeftSection />
          <div className="w-full h-auto bg-white rounded-xl overflow-hidden border border-[#C1E1C2] p-8">
            <div className="flex items-center justify-start gap-8 text-xl font-normal">
              <p className="text-black cursor-pointer">Profile Details</p>
              <p
                className="cursor-pointer text-color-grey"
                onClick={() => setSteps("review")}
              >
                Reviews
              </p>
            </div>
            <div className="flex items-start justify-start my-3">
              <hr className=" border border-[#E8AD21] w-[26%]" />
              <hr className=" border border-[#AFB5C1] w-[74%]" />
            </div>

            <div className="h-auto">
              <p className="py-4 text-2xl font-medium">
                About {localStorageData("fname")} {localStorageData("lname")}
              </p>
              <p className="text-base font-normal">
                {isExpanded || aboutText.length <= 200
                  ? aboutText
                  : `${aboutText.substring(0, 200)}...`}
              </p>
              {aboutText.length > 200 && (
                <p
                  className="font-bold leading-10 underline cursor-pointer"
                  onClick={toggleReadMore}
                >
                  {isExpanded ? "Read less" : "Read more"}
                </p>
              )}
            </div>

            <hr className="my-4 border-t border-[#AFB5C1]" />
            <div className="h-auto ">
              <p className="py-4 text-2xl font-medium">
                {localStorageData("fname")}'s Interests
              </p>
              <div className="flex flex-wrap gap-8">
                {interestsArray?.map((interest, index) => (
                  <InterestsContainer
                    key={index}
                    icon={findIconByName(interest.name)}
                    name={interest.name}
                  />
                ))}
              </div>
            </div>

            <hr className="my-4 border-t border-[#AFB5C1]" />
            {localStorageData("loginAs") === "Host" ?
              <PropertyCard handleWishlist={handleWishlist} card={card} />
              :
              <ReservationCrad pastTrips={pastTrips} handleWishlist={handleWishlist} />
            }
            {/* <div
              className="w-full my-4 mr-auto md:w-1/2"
              onClick={() => {
                setSteps("updateProfile");
              }}
            >
              <ButtonFilled text="Edit profile" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
