import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "pages/auth/Signin";
import Signup from "pages/auth/Signup";
import ForgotPassword from "pages/auth/ForgotPassword";
import UpdatePassword from "pages/auth/UpdatePassword";
import MemberForm from "pages/admin/MemberForm";
import { gapi } from "gapi-script";
import AuthHeader from "pages/auth/AuthHeader/AuthHeader";
import { googleapi } from "config/config";
import InfluencerSignup from "pages/auth/Signup/InfluencerSignup";

function Auth() {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clinetId: googleapi,
        scope: "",
      });
    }
    gapi.load("client: auth2", start);
  }, []);
  
  return (
    <>
      <main>
        <AuthHeader />
        <section className="relative w-full h-full min-h-screen py-20">
          <Routes>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
            <Route path="influencer-signup" element={<InfluencerSignup />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="updatepass/:email/:id" element={<UpdatePassword />} />
            <Route path="MemberForm" element={<MemberForm />} />
          </Routes>
        </section>
      </main>
    </>
  );
}

export default Auth;
