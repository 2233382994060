import React from 'react'

const CommingSoon = () => {
    return (
        <>
            <div className='h-[98vh] overflow-hidden w-full flex justify-center items-center relative'>
                <div>

                    <h1 className=' font-extrabold custom text-[60px]'>COMING SOON</h1>
                </div>

                <div className='md:w-4 md:h-10 bg-blue-200 rotatedivtwo absolute top-[15%] left-[10%]'></div>
                <div className='md:w-4 md:h-14 bg-blue-700 absolute top-[15%] left-[20%] rotat'></div>
                <div className='md:w-4 md:h-10 bg-blue-400 absolute  top-[15%] left-[60%] rotatedivtwo'></div>
                <div className=' absolute rotatediv top-[15%] left-[40%]'>
                    <div className='md:w-4 md:h-14 bg-blue-400 absolute  rotate-90 top-[10%] left-[50%]'></div>
                    <div className='md:w-4 md:h-14 bg-yellow-500 absolute  top-[10%] left-[50%]'></div>
                </div>
                <div className='md:w-4 h-16 rotate-45 bg-green-900 absolute  top-[45%] left-[20%]'></div>
                <div className='md:w-4 h-16  bg-green-900 absolute  animate-bounce rotate-45 top-[45%] left-[80%]'></div>
                <div className='md:w-4 md:h-10 bg-green-600 absolute  -top-[30%] left-[33%]'></div>
                <div className='md:w-4 md:h-10 bg-gray-400 absolute bottom-[80%] right-[10%]'></div>
                <div className='md:w-4 md:h-10 bg-rose-50 rotatedivtwo absolute bottom-[10%]  left-[20%]'></div>
                <div className='md:w-4 md:h-10 bg-red-400 rotatedivtwo absolute bottom-[30%]  left-[10%]'></div>
                <div className='md:w-4 md:h-10 rotatedivtwo bg-slate-400 absolute bottom-[10%] left-[20%]'></div>
                <div className='md:w-4 md:h-10 rotatedivtwo bg-rose-600 absolute bottom-[10%] left-[60%]'></div>
                <div className='md:w-4 h-16 rotate-3 bg-emerald-400 absolute  bottom-[10%] left-[80%]'></div>
                <div className='md:w-4 md:h-10 bg-green-200 rotate-90 animate-bounce absolute bottom-[10%]  left-[70%]'></div>
                <div className='md:w-4 md:h-10 bg-yellow-400 rotate-90 animate-bounce absolute bottom-[10%]  left-[40%]'></div>

            </div>
            <div className="hidden md:block fixed top-0 left-0 w-full h-full bg-dots bg-cover"></div>

        </>
    )
}

export default CommingSoon