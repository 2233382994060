import React, { useState } from "react";
import { ButtonOutlined } from "common/buttons/buttonOutlined";
import { PayoutsStepOne } from "./payoutsStepOne";
import { PayoutsStepTwo } from "./payoutsStepTwo";
import { useNavigate } from "react-router-dom";

export const Payouts = ({ setViewPayout, viewPayout }) => {
  const [payoutMethod, setPayoutMethod] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [viewStepTwo, setViewStepTwo] = useState()

  const navigate = useNavigate();

  // handling the button click and toggle the visibility
  const handleButtonClick = () => {
    // setPayoutMethod(!payoutMethod);
    setViewPayout(true)
  };

  const handleCheckboxClick = (e) => {
    setIsChecked(!isChecked);
  };

  const setupPayouts = () => {
    navigate('/payoutstepone')
  }

  return (
    <>
      {!viewPayout && (
        <div className="p-5 md:p-10 flex flex-col gap-5">
          <div className="">
            <p className="text-2xl font-medium">How you'll get paid </p>
            <p className="text-base font-normal text-[#4B4B4B]">
              Add at least one payout method so we know where to send your
              money.
            </p>
          </div>
          <div className="w-[200px] mr-auto" onClick={handleButtonClick}>
            <ButtonOutlined onClick={setupPayouts} text="set up payouts" />
          </div>
        </div>
      )}

      {viewPayout && (
        <div>

          {!viewStepTwo ?
            <PayoutsStepOne setViewStepTwo={setViewStepTwo} />
            : <PayoutsStepTwo setViewPayout={setViewPayout} setViewStepTwo={setViewStepTwo} />
          }
        </div>

      )}
    </>
  );
};
