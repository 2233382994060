import React, { useEffect } from 'react'
import deleteIcon from '../../../assets/img/deleteIcon.png'
import editIcon from '../../../assets/img/editIcon.png'
import { Container } from '@mui/material'
import userServices from 'services/httpService/userAuth/userServices'
import { localStorageData } from 'services/auth/localStorageData'
import userService from "services/httpService/userAuth/userServices";
import ErrorService from 'services/formatError/ErrorService'
import { useMutation, useQuery } from "react-query";

import { toast } from 'react-toastify'

const MyProperties = () => {
  const [property, setproperty] = React.useState([]);

  // Fetch properties by user ID from the API
  const getproperty = async () => {
    let res = await userServices.commonGetService(
      `/property/getPropertyByUserId/${localStorageData("_id")}`
    );
    setproperty(res.data.data);
  };

  // API call to delete a property by its ID
  const deleteListing = useMutation(
    (propertyId) =>
      userService.deletePostService(`/property/deletePropertyById/${propertyId}`),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error), "error not available");
      },
      onSuccess: (data) => {
        toast.success("Property Deleted");
        getproperty();
      },
    }
  );
  const onDeleteListing = async (propertyId) => {
    const r = window.confirm("Do You Really Want to Delete It ?"); // Confirm deletion with the user
    if (r === true) {
      deleteListing.mutate(propertyId); // If confirmed, call the mutation to delete the property
    }


  };
  useEffect(() => {
    getproperty();
  }, []);
  return (
    <>
      <section>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <div>
              <h1 className="font-semibold text-2xl py-4">My Properties</h1>
            </div>

            <div className=" flex flex-wrap gap-2 ">
              {property.map((item) => {
                return (
                  <div className="group sm:w-[32%] md:w-[24%] border border-[#C1E1C2] relative rounded-2xl overflow-hidden">
                    <div className="relative">
                      <img
                        src={item.pics && item.pics[0]}
                        className="object-cover h-[200px]"
                        alt=""
                      />

                      <span className="absolute top-2 right-2 px-4 py-1.5 bg-[#39CB79] text-white text rounded-full cursor-pointer">
                        Published
                      </span>
                    </div>
                    <div className=" px-4  ">
                      <div className="">
                        <h1 className='font-bold text-[18px] w-[226px] px-1 pt-5'>
                          {item.title}
                        </h1>
                        <p className='text-[16px] text-[#AFB5C1] w-[208px] py-2'>
                          {item.address}
                        </p>
                      </div>

                      <hr />
                      <div className=" py-3 flex justify-between items-center">
                        <img src={deleteIcon} onClick={() => onDeleteListing(item._id)} ></img>
                        <div className=' flex gap-3'>
                          <img src={editIcon} ></img>
                          <span className="font-bold"

                          >Edit</span>
                        </div>
                      </div>
                    </div>

                  </div>
                );
              })}
            </div>
          </Container>
        </Container>
      </section>
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default MyProperties
