import BlogPost from 'pages/Blogs/BlogPost'
import AdminSettings from 'pages/admin/AdminSettings/AdminSettings'
import FinancialTransactions from 'pages/admin/FinancialTransactions/FinancialTransactions'
import AdminDashboard from 'pages/admin/MainDashboard'
import Properties from 'pages/admin/PropertyManagement/Properties'
import ReferralPrograms from 'pages/admin/ReferralProgram'
import Reservation from 'pages/admin/ReservationManagement/Reservation'
import AdminReviews from 'pages/admin/Reviews/AdminReviews'
import Permissions from 'pages/admin/Roles_Permissions'
import SupportTicketing from 'pages/admin/SupportTicketing/SupportTicketing'
import UserManagement2 from 'pages/admin/UserManagement/UserManagement2'
import RefundReservation from 'pages/admin/RefundReservation/RefundReservation'
import React from 'react'
import AllPost from 'pages/Blogs/AllPost'



const AdminSidebarSteps = ({ active, Dashboard, Management, PropertiesDatials, ReservationDatials, Financial, Reviews, Referral, Settings, Supports, Roles, Refund, allProperty, blogPost }) => {
  console.log("allProperty#####", allProperty)
  return (
    <>
      {active === 1 && <AdminDashboard Dashboard={Dashboard} />}
      {active === 2 && <UserManagement2 Management={Management} />}
      {active === 3 && <Properties PropertiesDatials={PropertiesDatials} />}
      {active === 4 && <Reservation ReservationDatials={ReservationDatials} />}
      {active === 5 && <FinancialTransactions Financial={Financial} />}
      {active === 6 && <AdminReviews Reviews={Reviews} />}
      {active === 7 && <ReferralPrograms Referral={Referral} />}
      {active === 8 && <SupportTicketing Supports={Supports} />}
      {active === 9 && <Permissions Roles={Roles} />}
      {active === 10 && <AdminSettings Settings={Settings} />}
      {active === 11 && <BlogPost Settings={Settings} />}
      {active === 12 && <RefundReservation Refund={Refund} propertyData={allProperty} />}
      {active === 13 && <AllPost blogPost={blogPost} active={active} />}

    </>
  )
}

export default AdminSidebarSteps