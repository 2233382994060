import { CircularProgress, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userServices from 'services/httpService/userAuth/userServices';
import Footerbn from 'pages/Footer/Footerbn';
import ErrorService from 'services/formatError/ErrorService';
import { toast } from 'react-toastify';
import { useMutation } from "react-query";
import { localStorageData } from 'services/auth/localStorageData';
import BnbNav from 'components/NavBar/BnbNav';
import Navbar from 'components/Navbars/AuthNavbar';


function BlogsDetails() {
    const param = useParams()
    const [data, setData] = useState();
    const [userComment, setUserComment] = useState();
    const [render, setRender] = useState(false);

    // Initializes comment form state
    const initialCommentState = {
        name: "",
        email: "",
        website: "",
        message: "",
        blogId: param.id
    };
    const [comment, setComment] = useState(initialCommentState)

    //post api for posting user comment
    const postComment = () => {
        mutate({ ...comment, userId: localStorageData('_id') })
    }

    // Fetches the blog post and its comments
    const fetchBlogPost = async () => {
        let res = await userServices.getBlogPost(`post/blog/${param.id}`);
        setData(res.data.data);
        setUserComment(res.data.data.comments)
    };

    //format comment posting date to readable form 
    const commentFormatDate = (timeStamp) => {
        const date = new Date(timeStamp);
        const options = { month: "long", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
    };

    //format blog posting date to readable form 
    const blogFormatDate = (timeStamp) => {
        const date = new Date(timeStamp);
        const options = { month: "long", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
    };

    // API for posting comments
    const { mutate, isLoading } = useMutation(
        (token) =>
            userServices.commonPostService("post/blogComment", token),
        {
            onError: (error) => {
                toast.error(ErrorService.uniformError(error));
            },
            onSuccess: (data) => {
                setRender(!render)
                setComment(initialCommentState)
                toast.success('comment posted successfully')
            },
        }
    );

    useEffect(() => {
        fetchBlogPost();
    }, [render]);
    console.log("userComment", userComment)
    return (
        <>
            {data ?
                <>
                    <BnbNav />
                    <Navbar/>
                    <Container maxWidth='lg' className='py-12'>
                        <div className='w-full'>
                            <div className='w-full'>
                                <img src={data?.pic} alt="Blog Post" className='w-full h-[500px] object-cover' />
                                <span className='flex items-center justify-start gap-3 mt-3'>
                                    <p>{blogFormatDate(data?.timeStamp)}</p>
                                    <hr className='border border-gray-500 h-5' />
                                    <p> ({data?.comments.length}) Comments</p>
                                </span>
                            </div>

                            <div className='pt-8'>
                                <h2 className="text-4xl font-semibold capitalize">{data?.title}</h2>
                            </div>
                            <div className="w-full flex flex-col md:flex-row">
                                <div className="flex flex-col  md:w-full">
                                    <div className='pt-2'>
                                        <p
                                            className="text-lg text-[#646464] font-lato"
                                            dangerouslySetInnerHTML={{
                                                __html: data?.description,
                                            }}
                                        ></p>
                                    </div>
                                    <h1 className='font-bold text-2xl mt-5'>Blog Comments</h1>
                                    {userComment.length === 0 ? (
                                        <div className="p-6  rounded-lg bg-gray-100 mt-8 shadow-lg">
                                            <p className="text-lg text-gray-700">No comments have been posted yet. Be the first to share your thoughts!</p>
                                        </div>
                                    ) : (
                                        userComment.map((comment, index) => (
                                            <div key={index} className="p-6 border border-gray-300 rounded-lg bg-gray-50 mt-8 shadow-lg">
                                                <div className="flex items-center space-x-4">
                                                    <img className="w-12 h-12 rounded-full object-cover" src={comment.userId.pic} alt={`${comment.name}'s profile`} />
                                                    <div>
                                                        <p className="font-semibold">{comment.name}</p>
                                                        <p className="text-xs text-gray-500">{commentFormatDate(comment.timeStamp)}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <p className='text-md font-lato text-gray-800'>{comment.message}</p>
                                                </div>
                                            </div>
                                        )))}
                                    <div className=" pt-10 py-5">
                                        <div>
                                            <label htmlFor="message" className='text-2xl font-extrabold uppercase my-5'>Post a Comment</label>
                                            <textarea
                                                id="message"
                                                rows="7"
                                                className="block p-2.5 w-full text-sm mt-3 border border-gray-300 outline-none"
                                                placeholder="Write your thoughts here..."
                                                onChange={(e) => setComment({ ...comment, message: e.target.value })}
                                                value={comment.message}
                                            ></textarea>
                                            <div className="flex flex-wrap w-full justify-between my-8">
                                                <div className="mb-4 md:w-[30%]">
                                                    <input
                                                        className="shadow appearance-none border w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="name"
                                                        type="text"
                                                        placeholder="Name"
                                                        onChange={(e) => setComment({ ...comment, name: e.target.value })}
                                                        value={comment.name}
                                                    />
                                                </div>
                                                <div className="mb-4 md:w-[30%]">
                                                    <input
                                                        className="shadow appearance-none border w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="email"
                                                        type="text"
                                                        placeholder="Email"
                                                        onChange={(e) => setComment({ ...comment, email: e.target.value })}
                                                        value={comment.email}
                                                    />
                                                </div>
                                                <div className="mb-4 md:w-[30%]">
                                                    <input
                                                        className="shadow appearance-none border w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="website"
                                                        type="text"
                                                        placeholder="Website"
                                                        onChange={(e) => setComment({ ...comment, website: e.target.value })}
                                                        value={comment.website}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:w-[200px]">
                                                <button className=" uppercase text-center py-3 px-5 rounded-full bg-[#2459BF] text-white text-sm font-semibold" onClick={postComment} disabled={isLoading}>
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </Container>
                    <Footerbn />
                </>
                :
                <div className='flex items-center justify-center w-full h-screen'>
                    <CircularProgress />
                </div>
            }
        </>
    );
}

export default BlogsDetails;

