import React, { useEffect, useState } from 'react'
import UserManagement from './userManagement'
import InfluencerDetails from '../InfluencerDetails'
import HostDetail from '../HostDetail/HostDetail'
import userServices from 'services/httpService/userAuth/userServices';
// import UserManagement from './UserManagement'


const UserManagement2 = () => {
  const [allUser, setAllUser] = useState([]);
  const [allGuest, setAllGuest] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  const [influencerDetails, setInfluencerDetails] = useState([]);
  const [steps, setSteps] = useState('UserManagement')
  console.log("stepsUserManagement", steps)

  // Fetches all users from the API and updates the allUser state
  const getUsers = async () => {
    let res = await userServices.commonGetService(
      `/userAuth/allusers`
    );
    console.log("res", res)
    setAllUser(res.data);
    // console.log(res.data.data);
  };

  // Finds user details by ID and sets the appropriate step and user details based on the user's role
  const handleSetStepsAndDetail = (_id) => {
    console.log("usersId", _id)
    const detail = allUser.find(allUser => allUser._id === _id);
    console.log("detail", detail)
    // if (detail.roles.includes("Host") && !detail.roles.includes("Influencer")) {
      setuserDetails(detail);
      setSteps('GuestHost')
    // } 
    // if (detail.roles.includes("Guest") && detail.roles.includes("Influencer")) {
    //   setSteps('Influencer')
    //   setInfluencerDetails(detail)
    // }
    // setSteps('')
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {steps === "UserManagement" &&
        <UserManagement setStepsAndDetail={handleSetStepsAndDetail} allUser={allUser} />
      }
      {/* {steps === "Influencer" &&
        <InfluencerDetails setSteps={setSteps} allUser={allUser} influencerDetails={influencerDetails} />
      } */}
      {steps === "GuestHost" &&
        <HostDetail setSteps={setSteps} allUser={allUser} userDetails={userDetails} />
      }
    </>
  )
}

export default UserManagement2