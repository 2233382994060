import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useUpdatePass } from 'hooks';
import { IoIosArrowBack } from 'react-icons/io';

function UpdatePassword() {

  let navigate = useNavigate();

  // Hook to handle the API call for updating the password
  const { mutateAsync: updatepassword, isLoading } = useUpdatePass();

  //hook for getting the route parameters
  const params = useParams();

  // Formik function for managing form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      pass: '',
      cpass: '',
    },
    validationSchema: Yup.object().shape({
      pass: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[0-9]/, "Password must contain at least one numeric character")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
        .required("Password is required"),
      cpass: Yup.string().oneOf(
        [Yup.ref('pass'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: async (values) => {
      values.email = decodeURIComponent(params.email); // Decode email from URL parameters
      values.uniqueId = params.id;

      const response = await updatepassword(values);

      if (response.status) {
        navigate('/');
      }
    },
  });
  return (
    <div className='flex px-4 lg:px-10 py-10 pt-0 justify-center items-center'>
      <div className='w-full lg:w-4/12 px-4'>
        <div className='relative flex flex-col min-w-0 break-words w-full my-6  shadow-2xl bg-auth rounded-lg  border-0'>
          <div className='rounded-t mb-0 px-6 py-6'>
            <div className='text-center mb-3'>
              <h6 className='text-blueGray-500 text-sm font-bold'>
                Update Password ?
              </h6>
            </div>

            <hr className='mt-6 border-b-1 border-blueGray-300' />
          </div>
          <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
            <form onSubmit={formik.handleSubmit}>
              <div className='relative w-full mb-3'>
                <label
                  className='block  text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-password'
                >
                  Enter your new Password
                </label>
                <input
                  name='pass'
                  id='pass'
                  type='password'
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  placeholder="Enter your new password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pass}
                />
                {formik.touched.pass && formik.errors.pass ? (
                  <div className='text-red-500 text-xs'>
                    {formik.errors.pass}
                  </div>
                ) : null}
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-password'
                >
                  Confirm Password
                </label>
                <input
                  name='cpass'
                  id='cpass'
                  type='password'
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  placeholder="Enter password again"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpass}
                />
                {formik.touched.cpass && formik.errors.cpass ? (
                  <div className='text-red-500 text-xs'>
                    {formik.errors.cpass}
                  </div>
                ) : null}
              </div>

              <div className='text-center mt-6'>
                {isLoading === true ? (
                  <CircularProgress />
                ) : (
                  <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600" type='submit'>
                    Update Password
                  </button>
                )}
              </div>
              <a
                href='/'
                className='flex items-center justify-center font-bold text mt-4 underline'
              >
                <IoIosArrowBack className="mr-1" />
                Back To Home
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
