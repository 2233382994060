import React from "react";
import AllGuestCard from "./AllGuestCard";
import GuestCard from "./GuestCard";
import InfluencersCard from "./InfluencersCard";

const TripCard = ({ setSteps, active, Guest, Host, allUser, setStepsAndDetail, Influencers }) => {
  return (
    <>
      {active === 1 && <AllGuestCard setStepsAndDetail={setStepsAndDetail} allUser={allUser} />}
      {active === 2 && <GuestCard Guest={Guest} allUser={allUser} setSteps={setSteps} setStepsAndDetail={setStepsAndDetail} />}
      {active === 3 && <InfluencersCard Influencers={Influencers} allUser={allUser} setSteps={setSteps} setStepsAndDetail={setStepsAndDetail} />}
    </>
  );
};

export default TripCard;
