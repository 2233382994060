import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { getLocalUserdata } from 'services/auth/localStorageData';

const ProtectedRoute = () => {

  const userData = getLocalUserdata();
  const isAuthenticated = userData && userData._id;
  
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/signin" />
};

export default ProtectedRoute;

