import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLocalUserdata } from 'services/auth/localStorageData';

const AdminProtectedRoute = () => {

  const userData = getLocalUserdata();
  const isAuthenticated = userData?.data?.isAdmin === true;
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />
};

export default AdminProtectedRoute;


