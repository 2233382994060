import { GraphComponent } from 'common'
import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'

export const Earnings = () => {
    const data = {
        title: "Teachers",
        color: {
            textColor: "text-blue-900",
            background: 'bg-white',
            boxShadow: "0px 10px 20px 0px #e0c6ff",
        },
        series: [
            {
                name: "Total Earnings",
                data: [1000, 2000, 1008, 2041, 1102, 2109, 1280, 4000, , , , ,],
            },
        ],
        options: {
            chart: {
                type: 'area',
                height: '180px'
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                color: "#000",
                opacity: 0.35,
            },
            fill: {
                type: "gradient",
                colors: ["blue"],
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth",
                colors: ["blue"],
            },
            grid: {
                show: true,
                borderColor: '#e0e0e0',
                xaxis: {
                    lines: {
                        show: true,
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
            },
            yaxis: {
                show: false
            },
            xaxis: {
                categories: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'June',
                    'July',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ]
            }
        }
    }
    return (
        <div className="">
            <div className="flex flex-col gap-5 md:flex-row">
                <div className='w-full p-5 border rounded-lg border-color-green'>
                    <div className="flex justify-between pt-8 pb-4 md:pt-0 md:pb-0">
                        <p className="text-2xl font-medium text-center md:text-2xl">My Earnings</p>
                        <div className="h-[40px] rounded-[50px] cursor-pointer  border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2" >
                            <span className='pr-2 my-auto text-xs font-normal uppercase'>
                                Select month
                            </span>
                            <div className="my-auto ">
                                <IoIosArrowDown />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col pt-5 w-fulll">
                        <div className="flex items-end gap-3">
                            <p className="text-2xl font-extrabold">
                                0.00
                            </p>
                            <p className="text-base font-medium text-color-gray">
                                Points
                            </p>
                        </div>
                        <p className="text-base font-normal text-color-darkgrey">
                            Booked earnings for September 2023
                        </p>
                        <div className="flex gap-5 pt-5">
                            <div className="flex flex-col px-2 border-l-8 border-black">
                                <span className='text-xl font-extrabold'>0.00</span>
                                <span className='text-base font-normal text-color-darkgrey'>Paid Out</span>
                            </div>
                            <div className="flex flex-col px-2 border-l-8 border-color-grey">
                                <span className='text-xl font-extrabold'>0.00</span>
                                <span className='text-base font-normal text-color-darkgrey'>Expected</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full border rounded-lg border-color-green'>
                    <div className="flex flex-col justify-between pt-8 pb-4 md:pt-0 md:pb-0">
                        <p className="pt-5 pl-5 text-lg font-medium md:text-2xl">Summary</p>
                        <GraphComponent params={data} />
                    </div>
                </div>
            </div>
            <div className="">
                <p className="py-5 text-2xl font-medium">September 2023</p>
                <p className="text-base font-normal text-color-darkgrey">You have no listings currently listed</p>
                <p className="pt-2 text-base font-normal underline cursor-pointer">View transactions history</p>
                <p className="pt-2 text-base font-normal underline cursor-pointer">View tax information</p>
            </div>
        </div>
    )
}
