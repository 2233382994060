import React, { useState } from "react";
import Community from "./Community";
import ConnectHost from "./ConnectHost";

const CommunityIndex = () => {
  
  const [startConversation, setStartConversation] = useState(false);

  return (
    <>
      {startConversation ? (
        <ConnectHost setStartConversation={setStartConversation} />
      ) : (
        <Community setStartConversation={setStartConversation} />
      )}
    </>
  );
};

export default CommunityIndex;
