import React from 'react'
import Desktop from '../../../../assets/img/desktop.svg'
export const DeviceHistory = () => {
    return (
        <div className='pt-12 pb-48'>
            <div className="flex justify-between items-center md:w-1/2">
                <div className="flex items-center gap-5 justify-between w-[43%]">
                    <div className="">
                        <img src={Desktop} className='h-[44px] w-[51px]' alt="image not found" />
                    </div>
                    <div className="flex flex-col ">
                        <p className='text-lg font-medium'>
                            Session
                        </p>
                        <p className='text-base font-normal text-[#4B4B4B]'>
                            August 31, 2023 at 15:55
                        </p>
                    </div>
                </div>
                <p className="text-base font-normal underline w-[30%] cursor-pointer">
                    Log out device
                </p>
            </div>
        </div>
    )
}
