import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import PopupModal from "components/PopupModal/PopupModal";
import BlogPost from "./BlogPost";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import Pagination from 'components/Pagination/Pagination'

const AllPost = () => {
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Function to fetch blog posts
  const getBlog = async () => {
    try {
      setLoading(true);
      let res = await userServices.commonGetService(`post/blogs`);
      setLoading(false);
      setPostsData(res.data.data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      setLoading(false);
    }
  };
  console.log("postsData@@@@@@", postsData)
  // Function to delete a specific blog post
  const deleteBlog = async (postId) => {
    try {
      await userServices.commonDeleteService(`post/blog/${postId}`); // Call to delete the blog post by ID
      toast.success("Blog post deleted successfully!");
      getBlog();
    } catch (error) {
      console.error("Error deleting blog post:", error);
      setLoading(false);
      toast.error("Failed to delete blog post.");
    }
  };

  useEffect(() => {
    getBlog();
  }, []);

  // Function to edit a blog post
  const handleEdit = (postId) => {
    setSelectedPostId(postId);
    setIsEditing(true);
  };

  // Function to cancel the edit action
  const handleCancelEdit = () => {
    setSelectedPostId(null);
    setIsEditing(false);
  };

  // Function to handle a successful edit or update of a blog post
  const handleSuccess = () => {
    setSelectedPostId(null);
    setIsEditing(false);
    getBlog();
  };

  const handleDelete = (postId) => {
    setSelectedPostId(postId);
    setOpenAlert(true);
  };

  //formating date to readable formate
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  //word limit restriction for title
  const truncateTitle = (title, wordLimit) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return title;
  };

  // logic for pagination
  const totalPages = Math.ceil(postsData.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentPageData = postsData.slice(firstIndex, lastIndex);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <>
      {openAlert && (
        <PopupModal
          handleCancle={() => setOpenAlert(false)}
          handleAccept={() => {
            deleteBlog(selectedPostId);
            setOpenAlert(false);
          }}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description={`Are you sure you want to delete this post? This action cannot be undone.`}
          title={`Confirm Delete`}
          acceptbtnText="Confirm"
        />
      )}

      {!isEditing ? (
        !loading ? (
          <div className="overflow-x-auto">
            <button
              className="px-4 py-2 m-1 rounded-md bg-[#2459BF] text-white"
              onClick={() => setIsEditing(true)}
            >
              Create New Post
            </button>
            <table className="table-auto border-collapse w-full bg-white rounded-xl">
              <thead>
                <tr className="bg-[#fafbff] w-full">
                  <th className="px-4 py-2 text-left text-lg">Image</th>
                  <th className="px-4 py-2 text-left text-lg">Title</th>
                  <th className="px-4 py-2 border-t text-left border-b">Author</th>
                  <th className="px-4 py-2 border-t text-left border-b">Date of Publish</th>
                  <th className="px-4 py-2 border-t text-left border-b">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((post) => (
                  <tr key={post._id} className="border-t border-b">
                    <td className="px-4 py-2 font-medium text-sm text-[#4B4B4B]">
                      <img className="w-28 h-20 rounded-md" src={post.pic} />
                    </td>
                    <td className="px-4 py-2 font-medium text-sm text-[#4B4B4B]">
                      {truncateTitle(post.title, 8)}
                    </td>
                    <td className="px-4 py-2 font-medium text-sm text-[#4B4B4B]">Admin</td>
                    <td className="px-4 py-2 font-medium text-sm text-[#4B4B4B]">
                      {formatDate(post.timeStamp)}
                    </td>
                    <td className="px-4 py-2 font-medium text-sm text-[#4B4B4B]">
                      <button
                        className="px-4 py-2 m-1 rounded-md bg-[#FFA500] text-white"
                        onClick={() => handleEdit(post._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="px-4 py-2 m-1 rounded-md bg-[#FF0000] text-white"
                        onClick={() => handleDelete(post._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <CircularProgress />
        )
      ) : (
        <BlogPost
          postId={selectedPostId}
          onSuccess={handleSuccess}
          onCancel={handleCancelEdit}
          buttonText={selectedPostId ? "Update" : "Post"}
        />
      )}

      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

    </>
  );
};

export default AllPost;
