import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import img1 from '../../../../assets/img/apartment.jpg'
import { RatingStars } from 'common/RatingsComponent';
const data = [
  {
    name: 'Vanille',
    rating: 3,
    date: '1 day ago',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    replyButtonText: 'REPLY',
    img1: img1,
    img2: img1,
  },
];

export const Reviews = () => {

  return (
    <>
      <div className='justify-between md:flex md:flex-row '>
        <h1 className='text-2xl font-medium'>All Customers Feedback and Reviews</h1>
        <div className="flex justify-end ">
          <div className="rounded-[50px] cursor-pointer border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2 mr-2">
            <span className='px-2'>
              All
            </span>
            <div className="my-auto">
              {/* Assuming IoIosArrowDown is a valid component */}
              <IoIosArrowDown />
            </div>
          </div>

          <div className="rounded-[50px]  cursor-pointer border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2">
            <span className='px-2'>
              5.0 Stars
            </span>
            <div className="my-auto">
              {/* Assuming IoIosArrowDown is a valid component */}
              <IoIosArrowDown />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 py-5">
        {data.map((item, index) => {
          return (
            <div key={index} className="w-full py-8 bg-white border rounded-lg shadow-md md:flex md:flex-row md:py-16 border-color-green">
              <div>
                <div className="flex">
                  <div>
                    <img src={item.img1} alt="Profile Image" className="w-[56px] h-[56px] rounded-full flex ml-8 " />
                  </div>
                  <div className=''>
                    <h1 className='text-2xl font-medium'>{item.name}</h1>
                    <p className='text-lg font-normal'>
                      <RatingStars rating={item.rating} />
                      {item.date}
                    </p>
                  </div>
                </div>
                <div className="text-gray-600 w-[90%] mt-10">
                  <p className='ml-8 text-base font-normal'>
                    {item.description}
                  </p>
                </div>

                <div className='mt-8 ml-8 '>
                  <button className="px-6 py-2 text-white bg-blue-800 rounded-full">
                    {item.replyButtonText}
                  </button>
                </div>
              </div>

              <div className="md:w-[60%] md:h-[230px] w-full p-[10px] h-auto border-l-2  md:flex items-center justify-center ">
                <img src={item.img2} alt="Additional Image" className="rounded" />
              </div>
            </div>
          )
        })}
      </div>

      <div className="justify-end mt-4 space-x-4 md:flex ">
        <button className="px-4 py-2 font-medium rounded-full bg-color-grey"><img src={img1} className='w-6 h-6' alt="" /></button>
        <button className="px-6 py-2 font-medium text-white bg-yellow-500 rounded-full ">1</button>
        <button className="px-4 py-2 font-medium rounded-full">2</button>
        <button className="px-4 py-2 font-medium rounded-full">3</button>
        <button className="px-4 py-2 font-medium rounded-full"><img src={img1} className='w-12 h-12' alt="" /></button>

      </div>
    </>
  );
};
