import React from 'react'
import img1 from "assets/img/3group.png"
import img2 from "assets/img/sendfile.png"
import img3 from "assets/img/sendm.png"
import img4 from "assets/img/information.png"

import { useState } from 'react';

const AdminMessages = () => {
  const [isToggled, setIsToggled] = useState(false);

  const toggleButton = () => {
    setIsToggled(!isToggled);
  };
  return (
    <>
      <div className="md:p-5 p-2 bg-gray-100 ">
        <div className="flex md:flex-row flex-col justify-between bg-white">
          <div className="flex flex-col  w-full md:w-[40vw] lg:w-[30vw] overflow-y-auto border border-r ">
            <div className="px-2 py-4 border-b-2">
              <div className=" p-3 flex justify-between items-center text-black text-lg font-medium leading-[1.166]">
                <p>Messages</p>
                <div><img src={img1} alt="" /></div>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search Messages"
                  className="w-full px-2 py-3 border-2 border-gray-200 rounded-xl"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-center px-2 py-4 border-b-2">
              <div className="w-1/4">
                <img
                  src="https://source.unsplash.com/_7LbC5J-jw4/600x600"
                  className="object-cover w-12 h-12 rounded-full"
                  alt
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold">Kristin Watson</div>
                <span className="text-gray-500">Haha that's terrifying 😂</span>
              </div>
            </div>
            <div className="flex flex-row items-center px-2 py-4 border-b-2">
              <div className="w-1/4">
                <img
                  src="https://source.unsplash.com/otT2199XwI8/600x600"
                  className="object-cover w-12 h-12 rounded-full"
                  alt
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold">Nela Scarlett</div>
                <span className="text-gray-500">Haha that's terrifying 😂</span>
              </div>
            </div>
            <div className="flex flex-row items-center px-2 py-4 bg-blue-100 border-b-2 border-l-4">
              <div className="w-1/4">
                <img
                  src="https://source.unsplash.com/L2cxSuKWbpo/600x600"
                  className="object-cover w-12 h-12 rounded-full"
                  alt
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold">MERN Stack</div>
                <span className="text-gray-500">Lusi : Thanks Everyone</span>
              </div>
            </div>
            <div className="flex flex-row items-center px-2 py-4 border-b-2">
              <div className="w-1/4">
                <img
                  src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                  className="object-cover w-12 h-12 rounded-full"
                  alt
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold">
                  Javascript Indonesia
                </div>
                <span className="text-gray-500">
                  Evan : some one can fix this
                </span>
              </div>
            </div>

          </div>


          <div className="flex flex-col justify-between w-full md:w-[60vw] lg:w-full pr-2 ">
            <div className="flex items-center justify-between px-5 py-5 bg-white border-b-2 ">
              <div className="flex items-center justify-center font-semibold ">
                <img
                  src="https://source.unsplash.com/vpOeXr5wmR4/1200x1200"
                  className="object-cover w-12 h-12 rounded-full"
                  alt
                />
                <div className="mx-2 ">
                  <p className="text-lg font-medium leading-6 text-black uppercase font-avenir">
                    Neha Scarlet
                  </p>
                  <p className="text-black font-lato text-xs font-normal">
                    Online
                  </p>
                </div>
              </div>
              <div>
                <img src={img1} alt="" />
              </div>
            </div>
            <div className="flex flex-col  bg-gray-100 pt-2 space-y-2 pl-2">

              <div className="flex justify-start ">
                <img
                  src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                  className="object-cover w-8 h-8 rounded-full"
                  alt
                />
                <div className="px-4 py-3 ml-2 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  <span>Lorem ipsum dolor sit amet consectetur adipisicing .</span>

                </div>
              </div>

              <div className="flex justify-start ">
                <div className="px-4 py-3 ml-10 text-black bg-blue-100 rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  happy holiday guys!
                </div>

              </div>
              <div className="flex justify-start ">
                <div className="px-4 py-3 ml-10 text-black bg-blue-100 rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  happy holiday guys!
                </div>

              </div>
              <div className="flex justify-start ">
                <img
                  src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                  className="object-cover w-8 h-8 rounded-full"
                  alt
                />
                <div className="px-4 py-3 ml-2 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  ohoooo! Thats good  and that is really epic
                </div>

              </div>
              <div className="flex justify-start ">
                <div className="px-4 py-3 ml-10 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  Aww
                </div>

              </div>
              <div className="flex justify-start ">
                <img
                  src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                  className="object-cover w-8 h-8 rounded-full"
                  alt
                />
                <div className="px-4 py-3 ml-2 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  ohoooo! Thats good  and that is really epic
                </div>

              </div>
              <div className="flex justify-start ">
                <div className="px-4 py-3 ml-10 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  Aww
                </div>

              </div>
              <div className="flex justify-start pb-2 ">
                <div className="px-4 py-3 ml-10 text-black bg-blue-100  rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
                  How are you?
                </div>

              </div>

            </div>
            <div className="py-4 flex md:space-x-2 space-x-1 items-center pl-4">
              <div>
                <img src={img2} alt="" className='md:w-5 w-4' />
              </div>


              <div className=' md:w-[50vw] lg:w-[68vw] w-full bg-yellow-100 flex items-center md:space-x-4 space-x-1 rounded-xl border '>
                <div>
                  <input type="text" placeholder='Type A Message' className='md:w-[26vw] lg:w-[55vw] w-full  md:px-3 px-2 py-3 md:py-5 bg-yellow-100 rounded-xl' />


                </div>

                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    name="toggle"
                    id="toggle"
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    checked={isToggled}
                    onChange={toggleButton}
                  />
                  <label
                    htmlFor="toggle"
                    className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-700 cursor-pointer "
                  />

                </div>

                <div>
                  <span className='md:text-base text-xs'>Admin</span>
                </div>
                <div>
                  <img src={img3} className='w-3 md:w-5' alt="" />
                </div>

              </div>

            </div>

            <div className='flex space-x-2 pl-4 items-center'>
              <img src={img4} className='md:w-6 md:h-6 w-6 h-6 ' alt="" />

              <span className='text-sm font-medium text-[#AFB5C1]'>Your message will be sent to all subchannels</span>
            </div>
          </div>


        </div>
        &nbsp;&nbsp;&nbsp;
      </div>
    </>
  )
}

export default AdminMessages
