import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';


// Going previous arrow component for the slider
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className="border border-white w-[40px] absolute top-[40%] left-3 h-[40px] z-10 cursor-pointer rounded-full flex justify-center items-center"
      onClick={onClick}
    >
      <IoIosArrowBack style={{ color: "white" }} />
    </div>
  );
};

// Going forward arrow component for the slider
const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className="border border-white w-[40px] h-[40px]  absolute top-[40%] right-3 z-10 cursor-pointer rounded-full flex justify-center items-center"
      onClick={onClick}
    >
      <IoIosArrowForward style={{ color: "white" }} />
    </div>
  );
};
//Main component
const ImageSlider = ({ allPost }) => {
  //slider setting
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <Slider {...settings}>
      {
        allPost.pics.map((item) => {
          return (
            <img
              src={item}
              className="object-cover w-full h-[500px] ">
            </img>
          )
        })
      }
    </Slider>
  )
}

export default ImageSlider