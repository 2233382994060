import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import ListingColor from 'components/Cards/ListingColor';
import MultiDec from 'components/Select/MultiDec';
export default function AddCharacteristics({ state, setState, AddCharacteristics }) {
  const [data, setData] = useState(AddCharacteristics);

  const onSelect = (selectedCharacteristic) => {

    // Toggles the selection of a characteristic: removes it if already selected, adds it if not present
    setState((prevState) => {
      const existingIndex = prevState.characteristics.findIndex(
        (char) => char.id === selectedCharacteristic.id
      );

      if (existingIndex !== -1) {
        // Characteristic with the same ID exists, remove it
        const updatedCharacteristics = [...prevState.characteristics];
        updatedCharacteristics.splice(existingIndex, 1);

        return {
          ...prevState,
          characteristics: updatedCharacteristics,
        };
      } else {
        // Characteristic with the ID doesn't exist, add it
        return {
          ...prevState,
          characteristics: [...prevState.characteristics, selectedCharacteristic],
        };
      }
    });
  };


  return (
    <div>
      <Grid container spacing={5}>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "84vh", marginTop: '10vh' }}>
          <ListingColor
            bg='bg-color-darknavyblue'
            text='Choose Some Descriptive Words'
            color='text-[#FFFFFF]'
            btn='step 7'
          />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} pb={{ xs: 5, sm: 2, md: 0, lg: 5 }}
          style={{ marginTop: '10vh', paddingBottom: "17vh", paddingTop: "15vh", height: "84vh", overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className='grid grid-cols-2 gap-2 md:gap-4 px-2 md:grid-cols-2 lg:grid-cols-3 sm:4 md:px-0 xl:gap-10'>
            {data.map((item) => (
              <MultiDec
                data={item}
                withImg={false}
                allData={data}
                onSelect={onSelect}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
