import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import ListingColor from "components/Cards/ListingColor";

export default function PropertyStarting({ state, setState }) {

  return (
    <div className="h-full">
      <Grid container spacing={2}>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "82vh", marginTop: '10vh' }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="It's Easy to Get Started On BnByond"
            color="text-[#FFFFFF]"
            hideButton={true}

          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ marginTop: '12vh', paddingTop: '40vh', height: "82vh", overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className=" mx-4 my-6 border-red-500 xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0">
            <div className="flex gap-4 sm:gap-6 w-full h-32 sm:h-40 lg:h-32 border-b-2 border-[#C1E1C2] items-start mt-5 sm:mt-5">
              <div className="bg-color-blue border-2 border-[#FFFFFF] shadow shadow-[#FFFFFF] lg:w-[54px] lg:h-[54px] xs:w-12 xs:h-12 rounded-full flex justify-center items-center text-[#FFFFFF] font-bold text-[22px] leading-6 font-Poppins">
                1
              </div>
              <div className="w-[70%]">
                <h2 className="font-avenir font-medium text-[18px] sm:text-[24px] lg:text-[24px] leading-7 text-black">
                  Tell us about your place
                </h2>
                <p className="mt-2 sm:mt-4 font-avenir font-normal text-[14px] sm:text-[18px] lg:text-[18px] leading-7 text-[#646464]">
                  Share some basic info, like where it is and how many guests
                  can stay.
                </p>
              </div>
            </div>
            <div className="flex gap-4 sm:gap-6 w-full h-32 sm:h-40 lg:h-32 border-b-2 border-[#C1E1C2] items-start mt-5 sm:mt-5">
              <div className="bg-color-blue border-2 border-[#FFFFFF] shadow shadow-[#FFFFFF]  xs:w-12 xs:h-12  lg:w-[54px] lg:h-[54px] rounded-full flex justify-center items-center text-[#FFFFFF] font-bold text-[22px] leading-6 font-Poppins">
                2
              </div>
              <div className="w-[70%]">
                <h2 className="font-avenir font-medium text-[18px] sm:text-[24px] lg:text-[24px] leading-7 text-black">
                  Make it stand out
                </h2>
                <p className="mt-2 sm:mt-4 font-avenir font-normal text-[14px] sm:text-[18px] lg:text-[18px] leading-7 text-[#646464]">
                  Add 5 or more photos plus a title and description— we'll help
                  you out.
                </p>
              </div>
            </div>
            <div className="flex gap-4 sm:gap-6 w-full h-32 sm:h-40 lg:h-32 items-start mt-5 sm:mt-5">
              <div className="bg-color-blue border-2 border-[#FFFFFF] shadow shadow-[#FFFFFF] xs:w-12 xs:h-12  lg:w-[54px] lg:h-[54px] rounded-full flex justify-center items-center text-[#FFFFFF] font-bold text-[22px] leading-6 font-Poppins">
                3
              </div>
              <div className="w-[70%]">
                <h2 className="font-avenir font-medium text-[18px] sm:text-[24px] lg:text-[24px] leading-7 text-black">
                  Finish up and publish
                </h2>
                <p className="mt-2 sm:mt-4 font-avenir font-normal text-[14px] sm:text-[18px] lg:text-[18px] leading-7 text-[#646464]">
                  Choose if you'd like to start with an experienced guest, set a
                  starting price, and publish your listing.
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
