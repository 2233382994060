const termsAndConditions = [
  { heading: "Welcome to BnByond!" },
  "By accessing or using BnByond.com, our mobile application, or any other website operated by us where these Terms and Conditions are posted (collectively referred to as the 'Site'), you acknowledge and agree to be legally bound by the following terms and conditions, as well as our Privacy Policy. Together, these constitute the 'Terms' that govern your use of the Site. It is important that you carefully read these Terms as they contain crucial information regarding your rights and responsibilities when using our platform.",
  "When you make a reservation or use BnByond, you are subject to the terms and conditions specified on the BnByond site where your reservation is finalized. In the event of any conflicts between the terms and conditions of the BnByond site where you found the property and the site where you finalize your booking, the terms and conditions of the site where the reservation is finalized shall prevail. If you do not agree to these Terms or any other terms and conditions posted or linked to the Site, you are not authorized to access or use the Site.",
  "BnByond is an online marketplace that facilitates connections between hosts and travelers for short-term property rentals that are exchanged via an internal point system. As the operator of the Site, 'we,' 'us,' or 'our' refer to BnByond. By using our platform, you become a valued member of our community. Please note that you must be at least 18 years old and capable of entering into legally binding contracts to use this Site. We do not knowingly collect information from individuals under the age of 18. If you accessed the Site through another website, these Terms will still apply to your use of our Site.",
  { heading: "BnByond: Connecting Hosts and Travelers" },
  "At BnByond, we serve as a venue for hosts and travelers to connect with each other. We do not own or manage any of the properties listed on our Site, nor do we enter into any vacation rental contracts. Our platform allows homeowners, property managers, and members (collectively referred to as 'users') to advertise and offer their properties for rent via a point system to potential travelers.Additionally, we provide tools and services to facilitate communication and rental agreements between users. ",
  "While we may offer online booking and other services, it is important to note that we are not a party to any rental or other agreements between users. Any transactions or agreements between travelers and hosts, including the quality, condition, safety, or legality of the properties advertised, are solely the responsibility of the users involved. We cannot guarantee the accuracy of listings, reviews, or the availability of properties. Users may need to agree to separate agreements, waivers, or terms and conditions before finalizing a booking or purchasing a product or service.",
  "Users are responsible for complying with all applicable laws, rules, and regulations related to their use of the Site, tools, services, and any transactions conducted on the platform. Hosts must also adhere to the laws, rules, and regulations governing the listing and rental of their properties, including tax and credit card regulations.",
  { heading: "Use of the Site and Content" },
  "You are granted a limited, non-exclusive, and non-transferable license to access and use the Site for its intended purposes. This includes viewing and interacting with the content available on the platform. However, you agree not to reproduce, modify, distribute, display, or exploit any content on the Site without our prior written consent.",
  "As a member of the BnByond community, you are responsible for your actions and interactions on the platform. This includes providing accurate information, complying with laws and regulations, and treating other users with respect and fairness. You agree not to engage in any unlawful, fraudulent, or abusive behavior while using the Site.",

  { heading: "Limitations of Liability and Dispute Resolution" },
  "BnByond strives to provide a reliable and enjoyable experience for all users. However, we cannot guarantee the accuracy, completeness, or availability of the Site or its content. Therefore, we are not liable for any damages or losses arising from your use of the Site or any transactions conducted through the platform.",
  "In the event of disputes between users, we encourage you to seek an amicable resolution. If a resolution cannot be reached, you agree to resolve any disputes through arbitration, rather than in a court of law. These Terms constitute the entire agreement between you and BnByond and supersede any prior agreements or understandings. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.",
  "Use of the Site and its inquiry or booking functionality is limited to advertising and/or researching vacation rentals, making legitimate inquiries to our members, or any other use expressly authorized on the Site. It is prohibited to use the Site to post or transmit information that is false, fraudulent, or misleading, including making any reservation or inquiry under false pretenses, or engaging in any action that may be considered phishing or that would give rise to criminal or civil liability.",
  "Posting or transmitting any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic, or profane material is strictly prohibited.",
  "You must not violate, plagiarize, or infringe upon the rights of us or third parties, including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or privacy, or any other intellectual or proprietary rights.",
  "Using or accessing the Site in any manner that, in our sole discretion, adversely affects, or could adversely affect, the performance or function of the Site or any other system used by us or the Site is prohibited.",
  "Privacy, permits, or license requirements, zoning ordinances, safety compliance, and anti-discrimination laws are important considerations when listing your property. Please note that while we are not directly involved in rental transactions, there may be situations where we are legally obligated to share information about your listing with governmental bodies for investigations, litigation, or administrative proceedings.",
  "If you accept credit card or payment information from travelers, it is your responsibility to handle and protect that information in accordance with legal requirements and best practices.",
  "When traveling to different destinations, there are varying levels of risk involved. We recommend researching your desired location and reviewing travel advisories issued by a reputable federal government site.",
  "While we strive to detect and prevent fraudulent or illegal activity, we cannot guarantee that our measures or warnings will be completely effective or reach all users in a timely manner. Users are encouraged to remain vigilant and take their own precautions.",
  { heading: "License to Use the Site" },
  "As a user, you are granted a limited, revocable, and non-exclusive license to access and use the Site, its content, and services. This license permits you to advertise, search, purchase, or research products and services offered on the Site, participate in interactive areas, or engage in other authorized activities stated on the Site. Any use of the Site beyond these purposes or in violation of the Terms is strictly prohibited.",
  {
    heading: "Unauthorized Uses of the Site",
  },

  "The license granted to users does not include the right to collect, aggregate, copy, scrape, duplicate, display, or use data mining, robots, spiders, or similar tools without our prior written permission. Exceptions are made for general-purpose internet search engines that gather information solely to display hyperlinks to the Site and comply with our robots.txt file.",
  "Unauthorized uses of the Site also include:",
  "Any commercial use of the Site or its content, unless you are a member in good standing or have a valid software license.",
  "Using the Site to book or solicit rentals not listed under a valid subscription or pay-per-booking product.",
  "Copying, uploading, posting, displaying, distributing, or transmitting any part of the content in any form.",
  "Reproducing the Site on your own website or using framing techniques without our express written permission.",
  "Modifying, translating, or creating derivative works from any content or part of the Site.",
  "Reverse engineering any part of the Site.",
  "Selling, transferring, or licensing any portion of the Site to third parties.",
  "Please be aware that these restrictions are in place to protect the integrity of the Site and its services.",
  {
    heading: " Your Email Address and Data; Privacy Policy; Data Transmittal ",
  },

  "When you provide your email address, name, or other information in connection with using the BnByond site, any services or tools provided, or otherwise, you agree to allow us and our affiliated websites to add that information to our user database. You may receive promotional emails from us or our affiliates, but you can choose to opt out of receiving them at any time. For more information about our data collection practices and how to manage your email preferences, please review our Privacy Policy. By using the site, you acknowledge and agree to our Privacy Policy, as it governs our handling of your information. We prioritize the protection of your privacy and will utilize your user-contributed content in accordance with these Terms and our Privacy Policy. Rest  assured that we will only disclose your user-contributed content as outlined in these Terms and our Privacy Policy.",
  { heading: " Identity Verification " },

  "Verifying a user's identity online can present challenges, and we cannot be held responsible for confirming the identity of each user. We encourage you to communicate directly with other travelers or members using the site's available tools. However, please note that even this does not guarantee the identity of the person you're interacting with. We also recommend taking additional reasonable measures to verify the other person's identity and, for travelers, the property and relevant details of your booking or proposed booking. To maintain the security of your account, please ensure that your password and online ID for both your BnByond account and email account are kept confidential. Only share them with authorized users, and immediately notify us and change your online ID and password if you suspect unauthorized access. If someone requests your online ID and password, notify us immediately. Please refrain from sharing your online ID and password, as you will be held responsible for any transactions performed using your account, even if they are fraudulent or unintended. Keep in mind that neither BnByond nor its affiliates will be liable for any unauthorized transactions made using your ID or password. Unauthorized use of your online ID and password could result in liability to both BnByond and other users. We reserve the right to suspend or cancel your listing without notice if we suspect, at our discretion, any unauthorized or fraudulent use of your account or email.",
  {
    heading: " Communication and Use of Other Users' Information; No Spam ",
  },

  "Any personal information you obtain from other users directly or indirectly through the BnByond site or related communications should only be used for site-related purposes. This includes non-commercial communications, using site services, and facilitating financial transactions related to the purpose of the site (such as inquiring about or booking a property or charging a personal credit card). Any other use of this information will require the express permission of the user. Please refrain from using such information unlawfully or with unlawful intent. Remember to respect the privacy and rights of other users when accessing their information and communicating with them. In all cases, you should give users an opportunity to remove their information from your address book, database, or any other records, as well as review the information you have collected about them. Unless stated otherwise, you are prohibited from sharing personal information about another user with any third party without their consent. Similarly, other users may use your personal information to communicate with you, provided they adhere to this provision. Please ensure that you protect other users' personal information with the same level of care you would apply to safeguarding your own confidential information. You assume all responsibility for any misuse, loss, or unauthorized transfer of such information.",
  {
    heading: "Spam and Unsolicited Commercial Electronic Communications ",
  },

  "We have a strict policy against spam and unsolicited commercial electronic communications in any form. Therefore, you are strictlyprohibited from adding a BnByond user, regardless of whether they have rented a property from you or to you, to your mailing list(email or physical mail) without their explicit consent. It is also prohibited to utilize any tool or service on the BnByond site to sendspam or unsolicited commercial electronic communications, or engage in any other activity that violates these Terms.",
  {
    heading:
      "Property Listings, Reviews, and User-Contributed Content; Participation in Community ",
  },

  "We do not assume responsibility for pre-screening user-contributed content posted on the BnByond site, including property listings, reviews, or participation in interactive forums. This content can be directly contributed by the user or by us or a third party on behalf of the user. However, we reserve the right to decline or remove any user-contributed content that fails to meet our Content Guidelines or violates these Terms. We may also remove content if it is brought to our attention that it breaches any part of these Terms or other posting requirements. Additionally, we reserve the right, though not the obligation, to edit a member's content or user-contributed content in a non-substantive manner to ensure compliance with our Content Guidelines or formatting requirements, or to provide listing-related services to members based on available property information.",
  "User-contributed content must, at the very least (as determined by us):",
  "Not infringe on anyone's rights, including copyright, publicity, or privacy.",
  "Not include personal information that could be used to identify or contact individuals.",
  "Not contain unsolicited promotional content, advertising, political campaigns, contests, raffles, or solicitations.",
  "Be directly relevant to the Site, business services, products, or forums where the content is submitted.",
  "Not be obscene, abusive, discriminatory, or illegal.",
  "Not be false or misleading.",
  "All property listings are the sole responsibility of the member, whether they are the owner, property manager, or authorized agent.",
  "We explicitly disclaim any liability for the accuracy of the listings, reviews, or any breaches of contract on a user's part.",
  "It is the members' responsibility to keep their property information up-to-date on the site, including details about amenities, location, pricing, and availability for specific dates.",
  "We cannot guarantee the accuracy or timeliness of the copy, content, traveler or property reviews, guest book entries, property location, suitability, pricing, or availability information published on the site, even if travelers have searched for specific dates or property types.",
  "We may occasionally update property descriptions or change the location or geographic descriptions used to identify properties in listings and search results. This means that the location or geographic description associated with any property listing can be changed without prior notice.",
  "However, it is important to note that we do not verify the content or accuracy of property listings. Members are responsible for ensuring the accuracy of location, geographic details, and other content. If there is any inaccuracy, members should promptly correct it or contact us for assistance. Similarly, travelers are responsible for verifying the accuracy of the content and descriptions.",
  "Regarding user-contributed content, it is the sole responsibility of the user who contributed it, whether directly or through a third-party website. We explicitly disclaim any liability for such content.",
  "By submitting or authorizing user-contributed content, you grant us and our affiliates a license to use, copy, distribute, display, and modify the content in connection with our business. However, after a property listing is terminated or expires, we will not continue displaying the user-contributed content associated with it.",
  "We also possess the ability to protect user-contributed content, including images and copy, from unauthorized use by registering copyrights and taking legal action if necessary. You agree to cooperate with us in protecting such copyrighted material. Additionally, we may sublicense the rights granted to us under these Terms to third parties with whom we contract for promotional or other related services.",
  "If you have any rights of attribution or integrity in the user-contributed content, you waive those rights and agree to the publication, use, modification, deletion, or exploitation of the content by us or our affiliates.",
  {
    heading: "Travelers’ Service Fee and Currency Choice",
  },

  "We impose a service fee on travellers when they book a property through the Site's checkout system. The service fee encompasses the use of the Site and includes features like 24/7 user support. The exact amount of the service fee, which is a variable percentage of the total reservation amount, will be displayed to travelers during the booking process. Depending on the traveler's jurisdiction, VAT may be added to the service fee. The service fee, along with any applicable VAT, will be charged after both the traveler and member accept the reservation. The service fee is refundable only if the traveler is eligible for a full rental refund in accordance with the listing's cancellation policy. Any taxes associated with the service fee are the responsibility of BnByond, and members should not encourage or advise travelers to avoid or bypass the service fee.",
  "Currency Choice and Payment",
  "BnByond permits travelers to view prices on the site in their preferred currency and may offer the option to pay in that currency. Currency choices are based on publicly available rates and may not always reflect the best available rate. If your preferred currency is not supported for a booking, you will be charged in the owner's selected currency. The currency and the exact amount you will pay will be clearly displayed at checkout. Please note that choosing a different currency may affect our overall compensation. Additionally, if you pay in a currency different from your card's currency, your bank or card provider may charge foreign exchange, transaction, or other fees.",
  {
    heading: "Social Media or Third-Party Websites",
  },

  "If BnByond provides a feature that allows us to access or use your profile or other information from Facebook or another third-party website (referred to as a 'Social Media Site'), and you choose to utilize this feature, the following terms apply:",
  "The information or content from your Social Media Site profile that you have designated as 'public' (or similar) will be considered user-generated content under these Terms. Both you and we will have the same rights and responsibilities regarding this content. If the Social Media Content is misclassified, inaccurate, or you disagree with it for any reason, you should work with the Social Media Site to make any necessary changes or resolve disputes. Unfortunately, we will not be able to provide recourse in such cases.",
  "Your profile and account on the Social Media Site will continue to be governed by that site's terms and conditions and privacy policy.",
  {
    heading: "Infringement Notification",
  },

  "Respecting the intellectual property rights of others is important to us. BnByond strictly prohibits and does not tolerate the posting of any content on the site that infringes anyone's copyright. In appropriate circumstances, we will terminate the membership of a user who repeatedly infringes copyright. If you become aware of or suspect any copyright infringement on our site, please refer to our procedures for Notification of Copyright Infringement (DMCA Policy), which are incorporated by reference into these Terms.",
  {
    heading: "Unsolicited Ideas and Feedback",
  },

  "Occasionally, users submit ideas or suggestions related to our business, such as new product ideas, website enhancements, marketing plans, or other creative works. While we appreciate your input, please note the following:",
  "We are under no obligation to review or consider these submissions. If you choose to submit any ideas, suggestions, or creative works to us, they will become the property of BnByond without any compensation to you.",
  "BnByond may freely use or redistribute any submissions and their contents for any purpose.",
  "There is no obligation for BnByond to review or keep any submission confidential.",
  "We value your feedback on various aspects of our business. If you wish to provide feedback, please use the 'feedback' tab located at the bottom right side of the homepage, search results pages, property pages, or owner dashboard. Please note that we assume no obligation to keep your feedback confidential, and we reserve the right to use or disclose such information as we see fit.",
  {
    heading: "Software Available on the Site",
  },

  "BnByond maintains control and operates the Site in the United States. Specific rules apply regarding the software available on our Site:",
  "The Software on the Site is subject to United States export controls. Therefore, you must not download or export the Software (or any software on other sites operated by BnByond in the United States) to Cuba, Iraq, Libya, North Korea, Iran, Syria, or any country embargoed by the United States. Additionally, you must not use the Software if you are on the United States Treasury Department's list of Specially Designated Nationals or the United States Commerce Department's Table of Deny Orders.",
  "By using the Site, you confirm that you are not located in any of the aforementioned countries, not under their control, not a national or resident of any such country, and not listed on any restricted list.",
  "TAll the Software on the Site is copyrighted work owned by BnByond or identified third parties. Your use of the Software is governed by these Terms and any additional license agreement that accompanies it. If there is no additional license agreement, you are granted a limited, personal, and nontransferable license to use the Software solely for viewing and using the Site as per these Terms and for no other purpose.",
  {
    heading: "Software Warranty",
  },

  "Please be aware that the Software, if warranted at all, is only warranted according to the terms specified in the accompanying license agreement. Copying or reproducing any Software available on the Site is strictly prohibited unless explicitly allowed in a license agreement.",
  {
    heading: "Limitation of Liability",
  },

  "16. In order to clarify the extent of our liability, under no circumstances shall BnByond, its parent company, subsidiaries, affiliates, officers, directors, consultants, agents, and employees (collectively referred to as the 'BnByond Group'), or any Third-Party Provider of a service or tool offered on any BnByond site (each a 'Third-Party Provider'), be liable for any indirect, consequential, special, incidental, or punitive damages, including lost profits, arising from or related to:",
  "Our Site",
  "These Terms",
  "Any breach of these Terms by you or a third party",
  "Use of the Site, tools, or services we provide or any Third-Party Provider provides, related to our business on the Site, by you or any third party",
  "Any user-contributed content",
  "Interaction between our Site and any third-party site, including Social Media Sites, facilitated by a tool or service on our Site Any actual or attempted communication or transaction, including payment transactions, between users, even if we or any Third-Party Provider receives a fee in connection with it",
  "These limitations and exclusions apply regardless of the cause of action, whether it is breach of contract, breach of warranty, strict liability, tort, negligence, or any other legal theory, to the maximum extent permitted by applicable law.",
  "In any case, our liability and the liability of any member of the BnByond Group to you or any third party in connection with the Site is limited to the total amount of fees you pay to us in relation to any transaction that gives rise to a dispute.",
  {
    heading: "Disclaimers",
  },

  "At BnByond, we want to ensure that you understand some important points about our Site:",
  "The Site, including all its content, software, functions, materials, and information, is provided 'as is.' We explicitly disclaim any representations or warranties of any kind, express or implied, including non-infringement, merchantability, fitness for a particular purpose, or accuracy, for the content on the Site or the materials, information, and functions made accessible through the Site's software. We do not provide any guarantees regarding products or services or hypertext links to third parties. While we prioritize security, we cannot guarantee the absolute security of transmitting sensitive information through the Site or any linked site. We cannot promise that the functions on the Site or the materials and content therein will be uninterrupted or error-free, that any defects will be corrected, or that the Site or the server making it available is free from viruses or other harmful components. Please be aware that any transmission to and from this Site is not confidential, and your communications or user-contributed content may be read or intercepted by others. By submitting communications or user-contributed content to us and posting information on the Site, including property listings, you acknowledge and agree that no confidential, fiduciary, contractually implied, or other relationship is created between you and us, other than pursuant to these Terms.",
  "At BnByond, we want to ensure that you understand some important points about our Site:",
  "You also acknowledge and agree that you will not hold us or any third-party provider responsible for the content provided by any user, including any translations. Furthermore, we are not a party to any rental transaction or other transaction between users of the Site. We have no control over the safety of any transaction or rental property, or the truth or accuracy of any listing or other content provided on the Site. However, if we offer any guarantees on the Site, they will still apply.",
  "Disclaimer of Travel Risks",
  "Additionally, it is important to acknowledge that when we display information or property listings for specific destinations, we do not represent or warrant that traveling to those destinations is without risk. We cannot be held liable for any damages related to travel to any destination.",
  {
    heading: "Release and Indemnification",
  },

  "In the event that you have a dispute with other users of the Site, any third-party provider, or any third-party website linked to or interacting with the Site, including social media sites, the following provisions apply:",
  "You agree to release, remise, and forever discharge each member of the BnByond Group, including their agents, directors, officers, employees, and all related persons or entities, from any and all rights, claims, complaints, demands, causes of action, proceedings, liabilities, obligations, legal fees, costs, and disbursements arising from or related to such disputes or your use of the Site.",
  "If you are a California resident, you waive California Civil Code Section 1542, which states that a general release does not extend to claims that the creditor does not know or suspect to exist at the time of executing the release and that, if known, would materially affect the settlement with the debtor.",
  "You also agree to indemnify, defend, and hold each member of the BnByond Group (the 'Indemnified Parties') harmless from any liability and costs incurred in connection with any claim arising from your use of the Site or relating to the business conducted on the Site. This includes any communication, transaction, or dispute between you and any other user or third party, any content posted by you or on your behalf, any use of tools or services provided by third-party providers, any use of a tool or service offered by us that interacts with a third-party website, including social media sites, or any breach by you of these Terms. This includes attorneys' fees and costs. You agree to cooperate fully as reasonably required in the defense of any claim. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. You shall not settle any matter without our written consent.",
  {
    heading: "Disputes and Arbitration",
  },

  "We strive to ensure that resolving any issues or disputes you may have with BnByond is a smooth and efficient process. Therefore, we have established a simple two-step dispute resolution process:",
  "Step 1: Let's Talk it Out",
  "If you have a complaint or claim, we encourage you to first reach out to our friendly Traveler Support team. They will investigate the matter and work with you to find a satisfactory resolution. We believe in traveler satisfaction and aim to rectify any issues promptly.",
  "Step 2: Binding Arbitration",
  "If we cannot resolve the issue through Step 1, both parties agree to resolve any disputes through binding arbitration. This means that instead of going to court, an impartial arbitrator will make a final and binding decision. Please note that this arbitration agreement applies to all claims except those that can be handled in small claims court.",
  {
    heading: "Arbitration Agreement",
  },

  "By using our service, you and we agree that any disputes arising from or relating to our Service, these Terms, our Privacy Policy, or any services provided by BnByond will be resolved through binding arbitration. This agreement also covers claims against our subsidiaries, Travel Providers, or any companies offering products or services through us.",
  {
    heading: "No Class Actions or Representative Proceedings",
  },

  "Both parties agree that claims will be conducted on an individual basis and not as part of a class or consolidated action. The right to a jury trial is waived.",
  {
    heading: "Arbitration Rules and Governing Law",
  },

  "The arbitration process will be administered by the American Arbitration Association (AAA) under its Consumer Arbitration Rules, or an agreed-upon arbitral tribunal if the arbitration takes place outside the United States. The arbitrator will apply the laws of the state of Washington to the dispute.",
  {
    heading: "Mandatory Pre-Arbitration Dispute Resolution and Notification",
  },

  "Before initiating arbitration, we request that you give us an opportunity to resolve the issue. You must provide us with a written Notice of Dispute, including details of your complaint and the resolution you seek. If we cannot resolve the matter within 60 days, you may proceed with arbitration.",
  {
    heading: "Commencing Arbitration",
  },

  "To initiate arbitration, you are required to file a Demand with the American Arbitration Association (AAA) and provide a copy to us. The arbitration will be conducted by a single arbitrator, and the location of the arbitration proceedings can be determined based on convenience or conducted through videoconference.",
  {
    heading: "Attorneys' Fees and Costs",
  },

  "Each party is responsible for bearing their own arbitration fees and their respective share of the arbitrator's compensation. However, if the costs associated with the arbitration become excessively burdensome, we will intervene and cover the necessary fees to ensure that the arbitration process is not unduly onerous.",
  {
    heading: "Severability and Survival",
  },

  "In the event that any part of this Disputes and Arbitration provision is determined to be unenforceable, the remaining provisions shall remain valid. If a claim needs to proceed on a class, collective, consolidated, or representative basis, it must be resolved in a civil court.",
  {
    heading: "Traveler Support and Handling Complaints",
  },

  "For any inquiries or assistance, please visit our support page or contact us at:",
  "Attn: Traveler Support",
  "BnByond - a division of Great Big Brains, Inc.",
  "Nova Scotia, Canada",
  {
    heading: "Additional Terms and Conditions for Online Bookings",
  },

  "In addition to the aforementioned provisions, if you utilize our ReservationManager™ or any other online tool provided on our site t   book and make payments for rentals, there are supplementary terms that apply. These additional terms complement the existin   terms and conditions previously provided. Moreover, if there are any third-party providers involved in payment or other services   their respective terms, conditions, and privacy policies shall also apply. It is crucial to acknowledge and understand these legal  obligations.",
  "By utilizing the Reservation Services, which include managing inquiries, obtaining quotes, and handling rental agreements and payments, you must carefully review these additional terms as well as those provided by third-party providers. If you do not agree with any of these terms, regrettably, you will be unable to utilize our Reservation Services. Furthermore, it is essential to recognize that non-compliance with these terms may result in significant legal consequences.",
  "Moreover, when using a rental agreement as part of the Reservation Services or encountering any rental-related terms during the online booking process (including those we require), you are acknowledging and agreeing to those specific terms. This digital interaction represents your acceptance of responsibility for complying with the terms and conditions, whether they are sample agreements we provide or agreements you copy and paste into ReservationManager. It is your sole responsibility to ensure compliance with the law and encompass all essential details in your rental agreements. Please note that we are not legal professionals, so it is advisable to consult with a legal expert to ensure that your rental agreements are legally compliant and encompass all pertinent aspects.",
  "Additionally, certain members, particularly property managers, may employ software from our affiliate HomeAway Software, Inc., or other third parties. These 'Other Reservation Services' have their own set of terms and conditions. If you opt to utilize these services, you must also adhere to their respective rules in conjunction with our Terms. Therefore, you are subject to a dual set of terms and conditions, which may appear intricate but are vital for understanding and compliance.",
  {
    heading: "Your Property and Guest Liability",
  },

  "Insurance holds significant importance. As a user, it is your responsibility to obtain appropriate insurance coverage for your property or your trip as a guest. Ensuring adequate coverage is crucial, and as a member listing properties on our site, you agree to have the necessary insurance coverage in place before the arrival of your first guest, maintaining it until the departure of your last guest. We may request proof of coverage for verification purposes.",
  "Moreover, if you are interested in Property Damage Protection, we offer a solution in collaboration with CSA Travel Protection and Insurance Services. Should you choose this plan, it is imperative to adhere to its terms and conditions, which can be found in the Description of Coverage. This plan may eliminate the need for a security deposit, providing a convenient alternative. However, please ensure that you offer the same level of coverage to all your guests, promoting fairness and equality.",
  "Additionally, we provide Cancellation Protection, also administered by CSA Travel Protection and Insurance Services. This plan encompasses its own set of terms and conditions, which are elaborated upon in the Description of Coverage. We recommend thoroughly reviewing these terms to gain a comprehensive understanding of the plan's provisions.",
  {
    heading: "General",
  },

  "These Terms are governed by the Federal Arbitration Act, the rules of the American Arbitration Association (AAA), federal arbitration law, and, for users in the United States, the laws of the state corresponding to your billing address. We prioritize the authority of the Federal Arbitration Act and the AAA rules, which supersede any conflicting state laws.",
  "By the way, in our relationship, we operate as independent contractors. There is no agency, partnership, joint venture, employer- employee, or any other formal relationship between us. It is simply a matter of you utilizing our website while we provide the platform. Clear, right?",
  "If you need to send us any notices, please do so by regular postal mail to the following address: HomeAway.com, Inc., Attn: Great Big Brains Inc, Nova Scotia, Canada B3S 1K6. We will ensure that your message reaches us.",
  "On the other hand, when we need to communicate with you, we will use the email address you provided during registration or the booking/inquiry process. Please ensure that your email address remains up to date. We will consider the notice received once you receive it or 24 hours after we send it, unless your email address is invalid. Additionally, we reserve the right to send you a notice via certified mail to the physical address you provided. Such notice will be considered received three days after we send it. It's important for you to be aware of this.",
  "Occasionally, we may need to make changes to the website, such as adding or removing features, or even restricting your access to certain parts or the entire site. We will make our best efforts to inform you about these changes, but we cannot guarantee that we will always be able to do so. Furthermore, these Terms became effective on the mentioned date and replace any previous versions. We may modify these Terms at any time, and the updated terms will take immediate effect. Stay vigilant for any updates!",
  "Guess what? We may introduce special programs, products, or services that come with their own distinct terms and conditions. If such programs, products, or services arise, the corresponding terms will apply alongside these Terms. It's like a mix-and-match of terms and conditions, but don't worry, we won't implement anything drastic without your agreement.",
  "We also retain the right to modify the rates and fees associated with listings, whether they are subscription-based or pay-per- booking. We will inform you of any changes; however, please be aware that these rates and fees may fluctuate without prior notice. We aim to maintain fairness, after all.",
  "Oh, and the products and services we offer may undergo changes as well. We may introduce new offerings or make adjustments to existing ones. All such changes are subject to modification without prior notice. We strive to keep things dynamic, right?",
  "Just so you know, we do not maintain separate records of the Terms for each user. Therefore, please ensure to keep a copy for yourself by printing or saving them on your computer. And remember, if you violate these Terms or engage in any improper use of the site, we reserve the right to terminate your access. However, please note that our lack of immediate action does not waive our right to take subsequent action. Keep things legitimate, folks.",
  "These Terms represent the entire agreement between us and you concerning the use of the site, superseding any prior agreements. Also, those headings you see are provided for reference purposes only and hold no legal significance. In the event of a conflict between these Terms and other specific terms and conditions governing particular products or services, these Terms take precedence. If any portion of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. We are quite resilient.",
  "We have the ability to assign these Terms as we see fit. However, if you wish to transfer these Terms to another party, you must obtain our written consent. We will determine whether such a transfer is permissible.",
  "Finally, let's address our commitment to fostering a respectful and inclusive marketplace. We welcome individuals from all backgrounds and expect everyone to treat each other with respect both online and offline. Failure to abide by these principles may result in the termination of your access. Let's maintain a friendly and enjoyable environment for everyone, shall we?",
  "23. Membership and Listing Requirements Agreement",
  "This Membership and Listing Requirements Agreement ('Agreement') is entered into between [Your Name/Organization] ('Member') and BnByond ('Company'), collectively referred to as the 'Parties.'",
  "Eligibility and Authority",
  "1.1 Member represents and warrants that they are eligible to use the services provided by BnByond and have the legal capacity to enter into binding contracts in accordance with applicable laws.",
  "1.2 If Member is registering on behalf of a business entity, Member represents and warrants that they have the authority to agree to these Terms on behalf of that business.",
  "Accuracy of Information",
  "2.1 Member agrees to provide true, accurate, and up-to-date information during the registration process.",
  "2.2 Member acknowledges the obligation to promptly notify BnByond of any changes to the provided information.",
  "Listing Requirements",
  "3.1 Member shall maintain an up-to-date reservation calendar for each listed property, providing accurate availability information to travelers.",
  "3.2 Member shall make reasonable efforts to respond to booking requests from travelers within 24 hours to ensure a satisfactory experience.",
  "3.3 Member shall process traveler-authorized payments within 24 hours to ensure efficient and smooth transactions.",
  "3.4 Member shall respond to inquiries and booking requests from travelers and accept a reasonable number of them to maintain a high-quality traveler experience.",
  "3.5 Member shall minimize cancellations of accepted bookings, except in cases of unforeseen circumstances.",
  "Member Responsibilities",
  "4.1 Member represents and warrants that they own the listed property or have the necessary rights and authority to rent it out.",
  "4.2 Member shall not withhold rental deposits without a valid reason and shall adhere to the terms of the rental agreement.",
  "4.3 Member shall ensure that the property description is accurate, honest, and does not conceal any material information or defects.",
  "4.4 Member shall not deny access to the listed property without a justifiable reason, aiming to provide excellent experiences for travelers.",
  "4.5 Member shall provide refunds promptly in accordance with the cancellation policy or rental agreement when due.",
  "4.6 Member acknowledges that if the Company, at its sole discretion, compensates a traveler for a loss resulting from Member's actions or omissions, the Company may seek reimbursement from Member for the amount paid.",
  "Proof of Identity and Property Ownership",
  "5.1 Member agrees to promptly provide proof of identity, property ownership, or any other requested information to the Company when required to ensure the legitimacy of the listing.",
  "5.2 If Member is a tenant listing a property, Member acknowledges the importance of checking the lease or rental contract for any legal obligations or restrictions before listing the property.",
  "Listing Appearance in Search Results",
  "6.1 Member acknowledges that the Company cannot guarantee the specific placement of the listing in search results. The order of search results may vary based on factors such as search parameters, listing quality, response time, and other relevant criteria.",
  "6.2 Member understands that their listing may not always appear in search results, and the order of appearance may differ between the mobile app and the website. Temporary changes to search algorithms or tests may occur to improve the search experience.",
  "Content Publication and Presentation",
  "7.1 Member acknowledges that the Company has the right to refuse any content that does not comply with these Terms or is deemed unsuitable by the Company.",
  "7.2 The Company is not responsible for reviewing all content and shall not be liable for any loss or damage related to the design or positioning of Member's content.",
  "7.3 Member agrees to ensure that their content adheres to the Company's guidelines and accurately represents the intended appearance on the site. The Company may make minor edits to the content to meet formatting requirements or guidelines.",
  "By becoming a BnByond Member and listing a property, Member agrees to abide by the terms and conditions stated in this Agreement.",
  {
    heading: "Photography Agreement",
  },
  "Photographs play a crucial role in showcasing your vacation rental on BnByond. In order to ensure an excellent presentation while respecting the rights of all parties involved, the following terms apply:",
  "a. Photographs should primarily focus on the vacation rental itself. If individuals are included in the pictures, it is imperative to obtain their legal consent. Privacy and copyright concerns must be avoided at all costs. BnByond reserves the right to refrain from displaying or remove any photographs that do not comply with these terms or are deemed unacceptable in our sole discretion.",
  "b. By submitting a photograph to BnByond, you affirm the following:",
  "You hold all intellectual property rights, including copyrights, pertaining to the photograph.",
  "You possess the necessary permission from any individuals depicted in the photograph to display their likeness in an online advertisement on our site.",
  "The photograph accurately and faithfully represents the subject it intends to portray, without any misleading alterations or manipulations.",
  "You agree to indemnify and hold BnByond and its team members harmless from any claims or legal actions arising due to misrepresentation or alleged copyright infringement related to the submitted photograph.",
  "c. It remains your sole responsibility to acquire all required permissions for utilizing and posting photographs in your listings. You  must either own the copyright or possess authorization from the copyright owner to grant BnByond the rights to use these  photographs. In the event that BnByond requests evidence of such rights, you agree to promptly provide the requested proof",
  "Additionally, please be aware that BnByond may utilize your photographs for promotional purposes of your property or our platform, aiming to increase visibility and exposure.",
  {
    heading: "Trademark and Logo Usage",
  },
  "To ensure appropriate usage of our trademarks and logos, the following guidelines must be observed:",
  "a. You may refer to BnByond or our affiliate websites in a descriptive manner within your listing or other communications. For instance, stating 'Check out my vacation rental on BnByond' or 'I list properties on BnByond' is permissible. However, any statements implying sponsorship, affiliation, or endorsement of your company, property, or listing by BnByond or its affiliates are strictly prohibited. Examples include phrases such as 'BnByond sponsors my vacation rental' or describing your property as 'BnByond's best vacation rental.'",
  "b. Our names and logos are protected trademarks and generally may not be used without prior written authorization, except as  described above. If you seek to use our name or logo in other contexts or have inquiries regarding their usage, please visit  help.bnbyond.com.",
  {
    heading: "Hyperlinks and Web Addresses",
  },
  "BnByond reserves the right to deny hypertext links to, or addresses of, other websites from members' pages, and we may remove or refuse such links without prior notice at our sole discretion. Furthermore, please note that we reserve the right to impose charges for hypertext links at any given time.",
  {
    heading: "Property Substitution and Multiple Listings",
  },
  "a. Each listing on BnByond must correspond to a distinct and individually identified property, unless you have acquired a listing package that explicitly allows property substitution. This implies the following:",
  "The property specified in a listing cannot be substituted with another property without the express consent of BnByond. We may exercise discretion in approving such requests upon the termination of a property manager's contract, provided that sufficient evidence, as requested by us, is presented, and any additional forms required are completed. The substituted property's listing type and term must match those of the originally-listed property, without exceeding the original term. If changes submitted for an existing listing substantially transform it into a representation of another property, BnByond reserves the right to terminate the listing. Any fees associated with non-compliant listings may be retained by BnByond as compensation.",
  "b. Listings should exclusively pertain to one specific property, unless you possess multiple rental units and have purchased additional listings. If a single listing describes multiple properties, BnByond may undertake alterations or remove the listing altogether.",
  {
    heading: "Payment Method Requirements; Bank and Credit Card Fees",
  },
  "At BnByond, we have specific requirements when it comes to payment methods. Here are the details:",
  "We do not accept cash or instant-cash wire transfer services such as Western Union or MoneyGram for property rental transactions. Our aim is to ensure a secure and transparent environment. Violations of this policy or the use of unacceptable payment methods may result in the immediate removal of non-compliant listings from our site, without prior notice or refund. We prioritize maintaining the integrity of our platform.",
  "Occasionally, we come across users attempting unauthorized or fraudulent payment methods. While we strive to assist you in avoiding such situations, we cannot guarantee prevention or accept responsibility for any suspicious activity. We encourage you to remain vigilant.",
  "It's important to note that your bank or credit card company may charge fees, including foreign transaction fees, for property rentals. We recommend reviewing your agreement with your financial institution to understand the applicable charges.",
  {
    heading:
      "Subscription Payments; Automatic Renewal of Subscription Payments",
  },
  "When it comes to paying for subscription listings, simplicity is key. Here's how it works:",
  "We accept payment in U.S. Dollars, and you have the flexibility to use major credit or debit cards, PayPal, or checks drawn from U.S. banks. We want to make the process as convenient as possible for you.",
  "If you choose to pay for your subscription with a credit card or PayPal, it will automatically renew at the end of the current term. The renewal will be for the same duration and at the regular non-promotional rate. However, if you opt for a check or another non- card/PayPal payment method, your subscription will not renew automatically. In that case, you'll need to manually renew or activate a new subscription.",
  "To manage your subscription's auto-renewal feature, simply log into your account and navigate to your owner dashboard. Make sure to disable auto-renewal at least five (5) days before your current term expires. If you choose to turn off auto-renewal, your subscription will remain active until the end of the current term but will not renew automatically thereafter.",
  "By continuing to use our subscription service without disabling auto-renewal, you authorize us to charge your payment method at  the end of each term. The charge will be for the same duration and at the regular rate applicable to the product or service you  selected. Please be aware that you are responsible for these charges, so it's essential to keep track of your subscription.",
  "In the event that the product or service you previously purchased undergoes changes or is no longer available, we will charge your  payment method for the most similar product or service that we offer. We will determine the closest match, and although the price  may differ, you will still be responsible for the associated charges. If you need to update your payment method or payment information, you can find instructions at help.bnbyond.com.",
  "If you have a listing that is not on a subscription basis, we will provide you with compensation details during the registration process. Please note that we reserve the right to modify the compensation without notice. Any updates will be displayed on the site where you registered your listing. Additional terms and conditions may apply, so please pay attention to any notices we send your way.",
  {
    heading: "Subscription Term and Refund Requests",
  },
  "When you subscribe to a listing, you commit to the chosen term. Here's what you should know:",
  "Subscription listings run for the full term you select. The term begins upon receipt of your full or initial payment and lasts for one  year. For instance, if you pay for an annual subscription on July 1st, it will expire on June 30th of the following year. If you choose to  renew or if your subscription automatically renews, your listing will remain online for the new subscription period, and no refunds will  be provided.",
  "Generally, we do not offer refunds unless a special program is in effect. If you believe you meet the criteria for a refund, please  contact our customer support team. Ensure to provide your listing number and explain the reason for your refund request. We will  review your request and make a decision at our discretion. If you sell your property and wish to remove the listing, please reach out  to us. However, please note that no refund will be issued in such cases.",
  {
    heading: "Additional Terms for Pay-Per-Booking Listings",
  },
  "The terms and conditions that are in effect at the time a booking is accepted shall govern and supersede any prior agreement  between the user ('you') and BnByond ('we' or 'us') concerning the use of the platform.",
  "These terms constitute the entire agreement between you and us, encompassing all matters herein. The features and applicable fees for pay-per-booking listings will be provided under the 'List Your Property' section of the site, subject to additional terms, conditions, and requirements specified during the registration process, including those imposed by third-party providers.",
  "Listing a property on a pay-per-booking basis necessitates mandatory online booking and payments. The online payment service, facilitated by third-party providers, is subject to their respective terms and conditions and privacy policies.",
  "You retain the option to convert pay-per-booking listings to subscription listings at any time. However, any bookings made prior to conversion shall remain subject to the relevant pay-per-booking fees. All pay-per-booking listings must include cancellation policies,  with specific requirements displayed in the 'List Your Property' section.",
  {
    heading: "Distribution of Listings to Third-Party Websites",
  },
  "In order to expand the visibility of members' properties, we may distribute listing information and content to third-party websites. Additional terms and conditions may apply to such distributions, and we shall provide notification via the owner dashboard or email.",
  {
    heading: "Termination of Listings; Other Actions",
  },
  "In our sole discretion, if we determine that a member has submitted unsuitable material, violated local rental regulations, caused  disruptions in the community, misused the site or our online system, or materially breached these terms, we reserve the right to take  the following actions without providing a refund:",
  "Restrict the member's use of the site",
  "Affect the search position of the member's listing(s)",
  "Immediately terminate the member's listing(s)",
  "Furthermore, if we receive complaints from users or third parties regarding a member's listing or rental practices that we deem necessitate the immediate removal of the listing, we may terminate the member's listing(s) or subscription(s) without prior notice or refund. Examples of such situations include double-booking a property for multiple travelers on the same date or engaging in deceptive or unfair practices within the vacation rental industry or online marketplace. Additionally, if we suspect that a member's payment-related practices are insecure, illegal, or improper, or if we receive a complaint regarding infringement of third-party rights in relation to a listing's content, we may take immediate action to terminate the member's listing(s) or subscription(s).",
  "While we have no obligation to investigate complaints, we reserve the right to respond to violations or misuse of the site. This may  entail actions such as hiding a listing from search results, marking a member as 'out of office,' or removing or modifying false or  misleading information.",
  "Moreover, you agree to defend and indemnify BnByond, its affiliated companies, and their officers, directors, employees, and agents  against any claims, demands, losses, damages, fines, penalties, or other costs or expenses, including reasonable legal and  accounting fees, brought by third parties as a result of your breach of these terms, violation of any law or the rights of a third party,  or your use of the site.",
];

export default termsAndConditions;
