import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { localStorageData, Logout } from "services/auth/localStorageData";
import IndexDropdown from "components/Dropdowns/IndexDropdown";
import { IoNewspaper } from "react-icons/io5";
import { LuContact2 } from "react-icons/lu";
import { FaListCheck } from "react-icons/fa6";
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa";

const BnbNavMob = ({ navbarOpen, setNavbarOpen }) => {
  const [loginAs, setLoginAs] = useState("");

  useEffect(() => {
    const loginAsValue = localStorageData("loginAs");
    setLoginAs(loginAsValue);
  }, []);

  const handleChangeRole = (newRole) => {
    setLoginAs(newRole); // Update the state to trigger a re-render
  };

  return (
    <div className="md:hidden block">
      <div className="bg-sky-800 h-[102vh] fixed top-0 left-20 right-0 p-2 m-0 ">
        <div className="flex justify-between items-center ">
          <Link
            className="inline-block py-2 mr-4  text-sm font-bold leading-relaxed text-white uppercase whitespace-nowrap"
            to="/"
          >
            <img
              className="object-cover w-28"
              src={require("assets/img/whitelogo.png")}
              alt=""
            />
          </Link>
          <div className="flex justify-end px-4 pt-5 ">
            <FaArrowLeft className='text-white cursor-pointer'
              onClick={() => setNavbarOpen(!navbarOpen)} />
          </div>
        </div>
        <Grid item xs={12} sm={12}>
          <ul className="h-full text-white">

            <li className=" flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/aboutus" className="flex items-center gap-2 h-full">
                <FaInfoCircle />
                About Us
              </Link>
            </li>
            <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/blogs" className="flex items-center gap-2  h-full">
                <IoNewspaper />
                Blogs & News
              </Link>
            </li>
            <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/contactus" className="flex items-center gap-2  h-full">
                <LuContact2 />
                Contact Us
              </Link>
            </li>
            {loginAs === "Host" && (
              <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
                <Link to="/propertylisting" className="flex items-center gap-2  h-full">
                  <FaListCheck />
                  List Your Properties
                </Link>
              </li>
            )}

            <li className="flex pl-2 items-center py-4">
              {localStorageData("_id") ? (
                <IndexDropdown onRoleChange={handleChangeRole} />
              ) : (
                <a href="/auth/signin">
                  <div className="bg-white flex gap-2 items-center rounded-full w-[104.25px] h-[37.27px]">
                    <img
                      className="object-contain w-[28px] ml-2 h-[28px] rounded-full"
                      src={require("assets/img/log.png")}
                    />
                    <button className="text-[#000000] text-base font-normal">
                      Login
                    </button>
                  </div>
                </a>
              )}
            </li>
          </ul>
        </Grid>
      </div>
    </div>
  );
};

export default BnbNavMob;
