import React from "react";
import LandingSearchBar from "components/LandingSearchBar/LandingSearchBar";

export default function LandingHeader() {

  return (
    <div>
      <div className="relative pt-16 pb-32 header-height h-full">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover "
          style={{
            backgroundImage: "url(" + require("assets/img/homepagebg.jpg") + ")",
          }}
        >
        
          <span
            id="blackOverlay"
            className="absolute w-full h-full bg-black opacity-10"
          ></span>
        </div>
        <div className="relative z-10 mt-4 ">
          <LandingSearchBar />
        </div>
      </div>
    </div>
  );
}
