import React from "react";
import { Route, Routes } from "react-router-dom";
import SidebarAdmin from "components/Sidebar/SidebarAdmin";
import AdminLogin from "pages/admin/AdminLogin";
import AdminProtectedRoute from "./AdminProtectedRoute";

function Admin() {
  return (
    <>
      <Routes>
        <Route element={<AdminProtectedRoute />}>
          <Route path="/dashboard" element={<SidebarAdmin />} />
        </Route>
        <Route path="/login" element={<AdminLogin />} />
      </Routes>
    </>
  );
}

export default Admin;
