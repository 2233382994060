import React, { useState } from 'react'
import ReportList from './ReportList'
import ReviewsList from './ReviewsList'
import AdminPropertyDetail from '../PropertyManagement/AdminPropertyDetail';

const ReviewsSteps = ({ active, All, Report, setSteps }) => {

   const [propertyDetail, setPropertyDetail] = useState(null);

  // Function to set the current step and display the details of a selected property.
  const handleSetStepsAndDetail = (_id) => {
    console.log("Selected Property ID:", _id); // Log the selected ID
    const detail = All.find(property => property._id === _id);
    console.log("Property Detail Found:", detail); // Log the property details found
    setSteps('AdminPropertyDetail');
    setPropertyDetail(detail);
  };

  return (
    <>
      {active === 1 && <ReviewsList allProperty={All} setSteps={propertyDetail} setStepsAndDetail={handleSetStepsAndDetail} />}
      {active === 2 && <ReportList Report={Report} />}
      {active === "AdminPropertyDetail" &&
        <AdminPropertyDetail propertyDetail={propertyDetail}  />
      }
    </>
  )
}

export default ReviewsSteps