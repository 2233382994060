import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import PhoneInput from "react-phone-number-input";
import OtpInput from "react-otp-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import { localStorageData } from "services/auth/localStorageData";
import pic from "../../assets/img/computer.png";
import pic_bg from "../../assets/img/computer_bg.png";

const SendVerificationCode = () => {
  const [phone, setPhone] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  // API call for sending the OTP
  const { mutate, isLoading } = useMutation(
    (token) => userServices.otpService("/userAuth/otpsend", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        // If OTP is successfully sent, show the OTP input field
        if (data.status == 200) {
          setShowOTP(true);
        } else {
          console.error("Failed to send verification code");
          return;
        }
      },
    }
  );

  //API call for OTP verification
  const { mutate: passMutate, isLoading: loadingVerify } = useMutation(
    (token) => userServices.otpService("/userAuth/otpverification", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        // If OTP is verified successfully, navigate to the home page
        if (data.status == 200) {
          navigate("/");
          toast.success("Login successful");
        } else {
          toast.error("Incorrect OTP. Please try again.");
          return;
        }
      },
    }
  );

  // handle sending the verification code
  const handleSendVerificationCode = () => {
    const payload = {
      userId: localStorageData("_id"),
      phoneNumber: phone,
    };
    mutate(payload); // Trigger the OTP sending mutation
  };

  //function to handle OTP verification
  const handleVerifyOTP = () => {
    const payload = {
      otp: otp,
      userId: localStorageData("_id"),
    };
    passMutate(payload); // Trigger the OTP verification mutation
  };

  return (

    <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md mt-14">
      <div className="relative">
        <img src={pic_bg} alt="phone background" className="w-40 mx-auto" />
        <img src={pic} alt="phone" className="w-20 mx-auto absolute top-0 left-0 right-0 bottom-0 m-auto" />
      </div>

      <h2 className="text-2xl font-semibold my-6">
        {showOTP ? "Enter OTP" : "Enter Your Phone Number"}
      </h2>

      {/* Phone input field */}
      {!showOTP ? (
        <div className="mb-4">
          <label
            htmlFor="phone"
            className="my-2 block text-sm font-medium text-gray-600"
          >
            Phone Number
          </label>
          <PhoneInput
            id="phone"
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            international
            countryCallingCodeEditable={false}
            defaultCountry="US"
            inputStyle={{
              width: "100%",
              height: "45px !important ",
              border: "none",
              background: "rgba(118,118,128,0.12)",
            }}
          />
          <input type="checkbox" className="mt-4 w-4" />
          <span className="text-xs text-gray-600 ml-2">Remember this device</span>

        </div>

      ) : (
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          // renderSeparator={<span>-</span>}
          renderInput={(inputProps, index) => (
            <input
              {...inputProps}
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
                textAlign: "center",
              }}
            />
          )}
        />
      )}
      {isLoading || loadingVerify ?
        <button
          type="button"
          onClick={showOTP ? handleVerifyOTP : handleSendVerificationCode}
          className="bg-blue-500 text-white rounded-[50px] w-full hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-700 mt-4"
        >
          Loading...
        </button>
        : <button
          type="button"
          onClick={showOTP ? handleVerifyOTP : handleSendVerificationCode}
          className="bg-blue-500 text-white w-full  px-4 py-2 rounded-[50px] hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-700 mt-4"
        >
          {showOTP ? "Verify OTP to continue" : "Send Verification Code"}
        </button>}
    </div>

  );
};

export default SendVerificationCode;
