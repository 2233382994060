import React from "react";

const RefundStep1 = ({ step, setStep }) => {
  return (
    <>
      <div className="grid md:grid-flow-col grid-flow-row ">
        <div className="md:py-5 md:grid-cols-6 mr-10">
          <h1 className="font-[800px] text-[20px] font-bold">
            Why do you need to refund?
          </h1>
          {/* <p className="font-medium text-[18px] py-2">Please select a reason</p> */}
          {/* <select className="w-full  border border-[#AFB5C1] text-[#AFB5C1] p-4 rounded">
            <option value="accomodation" onClick={() => setStep("2")}>
              I no longer need accomodation
            </option>
            <option value="accomodation" onClick={() => setStep("2")}>
              I no longer need accomodation
            </option>
            <option value="accomodation" onClick={() => setStep("2")}>
              I no longer need accomodation
            </option>
          </select> */}
          <button className="px-4 py-2 border border-slate-400 rounded-md my-2" onClick={() => setStep("2")}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default RefundStep1;
