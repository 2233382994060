import { Account } from 'components/Account'
import React from 'react'

export const GuestAccount = () => {
  return (
    <>
      <Account />
    </>
  )
}
