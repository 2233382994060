
export const ButtonWithoutBackground = ({ text,onClick }) => {
    const handleClick = () => {
        if (onClick) {
          onClick();
        }
      };
    return (
        <button
      onClick={handleClick}
      className={`text-black text-center font-normal font-lato text-base underline hover:underline focus:underline cursor-pointer flex justify-center items-center`}
    >
      {text}
    </button>
    )
}