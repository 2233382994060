import React, { useState } from 'react'
import { TaxPayer } from './TaxPayer';
import { TaxDocuments } from './TaxDocuments';

export const Taxes = () => {
    const [selectedTab, setSelectedTab] = useState('taxPayer'); // Initial selected tab

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-color-green h-auto w-full p-5">
                <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
                    <ul className="flex flex-wrap -mb-px cursor-pointer">
                        <li className="me-2 text-[#AFB5C1]">
                            <p
                                onClick={() => handleTabClick('taxPayer')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'taxPayer'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                                TayPayer
                            </p>
                        </li>
                        <li className="me-2 ">
                            <p
                                onClick={() => handleTabClick('taxDocuments')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'taxDocuments'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                                TaxDocuments
                            </p>
                        </li>
                    </ul>
                </div>
                {selectedTab === 'taxPayer' && <TaxPayer />}
                {selectedTab === 'taxDocuments' && <TaxDocuments />}
            </div>
        </div>
    )
}
