import React, { useState } from 'react'
import { Container } from "@mui/material";
import Ellipse77 from "../../../assets/img/Ellipse77.png";
import { Rating } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import TopCard from '../UserManagement/TopCard';
import Dropdown from '../UserManagement/Dropdown';
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import AdminModal from '../AdminModal/AdminModal';

const Permissions = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 flex justify-between pt-7">
            <div className="">
              <h1 className="text-[24px] font-extrabold">Roles & Permissions</h1>
            </div>

            {isModalOpen && <AdminModal openModal={handleOpenModal} />}
            <button className="bg-[#2459BF] w-130 rounded-full w-[170px] h-[50] text-color-cream"

              onClick={() => setIsModalOpen(true)}

            >
              + ADD NEW ROLE
            </button>
          </div>

          <div className=" my-3 flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white">
            <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
              <div className=''>
                <p className='font-medium sm:text-lg text-sm'>All Roles</p>
              </div>
              <div className='flex  space-x-2'>
                <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                  <div>
                    <img src={icon3} />
                  </div>
                  <div>
                    <p className='font-normal text-xs text-gray-600'>Fillter</p>
                  </div>
                </div>
                <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px] border border-gray-300 rounded-md'>
                  <div>
                    <img src={icon5} />
                  </div>
                  <div>
                    <p className='font-normal text-xs text-gray-600'>Export</p>
                  </div>
                </div>

                <div className="relative md:w-64  ">
                  <input
                    type="text"
                    placeholder='Search'
                    className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                  />
                  <img
                    src={icon4}
                    alt="search icon"
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                  />
                </div>
              </div>
            </div>

            <div className='overflow-auto'>
              <table className="table-auto border-collapse w-[800px]  md:w-full bg-white">
                <thead>
                  <tr className='bg-[#fafbff]'>
                    <th className="px-4 py-2 border-t border-b text-left">
                      <input type="checkbox" className="mr-2" />
                      Name
                    </th>
                    <th className="px-4 py-2 border-t text-left border-b">Role</th>
                    <th className="px-4 py-2 border-t text-left border-b">Joined On</th>
                    <th className="px-4 py-2 border-t text-left border-b">Daily Report</th>
                    <th className="px-4 py-2 border-t text-left border-b">Permissions</th>
                    <th className="px-4 py-2 border-t  text-left border-b">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border-t border-b flex flex-col items-start">
                        <div className="flex items-center">
                          <input type="checkbox" className="mr-2" />
                          <img src={Ellipse77} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                          <div className='flex flex-col'>
                            <span className="font-medium text-sm leading-5 text-black">Gustavo Donin{index + 1}</span>
                            <span className="font-medium text-xs leading-4 text-gray-400">user@gmail.com</span>

                          </div>
                        </div>

                      </td>


                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">Administrator</td>

                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                        <div className='flex flex-col'>
                          <span>Feb 29, 2024</span>
                          <span className="font-medium text-xs leading-4 text-gray-400">03:00 AM</span>
                        </div>
                      </td>

                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                        <button className="font-extrabold text-xs leading-4 text-center text-[#2459BF]">View Report</button>
                      </td>
                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                        <button className="font-extrabold text-xs leading-4 text-center text-[#2459BF]">View Details</button>
                      </td>
                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                        <div className="w-[56px] h-[30px] bg-green-500 bg-opacity-10 rounded-full flex justify-center items-center">
                          <button className="font-extrabold text-xs leading-4 text-center text-green-500">Active</button>
                        </div>
                      </td>
                      <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black"><BsThreeDotsVertical /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>

        </Container>
      </section>
      {isModalOpen && <AdminModal closeModal={handleCloseModal} />}
    </>
  )
}

export default Permissions