import React from "react";
import { ButtonOutlined } from "common/buttons/buttonOutlined";
import termsAndConditions from "../../../common/commonText/TermsAndCondition";

const TermsAndConditions = () => {

  return (

    <div className="md:container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-2/3">
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h1 className="text-3xl font-bold  mb-8">
              Terms and Conditions
            </h1>
            <p className="text-gray-800 font-normal text-sm mb-4">
              Last updated: July 4th, 2023
            </p>
            {termsAndConditions.map((item, index) => {
              if (typeof item === "string") {
                return (
                  <p
                    className="text-gray-800 text-justify my-2 text-sm"
                    key={index}
                  >
                    {item}
                  </p>
                );
              } else if (typeof item === "object" && item.heading) {
                return (
                  <h2 className="text-xl font-semibold my-4" key={index}>
                    {item.heading}
                  </h2>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="bg-white rounded-lg p-6 shadow-md sticky top-10">
            <h2 className="text-lg font-medium mb-4">Need to get in touch?</h2>
            <p className="text-gray-600 mb-4 text-sm font-normal">
              We’ll start with some questions and get you to the right place.
            </p>
            <div className="text-center">
              <ButtonOutlined text="Contact Us" />
            </div>
            <p className="text-gray-600 mt-4 text-sm font-normal">
              You can also{" "}
              <a href="#" className="text-black font-semibold underline">
                give us feedback
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
