import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ListingColor from 'components/Cards/ListingColor';
import MultiSelect from 'components/Select/MultiSelect';


export default function AddAmenities({ state, setState, AddAmenities }) {
  const [data, setData] = useState(AddAmenities);

  // Toggles the selection of an amenity: adds it if not present, removes it if already selected
  const onSelect = (selectedAmenity) => {
    setState((prevState) => {
      const existingIndex = prevState.amenities.findIndex(
        (amenity) => amenity.id === selectedAmenity.id
      );

      if (existingIndex !== -1) {
        // Amenity with the same ID exists, remove it
        const updatedAmenities = [...prevState.amenities];
        updatedAmenities.splice(existingIndex, 1);

        return {
          ...prevState,
          amenities: updatedAmenities,
        };
      } else {
        // Amenity with the ID doesn't exist, add it
        return {
          ...prevState,
          amenities: [...prevState.amenities, selectedAmenity],
        };
      }
    });
  };

  return (
    <div className='z-[10px]'>
      <Grid container>
        {/* lg 5 */}
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: '10vh' }}>
          <ListingColor
            bg='bg-color-darknavyblue'
            text='What cool features or amenities should your fellow members know about?'
            color='text-[#FFFFFF]'
            btn='step 4'
          />
        </Grid>
        <Grid item lg={7} md={6} sm={13} xs={13} display={'flex'} justifyContent={'center'} alignItems={'center'}
          className='addAmeneties_responsiveness'
        >
          <div className='grid grid-cols-1 gap-8 lg:grid-cols-3 md:grid-cols-2 xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0'>
            {data.map((item) => (
              <MultiSelect
                data={item}
                withImg={true}
                allData={data}
                onSelect={onSelect}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
