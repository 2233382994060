import React, { useState, useEffect, useRef } from 'react';

function GuestAndRoomSelector({ guests, rooms, setState, handleGuestChange, handleRoomChange }) {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    // handling click events outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-full " ref={dropdownRef}>
            <div className="flex items-center justify-center">
                <button
                    className="flex items-center justify-between w-full p-2 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span>{guests} guests & {rooms} rooms</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-6 w-6 ${isOpen ? 'transform rotate-180' : ''}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div className="absolute z-10 top-full left-0 right-0 xl:right-auto   bg-white border border-gray-400 rounded-md shadow-md ">
                    <div className="flex flex-col p-2">
                        <div className="flex items-center gap-1  justify-between ">
                            <div>
                                <span className="ml-2 font-bold text-lg">Guests</span>
                            </div>
                            <div className='flex gap-2'>
                                <button
                                    className="bg-blue-500 text-white px-3.5 py-1.5 rounded-full"
                                    onClick={() => handleGuestChange('decrement')}
                                >
                                    -
                                </button>
                                <input
                                    value={guests}
                                    readOnly
                                    className="w-16 text-center border border-gray-400 rounded-full"
                                />
                                <button
                                    className="bg-blue-500 text-white px-3 py-1 rounded-full"
                                    onClick={() => handleGuestChange('increment')}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center gap-1 justify-between mt-2">
                            <div>
                                <span className="ml-2 font-bold text-lg">Rooms</span>
                            </div>
                            <div className='flex gap-2'>
                                <button
                                    className="bg-blue-500 text-white px-3.5 py-1.5 rounded-full"
                                    onClick={() => handleRoomChange('decrement')}
                                >
                                    -
                                </button>
                                <input
                                    value={rooms}
                                    readOnly
                                    className="w-16 text-center border rounded-full border-gray-400"
                                />
                                <button
                                    className="bg-blue-500 text-white px-3 py-1 rounded-full"
                                    onClick={() => handleRoomChange('increment')}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GuestAndRoomSelector;
