import { Rating } from "@mui/material";
import React from "react";

const PastTripCard = ({ history }) => {

  const handleCheckIn = (value) => {

    //formating checkin date
    const checkInDate = new Date(value);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedCheckInDate = checkInDate.toLocaleDateString(
      "en-US",
      options
    );
    return formattedCheckInDate;
  };

  return (
    <>
      <div className="md:flex flex-wrap gap-2">
        {history.map((items) => {

          const minPoint = Array.isArray(items?.property_id?.points) && items?.property_id?.points.length > 0
            ? items?.property_id?.points.reduce((min, p) => (p.point < min ? p.point : min), items?.property_id?.points[0].point)
            : null;

          return (
            <div className="md:w-[49%] my-4 border border-color-grey rounded-md overflow-hidden">
              <div className="md:flex gap-2">
                <div className="w-full flex justify-between p-2 ">
                  <div>
                    <div className="flex items-center ">
                      <Rating
                        name="size-small"
                        readOnly
                        defaultValue={2}
                        size="small"
                      />
                      <span> (2)</span>
                    </div>
                    <p className="text-xl font-semibold mt-4">
                      {items.property_id.title}
                    </p>
                    <p className="text-sm text-gray-600">
                      Sleeps 2 | BR 1 | BA 1
                    </p>
                    <p className="text-sm text-gray-600">
                      {handleCheckIn(items.offerState.checkIn)}
                    </p>
                    <p className="text-sm text-gray-600">Booking Cancelled</p>

                    <hr className="" />
                    <div className=" my-1 flex justify-between items-center">
                      <p className="text-sm">From</p>
                      <p>
                        <span className="text-color-grey text-xs line-through">
                        {minPoint}
                        </span>{" "}
                        <span className="text-color-red">
                          {minPoint} Points
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                    >
                      <path
                        d="M12.9287 0.754883C12.2506 0.755708 11.58 0.901914 10.958 1.18448C10.336 1.46705 9.77595 1.87999 9.31233 2.39783C8.63292 1.63891 7.75121 1.11128 6.78167 0.883437C5.81213 0.655595 4.79954 0.738065 3.87535 1.12014C2.95115 1.50221 2.15804 2.16625 1.59898 3.02604C1.03992 3.88583 0.740724 4.90166 0.740234 5.94166C0.740234 11.106 8.69889 16.175 9.04445 16.3741C9.12778 16.4265 9.22316 16.4542 9.32037 16.4542C9.41758 16.4542 9.51296 16.4265 9.59628 16.3741C9.92577 16.175 17.8844 11.106 17.8844 5.94166C17.883 4.5665 17.3604 3.24808 16.4314 2.2757C15.5023 1.30331 14.2426 0.756367 12.9287 0.754883ZM9.31233 15.233C7.9274 14.3078 1.81175 9.98455 1.81175 5.94166C1.81243 5.07812 2.07583 4.23724 2.56377 3.54088C3.05171 2.84453 3.73884 2.32889 4.52557 2.06868C5.3123 1.80848 6.15777 1.81723 6.93943 2.09367C7.72108 2.37012 8.39833 2.89989 8.87301 3.60621C8.92247 3.67968 8.98807 3.73963 9.06429 3.78102C9.14051 3.8224 9.22512 3.844 9.31099 3.844C9.39686 3.844 9.48147 3.8224 9.55769 3.78102C9.63392 3.73963 9.69952 3.67968 9.74897 3.60621C10.2233 2.89868 10.9007 2.36776 11.6828 2.09054C12.465 1.81332 13.3112 1.80422 14.0987 2.06456C14.8861 2.3249 15.5738 2.84114 16.0619 3.53833C16.55 4.23551 16.813 5.07736 16.8129 5.94166C16.8129 9.98175 10.6973 14.305 9.31233 15.233Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>

                <div className="w-full relative">
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PastTripCard;