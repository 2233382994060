import React from "react";
import { toast } from "react-toastify";

const RefundStep2 = ({ step, setStep, setRefundReason, refundReason }) => {
  const handleNext = () => {
    if (!refundReason) {
      return toast.error("please give refund reason")
    }
    setStep('3')
  }

  return (
    <>
      <div className="grid md:grid-flow-col grid-flow-row">
        <div className="md:py-5 md:grid-cols-6 mr-10">
          <h1 className="font-bold text-[20px]">
            Tell to Host why you need to refund.
          </h1>
          <p className="font-medium text-[18px] py-2">
            Message to Host
          </p>

          <textarea
            cols="30"
            rows="4"
            placeholder="Reason......"
            className="w-full text-[#AFB5C1] p-4 rounded border-[2px] border-[#C1E1C2]"
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
          />
          <div>
            <button
              onClick={() => handleNext()}
              className="rounded-full mt-5 text-white bg-[#2459BF] w-[170px] h-[48px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundStep2;
