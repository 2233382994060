import Chat from 'pages/Chat'
import React from 'react'

export const Inbox = ({ viewChat, setViewChat, setSelectedTab }) => {
    const handleClick = () => {
        setViewChat(false)
        setSelectedTab("dashboard")
    }
    return (

        <div>
            {
                viewChat ? <>
                    <div className="mt-10">
                        <Chat viewButton={true} handleClick={handleClick} />
                    </div>
                </> : null
            }

        </div>
    )
}
