import React from "react";

export default function MultiDec({ data, onSelect, allData }) {
  const [checked, onChange] = React.useState(data.status);
  function onCheckmarkPress() {
    onChange(!checked);
    allData[data.id].status = !checked;
    let newdata = allData.map((item) => {
      return item;
    });
    onSelect(data);
    // setData(newdata);
  }
  return (
    <div
      onClick={onCheckmarkPress}
      className={`cursor-pointer ${checked
          ? `bg-[#58C0D0] bg-opacity-[50%] overflow-hidden rounded-2xl`
          : "border-0"
        }`}
    >
      <div className="border-2 w-[150px] xl:w-[200px] border-[#C1E1C2] h-[73px] flex justify-center items-center rounded-2xl">
        <p className="text-center">{data.label}</p>
      </div>
    </div>
  );
}
