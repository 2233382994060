import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';

const currentDate = dayjs();

const DatePickerLocalize = ({ checkIn, setCheckIn, checkOut, setCheckOut, }) => {

  return (
    <div className="flex my-2 items-center justify-center px-4 py-2 border border-gray-300 rounded-xl w-full md:w-[350px] h-[50px]">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Check-in"
          value={checkIn}
          onChange={(newValue) => {
            setCheckIn(newValue);
          }}
          minDate={currentDate.toDate()}
          renderInput={({ inputRef, inputProps, InputProps, params }) => (
            <div className="flex items-center">
              <input
                className="w-full text-black outline-0"
                ref={inputRef}
                {...inputProps}
                placeholder="Check-in"
                {...params}
              />
              {InputProps?.endAdornment}
            </div>
          )}
        />
      </LocalizationProvider>
      <div className="w-px h-full mx-2 bg-gray-300"></div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Check-out"
          value={checkOut}
          onChange={(newValue) => {
            setCheckOut(newValue);
          }}
          minDate={currentDate.toDate()}
          renderInput={({ inputRef, inputProps, InputProps, params }) => (
            <div className="flex items-center">
              <input
                className="w-full text-black outline-0"
                ref={inputRef}
                {...inputProps}
                placeholder="Check-out"
                {...params}
              />
              {InputProps?.endAdornment}
            </div>
          )}
        />
      </LocalizationProvider>

    </div>
  );
};

export default DatePickerLocalize;
