import React, { useState, useEffect } from "react";
import PopupModal from "components/PopupModal/PopupModal";
import { useNavigate, useParams } from "react-router-dom"
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
const RefundStep3 = ({ step, setStep, reservationDetails, refundReason }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const params = useParams();

  //handle reservation refund process
  const refundReservation = async () => {
    try {
      setLoading(true);
      const refundApiUrl = `/reservation/refundReservation/${params.id}`;
      const updateReservation = `/reservation/reservation/${params.id}`;
      const refundResponse = await userServices.refundReservation(refundApiUrl, {
        refundReason: refundReason, // Send refund reason in the request body
      });
      const updateResponse = await userServices.updateReservation(updateReservation, {
        isRefundRequest: "true",
      });
      toast.success("Refund Request Successfully Processed");
      navigate('/');
    } catch (error) {
      toast.error(ErrorService.uniformError(error?.response?.data?.error));
    }
    setLoading(false); // Set loading state to false after API calls
  };

  // Calculate refund points, reduced points, and refund service fee
  const refundPoints = reservationDetails.offerState
    ? Math.floor(reservationDetails.offerState.points * 0.8)
    : 0;

  const reducedPoints = reservationDetails.offerState
    ? Math.floor(reservationDetails.offerState.points * 0.2)
    : 0;

  const refundServiceFee = reservationDetails.offerState
    ? Math.floor(reservationDetails.offerState.serviceFee * 0.8)
    : 0;

  const handleRefund = () => {
    setOpenAlert(true)
  }
  const handleCancleModal = () => {
    setOpenAlert(false);
  };
  const handleAcceptModal = () => {
    setOpenAlert(false);
    refundReservation();
  };
  return (
    <>
      {openAlert && (
        <PopupModal
          handleCancle={handleCancleModal}
          handleAccept={handleAcceptModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description="Are you sure you want to proceed with the refund process? This action cannot be undone."
          title="Confirm Refund"
          acceptbtnText="Confirm"
        />
      )}
      <div className="">
        <div className=" md:flex gap-10 ">
          <div className="">
            <p className="font-bold">Does this look right?</p>
            <div className="flex justify-between items-center">
              <p className="py-2">Total Price</p>
              <p>{reservationDetails?.offerState.points} Points</p>
            </div>
            <div>
              {/* <p className="pb-2">Full Refund</p> */}
              <hr></hr>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="py-2">Cleaning Fee</p>
              <p>0</p>
            </div>
            <div>
              {/* <p className="pb-2">Full Refund</p> */}
              <hr></hr>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="py-2">Service Fee</p>
              <p>{refundServiceFee}</p>
            </div>
            <div>
              {/* <p className="pb-2">Full Refund</p> */}
              <hr></hr>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="font-bold py-2">Total eligible refund points</p>
              <p>{refundPoints}</p>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="font-bold py-2">reduced points</p>
              <p>{reducedPoints}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="pb-2">Points due on reservation</p>
              <p>{reservationDetails?.offerState?.points}</p>
            </div>
            <hr></hr>
            <div className="flex justify-between items-center pt-2">
              <p className="font-bold">Total Refund Points</p>
              <p className="font-bold">{refundPoints}</p>
            </div>
            <div className="pt-2">
              <p className="pt-2">
                Your reservation will be canceled immediately and you,ll be
                refunded within 10 days
              </p>
              <div className="pt-2">
                <button
                  className={`bg-blue-500 text-sm rounded p-2 px-4 text-white flex items-center justify-center ${loading ? "cursor-not-allowed" : ""}`}
                  onClick={handleRefund}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Processing...
                    </>
                  ) : (
                    "Refund"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundStep3;
