import { Container } from '@mui/material';
import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export const Calendarcomponent = () => {

  const [activeTab, setActiveTab] = useState('pricing');

  const customButtons = {
    customPrevButton: {
      text: 'Prev',
      click: () => {
        // Handle custom previous button click if needed
        // Example: calendar.prev();
      },
    },
    customNextButton: {
      text: 'Next',
      click: () => {
        // Handle custom next button click if needed
        // Example: calendar.next();
      },
    },
  };


  return (
    <section>
      <Container>
        <div className='gap-8 md:flex'>
          <div className='w-[389.86px] h-[738.41px] border rounded-xl'>
            <div className='m-8'>
              <div className='w-[248.38px] flex items-center bg-[#F9F9F9] h-[40px] rounded-full'>
                <button
                  className='w-[124.19px] rounded-full h-[40px]'
                  onClick={() => setActiveTab('pricing')}
                  style={{ backgroundColor: activeTab === 'pricing' ? '#E8AD21' : '#F9F9F9', color: activeTab === 'pricing' ? '#FFFFFF' : '#AFB5C1' }}
                >
                  Pricing
                </button>
                <button
                  className='w-[124.19px] rounded-full h-[40px]'
                  onClick={() => setActiveTab('availability')}
                  style={{ backgroundColor: activeTab === 'availability' ? '#E8AD21' : '#F9F9F9', color: activeTab === 'availability' ? '#FFFFFF' : '#AFB5C1' }}
                >
                  Availability
                </button>
              </div>


              {activeTab === 'pricing' && (
                <div className='pt-4'>
                  <div>
                    <h3 className='text-[#000000] text-2xl font-medium'>Basic Price</h3>
                  </div>
                  <div className='pt-5'>
                    <div className=''>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center gap-4'>
                          <input type='text' placeholder='$66' className='placeholder:text-[#000000] w-[54px] h-[35px] text-[30px] font-bold' />
                          <span className='text-[#4B4B4B] text-base  font-normal'>/Per Night</span>
                        </div>
                      </div>
                    </div>

                    <div className='pt-4'>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center justify-between w-full'>
                          <input type='text' placeholder='Custom weekend Price' className='placeholder:text-[#000000] w-[161px] h-[35px] text-base font-normal' />
                          <a className='text-[#000000] underline text-base  font-normal'>Add</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='pt-5 w-full md:w-[318.56px]'>
                    <div>
                      <h3 className='text-[#000000] text-2xl font-medium'>Discounts</h3>
                    </div>
                    <div>
                      <p className='text-[#4B4B4B] pt-2 text-base font-normal'>Adjust your pricing to attract more guests.</p>
                    </div>
                  </div>

                  <div className='pt-5'>
                    <div className=''>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center gap-4'>
                          <input type='text' placeholder='10%' className='placeholder:text-[#000000] w-[63px] h-[35px] text-[30px] font-bold' />
                          <span className='text-[#4B4B4B] text-base  font-normal'>/Weekly</span>
                        </div>
                      </div>
                    </div>

                    <div className='pt-4'>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center gap-4'>
                          <input type='text' placeholder='20%' className='placeholder:text-[#000000] w-[63px] h-[35px] text-[30px] font-bold' />
                          <span className='text-[#4B4B4B] text-base  font-normal'>/Monthly</span>
                        </div>
                      </div>
                    </div>

                    <div className='pt-4'>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center justify-between w-full'>
                          <input type='text' placeholder='Custom Discount' className='placeholder:text-[#000000] w-[122px] h-[26px] text-base font-normal' />
                          <a className='text-[#000000] underline text-base  font-normal'>Add</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='pt-5 w-full md:w-[318.56px]'>
                    <div>
                      <h3 className='text-[#000000] text-lg font-medium'>Additional charges</h3>
                    </div>
                    <div className='pt-4'>
                      <div className='w-[318.56px] flex items-center px-4 h-[60px] border rounded-xl'>
                        <div className='flex items-center justify-between w-full'>
                          <div>
                            <h3 className='text-[#000000] text-lg font-medium'>Fees</h3>
                            <input type='text' placeholder='Cleaning, pets, extra guests etc' className='placeholder:text-[#000000] w-[191px] h-[23px] text-sm font-normal' />
                          </div>
                          <div>
                            <a className='text-[#000000] underline text-base  font-normal'>Add</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'availability' && (
                <div className='pt-4'>
                  Availability content
                </div>
              )}
            </div>
          </div>

          <div className='w-full max-w-screen-lg responsive-calendar '>
            <FullCalendar
              className='w-full h-full rounded-xl'
              plugins={[dayGridPlugin,
                timeGridPlugin,
                interactionPlugin
              ]}
              initialView='dayGridMonth'
              weekends={true}
              editable={true}
              droppable={true}
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                end: 'dayGridMonth',
              }}
              height="556.5px"
              width="100%"
              customButtons={customButtons}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}
