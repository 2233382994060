import { useMutation } from 'react-query';
import { fetchWrapper } from 'services/restApi';
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import userServices from "services/httpService/userAuth/userServices";
import { storeLocalData } from '../services/auth/localStorageData';
export const AdminLoginEmailAccount = () => {
  return useMutation(
    (body) => {
      return userServices.adminLogin('/admin/adminlogin', body);
    },
    {
      onSuccess: (data) => {
        console.log('data11111', data);
        if (data.status) {
          storeLocalData(data.data);
          toast.success('Admin successfully Login');

        }
      },
      onError: (err) => {
        toast.error(ErrorService.uniformError(err));
      },
    }
  );
};