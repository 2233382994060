import React from 'react'
import adminNavIcon from '../../../assets/img/adminNavIcon.png'
import searchIcon from '../../../assets/img/searchIcon.png'
import { AiOutlineMessage } from "react-icons/ai";
import { GoBell } from "react-icons/go";
import { FaUser } from "react-icons/fa6";

const AdminNav = () => {
    return (
        <>
            <div className='flex md:flex-row flex-col items-center justify-between border-b border-gray-500 p-5 md:gap-0 gap-3'>
                <div className='flex gap-3' >
                    <div className='flex justify-center items-center'>
                        <img src={adminNavIcon} />
                    </div>
                    <div className='relative'>
                        <input type='search' placeholder='Search' className='border border-[#E9E9E9] md:w-80 w-full p-2 px-5 rounded'></input>
                        <div className='absolute inset-y-0 right-2 flex items-center pr-2 pointer-events-none'>
                            <img src={searchIcon} />
                        </div>
                    </div>
                </div>

                <div className='flex gap-10 md:gap-5  '>
                    <div className='flex gap-4 items-center justify-center'>
                        <AiOutlineMessage className='w-8 h-8' />
                        <GoBell className='w-8 h-8' />
                        <FaUser className='bg-[#E8AD21] rounded-full p-2 w-10 h-10 text-white ' />
                    </div>
                    <div className='flex items-center justify-center gap-5'>
                        <div>
                            {/* <h1 className='font-bold'> </h1> */}
                            <h1 className='text-[#4B4B4B] font-bold'>Admin</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminNav
