import TaxPayerCard from 'components/Cards/TaxPayerCard'
import React from 'react'

export const TaxPayer = () => {
  return (
    <div className="space-y-6 px-6 sm:h-[500px]">
      <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
        <TaxPayerCard name="Add TAX INFO" information='Taxpayer Information' register='Tax info is required for most countries/regions. ' />
        <TaxPayerCard name="Add VAT ID NUMBER" information='Value Added Tax (VAT)' register='If you are VAT-registered, please add your VAT ID. ' />
      </div>
      <div>
        <p className="text-[16px] font-medium">Customer Support</p>
        <span className='flex justify-between items-center gap-4 md:gap-12'>
          <p className="text-[#4B4B4B] font-normal">Contact our support from 24/7 from anywhere in the world</p>
          <p className="text-[16px] font-medium"><span className="border-b border-black w-full cursor-pointer">Visit Help Centre</span></p>
        </span>
      </div>
    </div>
  )
}
