import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { localStorageData, Logout } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";

function ConfirmReservation() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(5);
  const [renderedfav, setRenderedfav] = useState();
  const [allPost, setallPost] = useState([]);

  const location = useLocation();
  const { state } = location;

  // Function to handle the confirmation of a listing as a favorite
  const handleConfirmList = () => {
    mutate({ propertyId: state.property_id, userId: localStorageData("_id") });
  };

  const { mutate } = useMutation(
    // API call to update the property as a favorite
    (token) =>
      userServices.Wishlist("/property/updatePropertyforFavourite", token),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setRenderedfav(!renderedfav);
      },
    }
  );

  const getproperty = async () => {
    try {
      // API call to get all available properties
      let res = await userServices.commonGetService(
        `/property/getAllPropertyAvailable`
      );
      setallPost(res.data.data);

      const filteredData = state.state.propertyDetail.favouriteList.some(
        (favitem) => favitem.userId === localStorageData("_id")
      );
      setRenderedfav(filteredData);
    } catch (error) {
      // Handle errors
      console.error("Error fetching property data:", error);
    }
  };

  useEffect(() => {
    // Fetch property data when component mounts
    getproperty();
  }, []);

  function formatDate(date) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  // Function to format time
  function formatTime(date) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert midnight (0) to 12
    const formattedMinute = `${minute < 10 ? '0' : ''}${minute}`; // Ensure two-digit minute representation
    return `${formattedHour}:${formattedMinute}${ampm}`;
  }

  // Check-in date and time
  const checkInDate = new Date(state?.state?.offerState?.checkIn.$d);
  const checkInFormattedDate = formatDate(checkInDate);
  const checkInFormattedTime = formatTime(checkInDate);

  // Assuming 5 hours check-in duration
  const checkOutDate = new Date(state?.state?.offerState?.checkOut.$d);
  const checkOutFormattedDate = formatDate(checkOutDate);
  const checkOutFormattedTime = formatTime(checkOutDate);

  // Output check-in message
  const checkInDateAndTime = `${checkInFormattedDate}\nCheck-in time is ${checkInFormattedTime} - ${checkOutFormattedTime}`;

  // Output check-out message
  const checkOutMessage = `${checkOutFormattedDate}\nCheck-out time is ${checkOutFormattedTime}`;

  return (
    <>
      <Container maxWidth="xl">
        <Container maxWidth="xl">
          <div className="mt-2 reserveDetailWrapper md:flex">
            <div className=" md:w-6/12">
              <div className="my-6 ">
                <h4 className="text-2xl font-medium ">
                  Your reservation is confirmed
                </h4>
                <p className="text-sm text-color-grey">
                  You're going to {state?.state?.propertyDetail?.address}
                </p>
              </div>
              <div className="my-4">
                <img
                  src={state?.state?.propertyDetail?.pics[0]}
                  alt=""
                  className="rounded w-full md:h-[400px]"
                />
                <div className="flex items-center justify-between mt-2">
                  <div>
                    <h3 className="text-2xl text-semibold">
                      {state?.state?.propertyDetail?.title}
                    </h3>
                    <p className="text-sm text-[#4B4B4B]">An entire place Booked for you</p>
                  </div>

                  <img src={state?.state?.propertyDetail?.user?.pic} className="w-20 h-20 bg-gray-100 rounded-full" alt="User profile" />

                </div>
                <hr className="mt-6 border-[#C1E1C2] " />

                <div className="mt-6">
                  <div className="flex justify-between gap-56">
                    <div className="">
                      <p className="font-bold">Check In</p>
                      <p className="text-[#4B4B4B]">{checkInFormattedDate}</p>
                      {/* <p className="text-[#4B4B4B]">{checkInFormattedTime}</p> */}
                    </div>

                    <div className="">
                      <p className="font-bold">Check Out</p>
                      <p className="text-[#4B4B4B]">{checkOutFormattedDate}</p>
                      {/* <p className="text-[#4B4B4B]">{checkOutFormattedTime}</p> */}
                    </div>
                  </div>
                  <div className="flex items-center my-2 justify-between">
                    <button className="w-1/2 btn-styl-blue">
                      <p onClick={() => navigate("/")}>
                        {" "}
                        Confirm
                      </p>
                    </button>
                    {/* <button className="px-12 py-2 ml-2 border rounded-full border-color-yellow text-color-yellow">
                      Referral
                    </button> */}
                    <div className="ml-2">
                      <div className="">
                        <button
                          onClick={handleConfirmList}
                          className={`fa-regular ${renderedfav && "text-color-red fa-solid"
                            } fa-heart w-[17.14px] h-[15.7px]`}
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="height-line md:w-6/12 md:mt-20 md:ml-4 rounded-xl">
              <div className="reserveImage "></div>
              <div className="items-center h-48 px-2 ">
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Address</p>
                    <p className="text-sm text-[#4B4B4B]">
                      {state?.state?.propertyDetail?.address}
                    </p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* Get Direction */}
                    TBD
                  </p>
                </div>
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Guests</p>
                    <p className="text-sm text-[#4B4B4B] ">{state?.state?.offerState?.guest}</p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* Invite Guest */}
                    TBD
                  </p>
                </div>
                <div className="mt-3 ">
                  <span className="font-semibold">Rooms</span>
                  <p className="text-sm text-[#4B4B4B] pt-2">{state?.state?.offerState?.room}</p>
                </div>
                <div className="flex items-center justify-between mt-3 ">
                  <div className="">
                    <p className="font-semibold text-md">Points</p>
                    <p className="text-sm text-[#4B4B4B]">{state?.state?.offerState?.points}</p>
                  </div>
                  <p className="text-sm underline cursor-pointer">
                    {/* View Receipt */}
                    TBD
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-semibold text-md">Service Fee</p>
                  <p className="text-sm text-[#4B4B4B]">${state?.state?.offerState?.serviceFee}</p>
                </div>
                <hr className="flex items-center justify-center mt-6 border-[#C1E1C2] w-12/12" />
                <div className="mt-3 ">
                  <span className="font-semibold">Reservation code</span>
                  <p className="text-[16px] pt-2 text-[#4B4B4B]">{state?.property_id}</p>
                </div>
                <hr className="flex items-center justify-center mt-6 border-[#C1E1C2] w-12/12" />
                <div className="flex items-center justify-between mt-3">
                  <button onClick={() => navigate("/changereservation",
                    { state: state }
                  )}
                    className="px-8 py-2 mt-4 font-semibold border rounded-full text-color-yellow border-color-yellow">
                    Change Reservation{" "}

                  </button>
                  <p onClick={() => navigate("/refund")} className="text-sm underline cursor-pointer">
                    {/* Refund */}
                    TBD
                  </p>
                </div>
                <hr className="flex items-center justify-center mt-6 border-[#C1E1C2] w-12/12" />
                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">
                      {state?.state?.propertyDetail?.user?.fname} is your host
                    </span>
                    <p className="text-sm text-[#4B4B4B]">
                      {state?.tripe?.message}
                    </p>
                  </div>
                  <p className="text-sm underline">
                    {/* Message Host */}
                    TBD
                  </p>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">Know what to expect</span>
                    <p className="text-sm text-[#4B4B4B]">
                      Make sure to review the house rules and amenities.
                    </p>
                  </div>
                  <p className="text-sm underline">
                    {/* Message Host */}
                    TBD
                  </p>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="">
                    <span className="font-semibold">Customer support</span>
                    <p className="text-sm text-[#4B4B4B]">
                      Contact our support team 24/7 from anywhere in the world.
                    </p>
                  </div>
                  <p className="text-sm underline">
                    {/* Visit Help Centre */}
                    TBD
                  </p>
                </div>
              </div>
            </div>
          </div>


          <br />
          <br />

          <br />

          <br />
          <br />
          <br />
        </Container>
      </Container>
    </>
  );
}

export default ConfirmReservation;
